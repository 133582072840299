import { KortexTextField } from "@aos/react-components";
import { BomFollowUpMultipleTraceabilitiesReq, Unpack } from "@kortex/aos-common";
import { useAppLayoutContext } from "@kortex/aos-ui/components/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { FC } from "react";

import { serviceMap, ServiceVariant } from "../../utils";

import SerialNumberSelector, { ItemReassigned } from "./serialNumberSelector";

const useStyles = makeStyles({
    quantityTextField: {
        width: "170px",
    },
    row: {
        marginBottom: "10px",
        marginTop: "10px",
    },
});

export interface FollowUpRow extends Unpack<BomFollowUpMultipleTraceabilitiesReq> {
    valid: boolean;
}

interface IOwnProps {
    followUp: FollowUpRow[];
    index: number;
    initialQuantity: number;
    isDuplicate: boolean;
    isFollowUpQuantityZero: boolean;
    isQuantityDecimal: boolean;
    lotSerialType: string;
    onFollowUpChange: (followUp: FollowUpRow[]) => void;
    partNumber: string;
    variant: ServiceVariant;
}

const Row: FC<IOwnProps> = (props) => {
    const {
        followUp,
        index,
        initialQuantity,
        isDuplicate,
        isFollowUpQuantityZero,
        isQuantityDecimal,
        lotSerialType,
        onFollowUpChange,
        partNumber,
        variant,
    } = props;

    const { loading, setLoading } = useAppLayoutContext();

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const errorMessage =
        // Duplicate traceability
        isDuplicate
            ? translate("bomPage.bomTable.duplicatedTraceability")
            : // Invalid traceability
            followUp[index].traceability && !followUp[index].valid
            ? translate("bomPage.bomTable.invalidTraceability")
            : // No error message
              undefined;
    /**
     * Validate the traceability of a given input value.
     */
    const isTraceabilityValid = async (value: string): Promise<boolean> => {
        // Trims the input value to remove whitespace.
        const updatedTraceability = value.trim();

        // Traceability is invalid if blank
        if (!updatedTraceability) return false;

        // No validation required if lot/serial type is "LOT DATE"
        if (lotSerialType !== "LOT DATE") return true;

        // Send a request to assert that the traceability is not expired.
        setLoading(true);

        const res = await dispatch(
            serviceMap[variant].assertTraceabilityNotExpired({
                lotSerialType,
                partNumber,
                traceability: updatedTraceability,
            })
        ).finally(() => setLoading(false));

        return Boolean(res);
    };

    /**
     * Deletes a row
     */
    const handleDeleteRow = (): void => {
        const updatedFollowUp = [...followUp];
        updatedFollowUp.splice(index, 1);

        onFollowUpChange(updatedFollowUp);
    };

    /**
     * Increases the quantity of specified traceability
     */
    const handleQuantityChange = (value: number): void => {
        const updatedFollowUp = [...followUp];
        updatedFollowUp[index].quantity = value;

        onFollowUpChange(updatedFollowUp);
    };

    /**
     * Reassign serial numbers
     */
    const handleReassignSerializedItem = (serializedItem: ItemReassigned): void => {
        const updatedFollowUp = deepClone(followUp);

        // Validate if assigned to other traceability
        for (const { serializedItems } of updatedFollowUp) {
            const index = serializedItems.findIndex(
                (item) => item.bomFollowUpSerializedItemId === serializedItem.bomFollowUpSerializedItemId
            );

            if (index !== -1) {
                // Remove
                serializedItems.splice(index, 1);
            }
        }

        // Add
        updatedFollowUp[serializedItem.indexTraceabilitySelected].serializedItems.push({
            bomFollowUpSerializedItemId: serializedItem.bomFollowUpSerializedItemId,
            serialNumber: serializedItem.serialNumber,
        });

        onFollowUpChange(updatedFollowUp);
    };

    /**
     * Updates the traceability of a row
     */
    const handleTraceabilityChange = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
        const updatedTraceability = event.target.value.trim();

        if (updatedTraceability === followUp[index].traceability) return void 0;

        const updatedFollowUp = [...followUp];
        updatedFollowUp[index].traceability = updatedTraceability;
        updatedFollowUp[index].valid = await isTraceabilityValid(updatedTraceability);

        onFollowUpChange(updatedFollowUp);
    };

    return (
        <div key={`multipleTraceabilitiesDialogRow${index}`}>
            <div className={classes.row} id={`rowValue${index}Id`}>
                {/* TRACEABILITY */}
                <KortexTextField
                    label={translate("bomPage.bomTable.traceability")}
                    onBlur={handleTraceabilityChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: `multipleTraceabilitiesDialogTraceability${index}Id`,
                        required: true,
                        disabled: index === 0,
                    }}
                    value={followUp[index].traceability}
                    variant="outlined"
                    error={errorMessage}
                />
                {/* QUANTITY */}
                <KortexTextField
                    changedDelayMS={0}
                    className={classes.quantityTextField}
                    label={translate("bomPage.bomTable.quantity")}
                    min={isFollowUpQuantityZero ? 0 : 1e-6}
                    max={initialQuantity}
                    onChanged={handleQuantityChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        disabled: isFollowUpQuantityZero,
                        id: `multipleTraceabilitiesDialogQuantity${index}Id`,
                        required: true,
                    }}
                    type="number"
                    value={isFollowUpQuantityZero ? 0 : followUp[index].quantity}
                    withButtons={!isFollowUpQuantityZero}
                    step={isQuantityDecimal ? 0.1 : 1}
                    stepDecimal={isQuantityDecimal ? 6 : 1}
                />
                {/* "DELETE ROW" BUTTON */}
                {index > 0 && followUp.length > 2 ? (
                    <IconButton id={`multipleTraceabilitiesDialogDeleteRow${index}Id`} onClick={handleDeleteRow} disabled={loading}>
                        <DeleteIcon />
                    </IconButton>
                ) : null}
            </div>
            {/* SERIAL NUMBERS ASSIGNED TO THE TRACEABILITY */}
            {followUp[index].serializedItems.map(
                (itemSerialized, indexItemSerialized): JSX.Element => (
                    <SerialNumberSelector
                        index={indexItemSerialized}
                        indexTraceability={index}
                        serializedItem={itemSerialized}
                        onItemSerializedReassigned={handleReassignSerializedItem}
                        traceabilities={followUp}
                        key={indexItemSerialized}
                    />
                )
            )}
        </div>
    );
};

export default Row;
