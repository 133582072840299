/*
 * ENUM
 * This enum is only used in the AOSError class
 * This enum is in this file and not the class to modify a single file when adding or updating an enum
 */
export var SuggestionKeyEnum;
(function (SuggestionKeyEnum) {
    SuggestionKeyEnum["ACTION_REQUIRED_VERSION_DRAFT"] = "suggestionKeyError.actionRequiredVersionDraft";
    SuggestionKeyEnum["ASSOCIATE_EXECUTION_AT_ONE_FAILURE_TICKET"] = "suggestionKeyError.associateExecutionAtOneFailureTicket";
    SuggestionKeyEnum["CHANGE_STATUS_OF_THE_JOB_TO_UPDATE_IT"] = "suggestionKeyError.changeStatusOfTheJobToUpdateIt";
    SuggestionKeyEnum["CONTACT_ADMIN_USER"] = "suggestionKeyError.contactAdminUser";
    SuggestionKeyEnum["CONTACT_AOS_DEV_TEAM"] = "suggestionKeyError.contactAosDevTeam";
    SuggestionKeyEnum["CONTACT_RESPONSIBLE_PROCESS_APPROVED_OR_RELEASE_IN_ROUTING"] = "suggestionKeyError.contactResponsibleProcessApprovedOrReleaseInRouting";
    SuggestionKeyEnum["COMPLETE_THE_CHANGELOG"] = "suggestionKeyError.completeTheChangelog";
    SuggestionKeyEnum["COMPLETE_THE_REFERENCE_ID"] = "suggestionKeyError.completeTheReferenceId";
    SuggestionKeyEnum["COMPLETE_TRAINING_CERTIFICATE"] = "suggestionKeyError.completeTrainingCertificate";
    SuggestionKeyEnum["EMPTY_SUGGESTION"] = "suggestionKeyError.emptySuggestion";
    SuggestionKeyEnum["EXPECTED_PASSWORD_FORMAT"] = "suggestionKeyError.expectedPasswordFormat";
    SuggestionKeyEnum["FIND_OR_CREATE_JOB"] = "suggestionKeyError.findOrCreateJob";
    SuggestionKeyEnum["FIND_OR_CREATE_TRAINING"] = "suggestionKeyError.findOrCreateTraining";
    SuggestionKeyEnum["JOB_QTY_MAX_REACH"] = "suggestionKeyError.jobQtyMaxReach";
    SuggestionKeyEnum["MAKE_SURE_EACH_PROCESS_IN_ROUTING_APPROVED"] = "suggestionKeyError.makeSureEachProcessInRoutingApproved";
    SuggestionKeyEnum["MAKE_SURE_ENTERED_INFO_CORRECTLY"] = "suggestionKeyError.makeSureEnteredInfoCorrectly";
    SuggestionKeyEnum["MAKE_SURE_TRACEABILITY_ENTERED_IS_DESIRED"] = "suggestionKeyError.makeSureTraceabilityEnteredIsDesired";
    SuggestionKeyEnum["MAKE_SURE_TRACEABILITY_ENTERED_IS_NOT_EXPIRED"] = "suggestionKeyError.makeSureTraceabilityEnteredIsNotExpired";
    SuggestionKeyEnum["MAKE_SURE_USERNAME_AND_PASSWORD_ENTERED_CORRECTLY_THEN_TRY_AGAIN"] = "suggestionKeyError.makeSureUsernameAndPasswordEnteredCorrectlyThenTryAgain";
    SuggestionKeyEnum["RELEASE_DATE_NOT_EQUAL_OR_LATER_THAN_RETIRED_DATE"] = "suggestionKeyError.releaseDateNotEqualOrLaterThanRetiredDate";
    SuggestionKeyEnum["PROCESS_VERSION_STATUS_REQUIRED_FOR_TRAINING_REQUIREMENT_UPDATE"] = "suggestionKeyError.processVersionStatusRequiredForTrainingRequirementUpdate";
    SuggestionKeyEnum["SELECT_ALL_MANDATORY_APPROVAL_GROUPS"] = "suggestionKeyError.selectAllMandatoryApprovalGroups";
    SuggestionKeyEnum["SELECT_VERSION_OTHER_THAN_DRAFT"] = "suggestionKeyError.selectVersion.OtherThanDraft";
    SuggestionKeyEnum["STORE_RESULT_ERROR"] = "suggestionKeyError.storeResultError";
    SuggestionKeyEnum["UNKNOWN_ERROR"] = "suggestionKeyError.unknownError";
    SuggestionKeyEnum["USE_ANOTHER_IDENTIFIER"] = "suggestionKeyError.useAnotherIdentifier";
    SuggestionKeyEnum["USE_ANOTHER_PREFIX"] = "suggestionKeyError.useAnotherPrefix";
    SuggestionKeyEnum["USE_ANOTHER_USERNAME"] = "suggestionKeyError.useAnotherUsername";
    SuggestionKeyEnum["USE_ANOTHER_USERCODE"] = "suggestionKeyError.useAnotherUsercode";
    SuggestionKeyEnum["VALIDATE_BOM_ID_AND_REVISION"] = "suggestionKeyError.validateBomIdAndRevision";
    SuggestionKeyEnum["VALIDATE_JOB_OR_BOM"] = "suggestionKeyError.validateJobOrBom";
})(SuggestionKeyEnum || (SuggestionKeyEnum = {}));
