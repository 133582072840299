import { theme } from "@aos/react-components";
import { IJobUiModel, JobStatusEnum } from "@kortex/aos-common";
import { IconButton, makeStyles } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import ReworkIcon from "@material-ui/icons/BuildOutlined";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import JobIcon from "@material-ui/icons/WorkOutlineOutlined";
import * as React from "react";
import { useEffect } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import KortexLabelText from "../../../core/KortexLabelText";
import KortexPanelCard from "../../../core/KortexPanelCard";
import { useSchedulerContext } from "../schedulerContext";
import { computeJobProgressCompletionPercentage, getJobStatusColor } from "../SchedulerData";

const useStyles = makeStyles({
    card: {
        cursor: "pointer",
    },
    cardContent: {
        display: "grid",
        gridAutoColumns: "0.25fr 1fr 1fr 1fr 1fr 0.5fr",
        gridAutoFlow: "column",
        rowGap: "2px",
        pointer: "cursor",
    },
    iconType: {
        color: theme.palette.grey[500],
        width: "20px",
        height: "20px",
    },
});

interface IOwnProps {
    drapAndDropIndex: number;
    job: IJobUiModel;
    isSelected: boolean;
    canUserArchive: boolean;
    onArchiveCard: (job: IJobUiModel) => void;
}

export default function JobCardContainer(props: IOwnProps): JSX.Element {
    const { drapAndDropIndex, job, isSelected, canUserArchive, onArchiveCard } = props;

    const ref = React.createRef<HTMLDivElement>();
    const classes = useStyles();
    const translate = useTranslate();
    const { setSelectedJob } = useSchedulerContext();

    const handleSelectJob =
        (job: IJobUiModel): (() => void) =>
        (): void => {
            setSelectedJob(job);
        };

    const handleArchiveOnClick =
        (job: IJobUiModel): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) =>
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();
            onArchiveCard && onArchiveCard(job);
        };

    /**
     * Scroll into view when card is selected
     */
    useEffect(() => {
        if (isSelected) {
            const position = ref?.current?.getBoundingClientRect() as DOMRect;
            if (position?.top > innerHeight - 100) {
                ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            } else if (position.bottom - 200 < 0) {
                ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [isSelected]);

    return (
        <KortexPanelCard
            className={classes.card}
            key={drapAndDropIndex}
            isSelected={isSelected}
            statusColor={getJobStatusColor(job.status)}
            onSelect={handleSelectJob(job)}
            padding={10}
            // isDraggable={true}
        >
            <div className={classes.cardContent} id="jobCardId" ref={ref}>
                {job.reworkId ? <ReworkIcon className={classes.iconType} /> : <JobIcon className={classes.iconType} />}
                <KortexLabelText label={translate("scheduler.jobRefId")} text={job.jobRefId} />
                <KortexLabelText label={translate("scheduler.partNumber")} text={`${job.partNumber ? job.partNumber : ""}`} />
                <KortexLabelText label={translate("scheduler.referenceId")} text={job.referenceId} />
                <KortexLabelText
                    label={translate("scheduler.completion")}
                    text={`${job.qty ? Math.round(computeJobProgressCompletionPercentage(job)).toString() : "0"}%`}
                />
                {canUserArchive && (job?.status === JobStatusEnum.DONE || job?.status === JobStatusEnum.ARCHIVED) && (
                    <IconButton onClick={handleArchiveOnClick(job)}>
                        {job.status === JobStatusEnum.DONE && <ArchiveIcon className={classes.iconType} />}
                        {job.status === JobStatusEnum.ARCHIVED && <UnarchiveIcon className={classes.iconType} />}
                    </IconButton>
                )}
            </div>
        </KortexPanelCard>
    );
}
