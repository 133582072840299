import { KortexSideMenu, primaryPalette, theme } from "@aos/react-components";
import { KanbanRightsEnum, LocationKeys } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { clearProcessesAction } from "@kortex/aos-ui/redux/process-manager/process-actions";
import { clearSchedulerAction } from "@kortex/aos-ui/redux/scheduler-manager/scheduler-actions";
import { setEditedTreeProcessNodeIdAction } from "@kortex/aos-ui/redux/tree-manager/tree-actions";
import { logout } from "@kortex/aos-ui/redux/user-manager/UserActions";
import { CircularProgress, Dialog, DialogContent, Drawer, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import LoginPage from "../../../components/pages/Login/LoginPage";
import ProcessEditorPage from "../../../components/pages/ProcessEditor/ProcessEditorPage";
import ReportingPage from "../../../components/pages/Reporting/ReportingPage";
import SchedulerPage from "../../../components/pages/Scheduler/SchedulerPage";
import StorageManager from "../../../components/pages/StorageManager/StorageManager";
import { defaultFrontPage, getPageUrl, getUserMainMenu, NoMatchRoute, PrivateRoute } from "../../../configs/menu";
import { useTranslate } from "../../../hooks/useTranslate";
import { setLanguage } from "../../../redux/general-manager/general-thunks-general";
import { EnumPageTypes } from "../../../redux/general-manager/general-types";
import {
    useSelectorCurrentPage,
    useSelectorDesktopUpdateReady,
    useSelectorLanguage,
    useSelectorReconnecting,
    useSelectorUserSession,
} from "../../../redux/selectors";
import { getDesktopVersion, getElectronAPIClient } from "../../../utilitites/desktopUtils";
import * as CloudStorage from "../../../utilitites/storage";
import { TrainingCertificateSignatureDialog } from "../../core/TrainingCertificateSignatureDialog";
import { TrainingCertificationSignatureDialog } from "../../core/TrainingCertificationSignatureDialog";
import FailureTicketPage from "../../pages/FailureTicket/FailureTicketPage";
import InfoPage from "../../pages/Info/InfoPage";
import { PasswordUpdatePage } from "../../pages/PasswordUpdate/PasswordUpdatePage";
import { ScannerPage } from "../../pages/Scanner";
import SchedulerOperatorPage from "../../pages/SchedulerOperator/SchedulerOperatorPage";
import SystemEditor from "../../pages/SettingsEditor/GlobalSettingEditor/GlobalSettings";
import GroupEditor from "../../pages/SettingsEditor/GroupEditor/GroupEditor";
import RoleEditorPage from "../../pages/SettingsEditor/RoleEditor/RoleEditorPage";
import TagEditor from "../../pages/SettingsEditor/TagEditor/TagEditor";
import UserEditorPage from "../../pages/SettingsEditor/UserEditor/UserEditorPage";
import TasksPage from "../../pages/Tasks/TasksPage";
import { TrainingPage } from "../../pages/Training";
import { TrainingPageProvider } from "../../pages/Training/utilities/trainingPageContext";
import { BomPage } from "../../pages/bom";
import Header from "../Header";
import { useSnackbar } from "../snackbarConfigurator";

const drawerWidth = 240;

const useStyles = makeStyles({
    root: {
        height: "100vh",
        width: "100vw",
        zIndex: 1,
        position: "relative",
        display: " grid",
        gridTemplateRows: "auto 1fr",
    },
    mainContent: {
        height: "calc(100vh - 64px)",
        width: "100vw",
    },
    topToolbar: {
        backgroundColor: primaryPalette[500],
        color: "white",
        height: "64px",
        width: "100vw",
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        paddingLeft: "0px",
        boxSizing: "border-box",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        color: "white",
        backgroundColor: "black",
        height: "48px",
    },
    logo: {
        width: "142px",
    },
    content: {
        height: "calc(100vh - 64px)",
        overflowX: "auto",
    },
    drawer: {
        height: "100%",
        boxShadow: theme.shadows[14],
    },
    drawerPaper: {
        display: "flex",
        flexDirection: "column",
        width: `${drawerWidth}px`,
        height: "100%",
        position: "static",
    },
    menuContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    reconnectingDialog: {
        padding: "20px",
    },
    reconnectingDialogContent: {
        width: "250px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        padding: "20px",
    },
    spacer: {
        flexGrow: 1,
    },
    snackbarUpdateAppButton: {
        border: "1px solid",
        color: theme.palette.common.white,
        pointerEvents: "all",
    },
});

export default function AppLayout(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();

    const currentPage = useSelectorCurrentPage();
    const desktopUpdateReady = useSelectorDesktopUpdateReady();
    const navigate = useNavigate();
    const language = useSelectorLanguage();
    const reconnecting = useSelectorReconnecting();
    const [_, setSearchParams] = useSearchParams();
    const snackbar = useSnackbar();
    const userInfo = useSelectorUserSession();

    const [menuOpen, setMenuOpen] = useState(false);

    const menuPages = getUserMainMenu(userInfo);

    // Action related to snack message for update, include button to update
    const updateAction = (): JSX.Element => (
        <Button className={classes.snackbarUpdateAppButton} onClick={handleDesktopUpdateClick}>
            {<Typography variant="button">{translate("appLayout.updateAndRestart")}</Typography>}
        </Button>
    );

    // TODO: create a custom bootstrap effect
    useEffect(() => {
        CloudStorage.bootstrap();
    }, []);

    // Send snackbar on new update available
    useEffect(() => {
        if (desktopUpdateReady === true) {
            snackbar.info(translate("appLayout.updateAvailable").toUpperCase(), {
                action: updateAction,
                persist: true,
            });
        }
    }, [desktopUpdateReady]);

    const handleOpenUsersMenu = (): void => {
        navigate(getPageUrl(EnumPageTypes.SETTINGS_USERS));
    };

    /**
     * Switch to Tasks Page using the router
     **/
    const handleGoToTasksPage = (): void => {
        navigate(getPageUrl(EnumPageTypes.TASKS));
    };

    const handleLogout = (): void => {
        setSearchParams({}); // To prevent automatic reconnection (development only)
        dispatch(logout());
    };

    const handleChangePage = (page: string): void => {
        const pageSanitized = page.replace("/", "");

        if (currentPage !== pageSanitized) {
            // If leaving or entering scheduler or operator scheduler pages, wipe redux for now for incompatibilities with optmized and non-optimized modules.
            if (
                currentPage === EnumPageTypes.SCHEDULER ||
                pageSanitized === EnumPageTypes.SCHEDULER ||
                currentPage === EnumPageTypes.OPERATOR_SCHEDULER ||
                pageSanitized === EnumPageTypes.OPERATOR_SCHEDULER
            ) {
                dispatch(clearSchedulerAction());
                dispatch(clearProcessesAction());
                dispatch(setEditedTreeProcessNodeIdAction(undefined));
            }
        }

        setMenuOpen(false);

        navigate(getPageUrl(pageSanitized as EnumPageTypes));
    };

    const handleGoToHomePage = (): void => {
        navigate(getPageUrl(defaultFrontPage(userInfo)));
    };

    /**
     * Open or close side menu
     */
    const handleDrawerToggle = (): void => {
        setMenuOpen(!menuOpen);
    };

    /**
     * Handle language change
     */
    const handleLanguageChange = (lang: LocationKeys): void => {
        dispatch(setLanguage(lang));
    };

    /**
     * Handle desktop update click
     */
    const handleDesktopUpdateClick = (): void => {
        getElectronAPIClient().services.general.desktopStartUpdate({})();
    };

    return (
        <div className={classes.root}>
            {!(userInfo && userInfo.roleRights.kanban === KanbanRightsEnum.INSERT) && (
                <div className={classes.topToolbar}>
                    <Header
                        onGoToUsersPage={handleOpenUsersMenu}
                        onGoToTasksPage={handleGoToTasksPage}
                        onLogout={handleLogout}
                        onDrawerToggle={handleDrawerToggle}
                        loading={false}
                        language={language}
                        username={userInfo ? userInfo.userName : ""}
                        onLanguageChange={handleLanguageChange}
                    />
                </div>
            )}
            <div className={classes.mainContent}>
                {/* MAIN MENU */}
                <Drawer
                    variant="temporary"
                    open={menuOpen}
                    onClose={handleDrawerToggle}
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <div className={classes.menuContainer}>
                        <KortexSideMenu
                            configList={menuPages}
                            version={"version: " + getDesktopVersion()}
                            currentPage={currentPage}
                            onChangeMenuItem={handleChangePage}
                            onCloseMenu={handleDrawerToggle}
                            onGoToHomePage={handleGoToHomePage}
                        />
                    </div>
                </Drawer>
                {/* MAIN CONTENT  */}
                <main className={classes.content}>
                    <Routes>
                        <Route path={getPageUrl(EnumPageTypes.LOGIN)} element={<LoginPage />} />
                        <Route path={getPageUrl(EnumPageTypes.PASSWORD)} element={<PasswordUpdatePage />} />
                        <Route
                            path={getPageUrl(EnumPageTypes.REPORTS)}
                            element={
                                <PrivateRoute>
                                    <ReportingPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={`${getPageUrl(
                                EnumPageTypes.PROCESS
                            )}/:treeNodeId?/:processId?/:processActionId?/:processActionStepIndex?`}
                            // TODO: Replace `processActionStepIndex` by `processActionStepId` (AOS-2586)
                            element={
                                <PrivateRoute>
                                    <ProcessEditorPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.TRAINING)}
                            element={
                                <PrivateRoute>
                                    <TrainingPageProvider>
                                        <TrainingPage />
                                    </TrainingPageProvider>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.SCHEDULER)}
                            element={
                                <PrivateRoute>
                                    <SchedulerPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.OPERATOR_SCHEDULER)}
                            element={
                                <PrivateRoute>
                                    <SchedulerOperatorPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}
                            element={
                                <PrivateRoute>
                                    <BomPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.REWORKS)}
                            element={
                                <PrivateRoute>
                                    <FailureTicketPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.TASKS)}
                            element={
                                <PrivateRoute>
                                    <TasksPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.STORAGE_MANAGER)}
                            element={
                                <PrivateRoute>
                                    <div style={{ margin: "16px", height: "calc(100% - 32px)" }}>
                                        <StorageManager />
                                    </div>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.KANBAN)}
                            element={
                                <PrivateRoute>
                                    <ScannerPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.INFO)}
                            element={
                                <PrivateRoute>
                                    <InfoPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.SETTINGS_USERS)}
                            element={
                                <PrivateRoute>
                                    <UserEditorPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.SETTINGS_GROUPS)}
                            element={
                                <PrivateRoute>
                                    <GroupEditor />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.SETTINGS_ROLES)}
                            element={
                                <PrivateRoute>
                                    <RoleEditorPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.SETTINGS_TAGS)}
                            element={
                                <PrivateRoute>
                                    <TagEditor />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={getPageUrl(EnumPageTypes.SETTINGS_GLOBALS)}
                            element={
                                <PrivateRoute>
                                    <SystemEditor />
                                </PrivateRoute>
                            }
                        />
                        <Route path="*" element={<NoMatchRoute />} />
                    </Routes>
                </main>
            </div>
            {/* Reconnect Dialog */}
            <Dialog open={reconnecting} className={classes.reconnectingDialog}>
                <DialogContent className={classes.reconnectingDialogContent}>
                    <div className={classes.spacer} />
                    <CircularProgress />
                    <div className={classes.spacer} />
                    {translate("appLayout.reconnecting")}
                    <div className={classes.spacer} />
                </DialogContent>
            </Dialog>
            {userInfo && currentPage !== EnumPageTypes.LOGIN && currentPage !== EnumPageTypes.PASSWORD && (
                <>
                    <TrainingCertificateSignatureDialog userId={userInfo.userId} />
                    <TrainingCertificationSignatureDialog userId={userInfo.userId} />
                </>
            )}
        </div>
    );
}
