import { KortexTextField, theme } from "@aos/react-components";
import MomentUtils from "@date-io/moment";
import { getDateFormat } from "@kortex/aos-common";
import FiltersIcon from "@kortex/aos-ui/components/core/Icons/Filters/Filters";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Button, IconButton, makeStyles, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { IFilters, useBomContext } from "../../context/bomContext";
import { isSearchTypeAdjustmentType, useAdjustmentTypeMenuItems } from "../utils";

const useStyles = makeStyles({
    button: {
        margin: "0 5px 5px",
    },
    datePicker: {
        margin: "5px",
        width: "150px",
    },
    datePickerContainer: {
        display: "flex",
        justifyContent: "space-around",
    },
    datePickerInput: {
        fontSize: "1rem",
        padding: "5px",
    },
    datePickerInMenu: {
        flex: 1,
        padding: "0 5px",
    },
    datePickerInMenuLabel: {
        paddingLeft: "15px",
    },
    footer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        margin: "5px 0",
    },
    iconButton: {
        height: "48px",
        marginRight: "5px",
        width: "48px",
    },
    menu: {},
    tableCell: {
        borderBottom: "unset",
    },
    tableHeaderCell: {
        backgroundColor: theme.palette.grey[300],
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    textField: {
        display: "flex",
    },
});

interface IState {
    anchor: HTMLElement | null;
}

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
}

const FilterMenu: FC<IOwnProps> = (props) => {
    const classes = useStyles(props);
    const { search, setSearch, updateQuery } = useBomContext();
    const intl = useIntl();
    const adjustmentTypeMenuItems = useAdjustmentTypeMenuItems("adjustmentType");
    const adjustmentTypeSerialNumberMenuItems = useAdjustmentTypeMenuItems("adjustmentTypeSerialNumber");
    const translate = useTranslate();

    const [state, setState] = useState<IState>({
        anchor: null,
    });

    const enabledFilters = useMemo((): (keyof IFilters)[] => {
        switch (search.type) {
            case "adjustmentType":
                return ["adjustmentType", "dateFrom", "dateTo", "jobRefId", "partNumber", "quantity", "traceability", "trackingId"];
            case "adjustmentTypeSerialNumber":
                return [
                    "adjustmentTypeSerialNumber",
                    "dateFrom",
                    "dateTo",
                    "jobRefId",
                    "partNumber",
                    "secondaryTraceabilitySerialNumber",
                    "traceability",
                    "trackingId",
                ];
            case "jobRefId":
                return ["dateFrom", "dateTo"];
            case "partNumber":
                return ["dateFrom", "dateTo", "jobRefId", "partNumber", "quantity", "traceability", "trackingId"];
            case "secondaryTraceabilitySerialNumber":
                return ["dateFrom", "dateTo", "jobRefId", "partNumber", "secondaryTraceabilitySerialNumber", "traceability", "trackingId"];
            case "traceability":
                return ["dateFrom", "dateTo", "jobRefId", "partNumber", "quantity", "traceability", "trackingId"];
            default:
                return [];
        }
    }, [search.type]);

    const handleApplyFilter = (): void => {
        updateQuery();
        setState((prevState) => ({ ...prevState, anchor: null }));
    };

    const handleClearFilter = (): void => {
        setSearch((prevState) => ({
            ...prevState,
            filters: isSearchTypeAdjustmentType(search.type) ? { [search.type]: search.filters[search.type] } : {},
        }));
    };

    const handleDateFilterChange =
        (key: "dateFrom" | "dateTo"): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            let timestamp = 0;

            if (date) {
                if (key === "dateFrom") {
                    timestamp = new Date(date.toString()).setHours(0, 0, 0);
                } else {
                    timestamp = new Date(date.toString()).setHours(23, 59, 59, 999);
                }
            }

            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    [key]: timestamp || undefined,
                },
            });
        };

    const handleMenuClose = (): void => {
        setState((prevState) => ({ ...prevState, anchor: null }));
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setState((prevState) => ({ ...prevState, anchor: event.currentTarget }));
    };

    const handleSelectorFilterChange =
        (key: keyof IFilters): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    [key]: event.target.value,
                },
            });
        };

    const handleTextFilterChange =
        (key: keyof IFilters, conversionCb = (value: unknown): unknown => value): ((value: string | number) => void) =>
        (value: string | number): void => {
            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    [key]: value ? conversionCb(value) : undefined,
                },
            });
        };

    const isFilterEnabled = (key: keyof IFilters): boolean => enabledFilters.includes(key);

    return (
        <>
            {(isFilterEnabled("dateFrom") || isFilterEnabled("dateTo")) && (
                <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                    {isFilterEnabled("dateFrom") && (
                        <DatePicker
                            allowKeyboardControl={true}
                            autoOk={true}
                            cancelLabel={translate("general.cancel")}
                            className={classes.datePicker}
                            clearable={true}
                            clearLabel={translate("general.clear")}
                            disableFuture={true}
                            emptyLabel=" "
                            format={search.filters.dateFrom ? getDateFormat() : ""}
                            id="filterDateFromId"
                            inputVariant="outlined"
                            label={translate("bomPage.from")}
                            okLabel={translate("general.select")}
                            onChange={handleDateFilterChange("dateFrom")}
                            value={search.filters.dateFrom || null}
                            variant="dialog"
                        />
                    )}
                    {isFilterEnabled("dateTo") && (
                        <DatePicker
                            allowKeyboardControl={true}
                            autoOk={true}
                            cancelLabel={translate("general.cancel")}
                            className={classes.datePicker}
                            clearable={true}
                            clearLabel={translate("general.clear")}
                            disableFuture={true}
                            emptyLabel=" "
                            format={search.filters.dateTo ? getDateFormat() : ""}
                            id="filterDateToId"
                            inputVariant="outlined"
                            label={translate("bomPage.to")}
                            okLabel={translate("general.select")}
                            onChange={handleDateFilterChange("dateTo")}
                            value={search.filters.dateTo || null}
                            variant="dialog"
                        />
                    )}
                </MuiPickersUtilsProvider>
            )}
            {search.type !== "jobRefId" && search.type !== "trackingId" && (
                <IconButton className={classes.iconButton} id="bomPageFilterButton" onClick={handleMenuOpen}>
                    <FiltersIcon />
                </IconButton>
            )}
            {Boolean(state.anchor) && (
                <Popover anchorEl={state.anchor} className={classes.menu} onClose={handleMenuClose} open={Boolean(state.anchor)}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeaderCell} width="40%">
                                    <Typography variant="h6">{translate("bomPage.filter")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell} width="60%">
                                    <Typography variant="h6">{translate("bomPage.value")}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFilterEnabled("adjustmentType") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.searchType.adjustmentType")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChange={handleSelectorFilterChange("adjustmentType")}
                                            TextFieldProps={{
                                                id: "filterMenuAdjustmentType",
                                                select: true,
                                            }}
                                            value={(search.filters.adjustmentType as string) ?? ""}
                                            variant="standard"
                                        >
                                            {adjustmentTypeMenuItems}
                                        </KortexTextField>
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("adjustmentTypeSerialNumber") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">
                                            {translate("bomPage.searchType.adjustmentTypeSerialNumber")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChange={handleSelectorFilterChange("adjustmentTypeSerialNumber")}
                                            TextFieldProps={{
                                                id: "filterMenuAdjustmentTypeSerialNumber",
                                                select: true,
                                            }}
                                            value={(search.filters.adjustmentTypeSerialNumber as string) ?? ""}
                                            variant="standard"
                                        >
                                            {adjustmentTypeSerialNumberMenuItems}
                                        </KortexTextField>
                                    </TableCell>
                                </TableRow>
                            )}
                            {(isFilterEnabled("dateFrom") || isFilterEnabled("dateTo")) && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.bomTable.date")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <div className={classes.datePickerContainer}>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                {isFilterEnabled("dateFrom") && (
                                                    <DatePicker
                                                        allowKeyboardControl={true}
                                                        autoOk={true}
                                                        cancelLabel={translate("general.cancel")}
                                                        className={classes.datePickerInMenu}
                                                        clearable={true}
                                                        clearLabel={translate("general.clear")}
                                                        emptyLabel=" "
                                                        format={search.filters.dateFrom ? getDateFormat() : ""}
                                                        id="filterMenuDateFromId"
                                                        InputLabelProps={{
                                                            className: classes.datePickerInMenuLabel,
                                                        }}
                                                        inputProps={{
                                                            className: classes.datePickerInput,
                                                        }}
                                                        inputVariant="standard"
                                                        label={translate("bomPage.from")}
                                                        okLabel={translate("general.select")}
                                                        onChange={handleDateFilterChange("dateFrom")}
                                                        value={search.filters.dateFrom || null}
                                                        variant="dialog"
                                                    />
                                                )}
                                                {isFilterEnabled("dateTo") && (
                                                    <DatePicker
                                                        allowKeyboardControl={true}
                                                        autoOk={true}
                                                        cancelLabel={translate("general.cancel")}
                                                        className={classes.datePickerInMenu}
                                                        clearable={true}
                                                        clearLabel={translate("general.clear")}
                                                        emptyLabel=" "
                                                        format={search.filters.dateTo ? getDateFormat() : ""}
                                                        id="filterMenuDateToId"
                                                        InputLabelProps={{
                                                            className: classes.datePickerInMenuLabel,
                                                        }}
                                                        inputProps={{
                                                            className: classes.datePickerInput,
                                                        }}
                                                        inputVariant="standard"
                                                        label={translate("bomPage.to")}
                                                        okLabel={translate("general.select")}
                                                        onChange={handleDateFilterChange("dateTo")}
                                                        value={search.filters.dateTo || null}
                                                        variant="dialog"
                                                    />
                                                )}
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("jobRefId") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.searchType.jobRefId")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChanged={handleTextFilterChange("jobRefId")}
                                            TextFieldProps={{
                                                id: "filterMenuJobRefIdId",
                                            }}
                                            value={search.filters.jobRefId ?? ""}
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("partNumber") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.searchType.partNumber")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChanged={handleTextFilterChange("partNumber")}
                                            TextFieldProps={{
                                                id: "filterMenuPartNumberId",
                                            }}
                                            value={search.filters.partNumber ?? ""}
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("quantity") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.bomTable.quantity")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChanged={handleTextFilterChange("quantity", Number)}
                                            TextFieldProps={{
                                                id: "filterMenuQuantity",
                                            }}
                                            value={search.filters.quantity ?? ""}
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("secondaryTraceabilitySerialNumber") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">
                                            {translate("bomPage.searchType.secondaryTraceabilitySerialNumber")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChanged={handleTextFilterChange("secondaryTraceabilitySerialNumber")}
                                            TextFieldProps={{
                                                id: "filterMenuSecondaryTraceabilitySerialNumber",
                                            }}
                                            value={search.filters.secondaryTraceabilitySerialNumber ?? ""}
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("traceability") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.searchType.traceability")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChanged={handleTextFilterChange("traceability")}
                                            TextFieldProps={{
                                                id: "filterMenuTraceability",
                                            }}
                                            value={search.filters.traceability ?? ""}
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {isFilterEnabled("trackingId") && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">{translate("bomPage.searchType.trackingId")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <KortexTextField
                                            className={classes.textField}
                                            onChanged={handleTextFilterChange("trackingId")}
                                            TextFieldProps={{
                                                id: "filterMenuTrackingIdId",
                                            }}
                                            value={search.filters.trackingId ?? ""}
                                            variant="standard"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <div className={classes.footer}>
                        <Button className={classes.button} color="secondary" onClick={handleClearFilter} variant="outlined">
                            <Typography variant="button">{translate("general.clear")}</Typography>
                        </Button>
                        <Button className={classes.button} color="secondary" onClick={handleApplyFilter} variant="contained">
                            <Typography variant="button">{translate("general.apply")}</Typography>
                        </Button>
                    </div>
                </Popover>
            )}
        </>
    );
};

export default FilterMenu;
