import { IColumns, toCSV } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { writeAndDownloadFile } from "@kortex/aos-ui/utilitites/writeAndDownloadFile";
import { IconButton, Tooltip } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import React, { FC } from "react";

export interface IFile {
    /**
     * Must be an array of objects that all have the same properties.
     */
    data: unknown[];
    /**
     * File name.
     */
    name: string;
}

interface IOwnProps {
    onDownload: () => IFile[];
}

const DownloadCsvButton: FC<IOwnProps> = (props) => {
    const { onDownload } = props;

    const translate = useTranslate();

    const exportCsv = (): void => {
        for (const file of onDownload()) {
            // Extract column names
            const columnNames: IColumns<unknown> = {};

            for (const key of Object.keys((file.data[0] as object) ?? {})) {
                columnNames[key] = key as keyof object;
            }

            // Extract data and create files
            const csvData = toCSV(file.data, columnNames);

            writeAndDownloadFile([csvData], file.name, { type: "text/csv" });
        }
    };

    return (
        <>
            <Tooltip title={translate("bomPage.bomTable.downloadCsv")}>
                <IconButton onClick={exportCsv}>
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DownloadCsvButton;
