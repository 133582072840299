import { theme } from "@aos/react-components";
import { IBomFollowUpDbModel } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Table, TableBody, TablePagination } from "@material-ui/core";
import React, { FC, useState } from "react";

import { useBomContext } from "../../../context";
import { DownloadCsvButton, IFile } from "../../downloadCsvButton";

import { Header } from "./header";
import { Row } from "./row";

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableContainer: {
        height: "calc(100vh - 233px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 54px (table pagination)
        overflowY: "auto",
    },
    tableFooter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface IState {
    page: number;
    rowsPerPage: number;
}

interface IOwnProps {
    followUps: IBomFollowUpDbModel[];
}

const SecondaryTraceabilityTable: FC<IOwnProps> = (props) => {
    const { followUps } = props;

    const { previousSearch } = useBomContext();
    const classes = useStyles();
    const translate = useTranslate();

    const [state, setState] = useState<IState>({
        page: 0,
        rowsPerPage: 20,
    });

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState) => ({ ...prevState, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        }));
    };

    const onDownload = (): IFile[] => [
        {
            data: followUps.map((followUp) => ({
                // Please note that the order of the data below will be the same when exported to a CSV document.
                traceability: followUp.traceability,
                partNumber: followUp.partNumber,
                trackingId: followUp.trackingId,
                jobRefId: followUp.jobRefId,
                updatedOn: followUp.updatedOn,
            })),
            name: `${translate("bomPage.bomTable.bomFollowUp")} - ${translate("bomPage.searchType.secondaryTraceabilitySerialNumber")} - ${
                previousSearch.filters.secondaryTraceabilitySerialNumber
            }.csv`,
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <Table stickyHeader={true}>
                    <Header />
                    <TableBody>
                        {followUps
                            .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                            .map((followUp, index) => (
                                <Row
                                    classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                                    followUp={followUp}
                                    key={`secondaryTraceabilityTableRow${index}`}
                                />
                            ))}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.tableFooter}>
                <DownloadCsvButton onDownload={onDownload} />
                <TablePagination
                    component="div"
                    count={followUps.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={state.page}
                    rowsPerPage={state.rowsPerPage}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>
        </div>
    );
};

export default SecondaryTraceabilityTable;
