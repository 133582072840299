/**
 * Write the data to a file and download it.
 */
export function writeAndDownloadFile(data: BlobPart[], fileName: string, options?: BlobPropertyBag): void {
    // Create a Blob from CSV data
    const blob = new Blob(data, options);

    // Create a download link from the Blob
    const downloadLink = window.URL.createObjectURL(blob);

    // Create an <a> tag to trigger the download
    const anchor = document.createElement("a");
    anchor.href = downloadLink;
    anchor.download = fileName; // Nom du fichier de téléchargement

    // Simulate a click on the link to start the download
    anchor.click();

    // Free up memory by revoking the URL
    window.URL.revokeObjectURL(downloadLink);
}
