import { IBomFollowUp, isBomItemTraceable, IWoBomItem, ProcessActionStepId } from "@kortex/aos-common";
import { useAppLayoutContext } from "@kortex/aos-ui/components/context";
import { MenuType } from "@kortex/aos-ui/components/core/bom";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { processPlayerBomFollowUpInsertFollowUp } from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import { AutoTabField } from "@kortex/aos-ui/utilitites/autoTab";
import { IconButton, InputAdornment, makeStyles, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC, useRef, useState } from "react";

import { usePlayerContext } from "../../../context";
import { PlayerControlsBomItemInfoTooltip } from "../../../controls/content/bom/utilities";
import { SerialNumberRow } from "../serialNumberRow";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
    traceability: {
        margin: 0,
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUp?: IBomFollowUp;
    index: number;
    item: IWoBomItem;
    itemIndex: number;
    onMoreClick: (type: MenuType, followUp?: IBomFollowUp) => (event: React.MouseEvent<HTMLElement>) => void;
    processActionLabel: string;
    processActionStepId: ProcessActionStepId;
    processActionStepLabel: string;
    quantity: number;
    validateBom: boolean;
}

const TraceabilityRow: FC<IOwnProps> = (props) => {
    const {
        followUp,
        index,
        item,
        itemIndex,
        onMoreClick,
        processActionLabel,
        processActionStepId,
        processActionStepLabel,
        quantity,
        validateBom,
    } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const { jobProcessInfo, playerState } = usePlayerContext();
    const translate = useTranslate();

    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const isItemTraceable = isBomItemTraceable(item);
    const optionsButtonDisabled = isBomDisabled || !followUp;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { setLoading } = useAppLayoutContext();

    const [traceabilityError, setTraceabilityError] = useState<string>("");

    const handleTraceabilityChange = (event: React.FocusEvent<HTMLInputElement>): void => {
        setTraceabilityError("");
        const newValue = event.target.value.trim();

        if (!newValue.length || newValue === followUp?.traceability) return void 0;

        setLoading(true);
        dispatch(
            processPlayerBomFollowUpInsertFollowUp({
                bomFollowUpId: followUp?.bomFollowUpId,
                jobRefId: jobProcessInfo!.job.jobRefId,
                partNumber: item.partNumber,
                processActionStepId,
                quantity,
                traceability: newValue,
                trackingId: playerState.processState.serialNumber,
                trackingInstances: playerState.processState.serialNumberInstances.toString(),
                lotSerialType: item.lotSerialType,
            })
        ).then((res) => {
            if (!res) {
                setTraceabilityError(translate("bomPage.bomTable.invalidTraceability"));
                if (inputRef.current) {
                    inputRef.current.select();
                }
            }

            setLoading(false);
        });
    };

    const renderRowsSerialNumber = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < (item.singleSerialNumber ? 1 : quantity); i++) {
            elements.push(
                <SerialNumberRow
                    followUp={followUp}
                    onMoreClick={onMoreClick}
                    index={i}
                    item={item}
                    itemIndex={itemIndex}
                    key={i}
                    traceabilityIndex={index}
                    validateBom={validateBom}
                />
            );
        }

        return elements;
    };

    return (
        <TableRow className={classes.root} id={`playerBomDialogTraceabilityRowId-${itemIndex}-${index}`}>
            {index === 0 && isItemTraceable ? (
                <>
                    {/* ACTION LABEL */}
                    <TableCell id={`playerBomDialogTraceabilityRowTooltipProcessActionLabelId-${itemIndex}-${index}`} width="10%">
                        {processActionLabel}
                    </TableCell>
                    {/* STEP LABEL */}
                    <TableCell id={`playerBomDialogTraceabilityRowTooltipprocessActionStepLabelId-${itemIndex}-${index}`} width="10%">
                        {processActionStepLabel}
                    </TableCell>
                    {/* PART NUMBER */}
                    <PlayerControlsBomItemInfoTooltip item={item} placement="right">
                        <TableCell id={`playerBomDialogTraceabilityRowTooltipPartNumberId-${itemIndex}-${index}`} width="10%">
                            {item.partNumber}
                        </TableCell>
                    </PlayerControlsBomItemInfoTooltip>
                    {/* DESCRIPTION */}
                    <TableCell id={`playerBomDialogTraceabilityRowDescriptionId-${itemIndex}-${index}`} width="35%">
                        {item.description}
                    </TableCell>
                </>
            ) : (
                <TableCell colSpan={4} width="75%" />
            )}
            {/* QUANTITY */}
            <TableCell id={`playerBomDialogTraceabilityRowQuantityId-${itemIndex}-${index}`} width="5%">
                {`${followUp?.isOverconsumption ? "+ " : ""}${quantity}`}
            </TableCell>
            {/* TRACEABILITY */}
            <TableCell id={`playerBomDialogTraceabilityRowTraceabilityId-${itemIndex}-${index}`} width="10%">
                {isItemTraceable ? (
                    <AutoTabField
                        autoTabIndex={[itemIndex, index, -1]}
                        error={
                            traceabilityError !== "" || (validateBom && !Boolean(followUp?.traceability))
                                ? traceabilityError || translate("general.required")
                                : undefined
                        }
                        InputProps={{
                            inputRef: inputRef,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/* OPTIONS BUTTON */}
                                    <IconButton
                                        disabled={optionsButtonDisabled || !Boolean(followUp?.traceability)}
                                        id={`playerBomDialogTraceabilityRowMoreId-${itemIndex}-${index}`}
                                        onClick={onMoreClick("traceability", followUp)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            readOnly: Boolean(followUp?.traceability),
                        }}
                        onBlur={handleTraceabilityChange}
                        TextFieldProps={{
                            autoComplete: "off",
                            className: classes.traceability,
                            id: `playerBomDialogTraceabilityRowTraceabilityInputId-${itemIndex}-${index}`,
                            placeholder: item.lotSerialType,
                        }}
                        value={followUp?.traceability ?? ""}
                        variant="standard"
                    />
                ) : null}
            </TableCell>
            {/* SERIAL NUMBER */}
            <TableCell id={`playerBomDialogTraceabilityRowSerialNumbersId-${itemIndex}-${index}`} width="10%">
                {item.serialized && renderRowsSerialNumber()}
            </TableCell>
        </TableRow>
    );
};

export default TraceabilityRow;
