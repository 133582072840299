import { greyPalette, rainbowPalette, warningPalette } from "@aos/react-components";
import { ApprovalStatusEnum, getProcessReleaseStatus, IProcessDbModel, IProcessUiModel, ProcessReleaseStatus } from "@kortex/aos-common";
import { makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";

import KortexLabelText from "../../../../../components/core/KortexLabelText";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { ILocale } from "../../../../../locales/ILocale";
import { getProcessStatus, ProcessStatus } from "../../../../../utilitites/getProcessStatus";

const useStyles = makeStyles({
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "10px 1fr",
    },
    cardContent: {
        display: "grid",
        gridTemplateRows: "100px 1fr auto",
    },
    cardHeader: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        height: "100px",
        padding: "0px 16px",
        alignItems: "center",
    },
    root: {
        marginBottom: "16px",
        position: "relative",
        backgroundColor: greyPalette[50],
    },
    rootSelected: {
        backgroundColor: greyPalette[200],
    },
});

export function statusColor(process: IProcessDbModel): string {
    const status = getProcessStatus(process);

    switch (status) {
        case ProcessStatus.ARCHIVED:
            return greyPalette[400];
        case ProcessStatus.APPROVED:
            return rainbowPalette[400];
        case ProcessStatus.RELEASED:
            return rainbowPalette[500];
        case ProcessStatus.RETIRED:
            return rainbowPalette[1000];
        case ProcessStatus.CANCELLED:
        case ProcessStatus.REJECTED:
            return rainbowPalette[900];
        case ProcessStatus.WAITING_APPROVAL:
            return rainbowPalette[300];
        case ProcessStatus.HOLD:
            return warningPalette[500];
        default:
            return greyPalette[400];
    }
}

export function getProcessVersionStatusLabelKey(versionStatus?: string): keyof ILocale {
    switch (versionStatus) {
        case ApprovalStatusEnum.APPROVED:
        case ApprovalStatusEnum.CANCELLED:
        case ApprovalStatusEnum.HOLD:
            return "process.versioning.approved";
        case ApprovalStatusEnum.REJECTED:
            return "process.versioning.rejected";
        case ApprovalStatusEnum.AWAITING:
            return "process.versioning.awaiting";
        default:
            return "process.versioning.statusNone";
    }
}

const processReleaseStatusLabelKey: Record<ProcessReleaseStatus, keyof ILocale> = {
    [ProcessReleaseStatus.ARCHIVED]: "process.versioning.archived",
    [ProcessReleaseStatus.AWAITING]: "process.versioning.awaiting",
    [ProcessReleaseStatus.CANCELLED]: "process.versioning.cancelled",
    [ProcessReleaseStatus.HOLD]: "process.versioning.onHold",
    [ProcessReleaseStatus.NONE]: "process.versioning.statusNone",
    [ProcessReleaseStatus.RELEASE_SCHEDULED]: "process.versioning.releaseScheduled",
    [ProcessReleaseStatus.RELEASED]: "process.versioning.released",
    [ProcessReleaseStatus.RETIRED]: "process.versioning.retired",
};

export function getProcessReleaseStatusLabelKey(process: IProcessUiModel): keyof ILocale {
    return processReleaseStatusLabelKey[getProcessReleaseStatus(process)];
}

interface IOwnProps {
    process: IProcessUiModel;
    isSelected: boolean;
}

export default function ProcessApprovalCard(props: IOwnProps): JSX.Element {
    const { isSelected, process } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [isCollapsed, setIsCollapsed] = useState(false);

    const selectedClass = isSelected ? classes.rootSelected : "";

    const handleToggleCollapse = (): void => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Paper className={`${classes.root} ${selectedClass}`} id={`processVersioningVersion${process.version}CardId`}>
            <div className={classes.cardContainer}>
                <div style={{ backgroundColor: statusColor(process) }} />
                <div className={classes.cardContent}>
                    {/* HEADER */}
                    <div className={classes.cardHeader} onClick={handleToggleCollapse}>
                        <KortexLabelText
                            id={`versionNumber${process.version}CardId`}
                            label={translate("process.versioning.version")}
                            text={process.isDraft ? translate("process.versioning.currentDraft") : process.version || ""}
                        />
                        <KortexLabelText
                            id={`versionApprovalStatus=${process.version}CardId`}
                            label={translate("process.versioning.approvalStatus")}
                            text={translate(getProcessVersionStatusLabelKey(process.versionStatus))}
                        />
                        <KortexLabelText
                            id={`versionReleaseStatus${process.version}CardId`}
                            label={translate("process.versioning.releaseStatus")}
                            text={translate(getProcessReleaseStatusLabelKey(process))}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
}
