import { KortexTextField } from "@aos/react-components";
import {
    BomFollowUpMultipleTraceabilitiesReq,
    IBomFollowUpDbModel,
    IBomFollowUpSerializedItemDbModel,
    ISerializedItem,
} from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, MenuItem } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

const useStyles = makeStyles({
    content: {
        display: "flex",
        alignItems: "center",
    },
    checkbox: {
        padding: "0px",
    },
    indentation: {
        width: "50px",
    },
});

export type FollowUpRow = Pick<IBomFollowUpDbModel, "traceability" | "quantity" | "partNumber"> & Partial<IBomFollowUpDbModel>;

export interface ItemReassigned extends Pick<IBomFollowUpSerializedItemDbModel, "bomFollowUpSerializedItemId" | "serialNumber"> {
    indexTraceabilitySelected: number;
}

interface IOwnProps {
    index: number;
    indexTraceability: number;
    serializedItem: Pick<ISerializedItem, "bomFollowUpSerializedItemId" | "serialNumber">;
    traceabilities: BomFollowUpMultipleTraceabilitiesReq;
    onItemSerializedReassigned: (itemSerializedReassigned: ItemReassigned) => void;
}

const SerialNumberSelector: FC<IOwnProps> = (props): JSX.Element => {
    const { index, indexTraceability, serializedItem, onItemSerializedReassigned, traceabilities } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const [indexSelected, setIndexSelected] = useState<number>(indexTraceability);

    useEffect(() => {
        setIndexSelected(indexTraceability);
    }, [traceabilities]);

    /**
     * Updates the serial number of the item
     */
    const handleSerialNumberChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newIndex = parseInt(event.target.value);
        const newValue: ItemReassigned = {
            bomFollowUpSerializedItemId: serializedItem.bomFollowUpSerializedItemId,
            serialNumber: serializedItem.serialNumber,
            indexTraceabilitySelected: newIndex,
        };

        onItemSerializedReassigned(newValue);
        setIndexSelected(newIndex);
    };

    return (
        <div className={classes.content}>
            {/* Indentation */}
            <div className={classes.indentation}></div>
            {/* Serial Number */}
            <KortexTextField
                label={translate("bomPage.bomTable.serialNumber")}
                value={serializedItem.serialNumber}
                variant="standard"
                TextFieldProps={{
                    autoComplete: "off",
                    id: `replacementDialogSerialNumber${index}Id`,
                    disabled: true,
                }}
            />
            {/* Traceability selctor */}
            <KortexTextField
                label={translate("bomPage.bomTable.traceability")}
                onChange={handleSerialNumberChange}
                TextFieldProps={{
                    id: `serialNumberList${index}Id`,
                    select: true,
                }}
                variant="standard"
                value={indexSelected}
            >
                {traceabilities
                    .filter((traceabilityEmpty) => traceabilityEmpty.traceability.length > 0)
                    .map((traceability, index) => (
                        <MenuItem key={index} value={index} id={`traceabilitySelector${index}Id`}>
                            {traceability.traceability}
                        </MenuItem>
                    ))}
            </KortexTextField>
        </div>
    );
};

export default SerialNumberSelector;
