import { greyPalette, magentaPalette, theme } from "@aos/react-components";
import { ProcessEditorRightsEnum } from "@kortex/aos-common";
import { Divider, List, ListItem, Paper, makeStyles } from "@material-ui/core";
// import SmartphoneIcon from "@material-ui/icons/Smartphone"; // FIXME: Enable when Mobile Mode will be implemented
// import VisibilityIcon from "@material-ui/icons/Visibility"; // FIXME: Enable when Preview Mode will be implemented
import { TrainingIcon } from "@kortex/aos-ui/components/core/Icons/training";
import * as React from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import TemplateFullIcon from "../../../../../../../core/Icons/TemplateFull/TemplateFull";
import KortexLabelIcon from "../../../../../../../core/KortexLabelIcon";

const useStyles = makeStyles({
    divider: {
        margin: 0,
    },
    horizontalList: {
        display: "flex",
        flexDirection: "row",
        padding: "0px",
    },
    listItem: {
        width: "61px",
        color: greyPalette[600],
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
    trainingIconHighlighted: {
        backgroundColor: magentaPalette["magenta"],
        color: theme.palette.common.white,
    },
});

export enum EnumDisplayMode {
    MOBILE = "mobile",
    PREVIEW = "preview",
    TEMPLATE_EDITOR = "templateEditor",
    TEMPLATE_SELECTOR = "templateSelector",
    TRAINING_COMMUNIQUE = "trainingCommunique",
}

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    className?: string;
    hidden?: boolean;
    onClick: (type: EnumDisplayMode) => void;
    style?: React.CSSProperties;
    trainingHighlighted: boolean;
}

export default function WorkInstructionsDisplayModesToolbar(props: IOwnProps): JSX.Element {
    const { className, hidden, onClick, trainingHighlighted } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(props.userAccessLevel);

    /**
     * Called when an option from the toolbar is clicked
     *
     * @param {EnumDisplayMode} option - clicked option
     */
    const handleClick =
        (option: EnumDisplayMode): (() => void) =>
        (): void => {
            onClick(option);
        };

    return (
        <Paper className={className} hidden={hidden}>
            <List className={classes.horizontalList}>
                <ListItem
                    button={true}
                    className={`
                        ${classes.listItem}
                        ${trainingHighlighted ? classes.trainingIconHighlighted : ""}
                    `}
                    id="toolbarTrainingCommuniqueEditorId"
                    onClick={handleClick(EnumDisplayMode.TRAINING_COMMUNIQUE)}
                >
                    <KortexLabelIcon fontSize={9} label={translate("action.workInstructions.toolbar.communique")}>
                        <TrainingIcon />
                    </KortexLabelIcon>
                </ListItem>
                <Divider className={classes.divider} flexItem={true} orientation="vertical" variant="middle" />
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarTemplateEditorId"
                    onClick={handleClick(EnumDisplayMode.TEMPLATE_EDITOR)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.saveTemplate")}>
                        <TemplateFullIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarTemplateSelectorId"
                    onClick={handleClick(EnumDisplayMode.TEMPLATE_SELECTOR)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.selectTemplate")}>
                        <TemplateFullIcon />
                    </KortexLabelIcon>
                </ListItem>
                {/* FIXME: Enable when Mobile Mode will be implemented */}
                {/*
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarMobile"
                    onClick={handleClick(EnumDisplayMode.MOBILE)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.mobile" })}>
                        <SmartphoneIcon />
                    </KortexLabelIcon>
                </ListItem>
                */}
                {/* FIXME: Enable when Preview Mode will be implemented */}
                {/*
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarPreview"
                    onClick={handleClick(EnumDisplayMode.PREVIEW)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.preview" })}>
                        <VisibilityIcon />
                    </KortexLabelIcon>
                </ListItem>
                */}
            </List>
        </Paper>
    );
}
