import { IProcessUiModel, OrNull, OrUndefined, ProcessId } from "@kortex/aos-common";
import { processGet } from "@kortex/aos-ui/redux/process-manager/process-thunks-process";
import { useSelectorProcesses } from "@kortex/aos-ui/redux/selectors";
import { useState } from "react";

import { useThunkDispatch } from "../useThunkDispatch";

type SetProcess = (processId: OrNull<ProcessId>, includeActions?: boolean) => Promise<void>;
type UseProcess = [OrUndefined<IProcessUiModel>, SetProcess];

interface IState {
    includeActions: boolean;
    processId: OrNull<ProcessId>;
}

export function useProcess(processId: ProcessId, includeActions = true): UseProcess {
    const dispatch = useThunkDispatch();

    const [state, setState] = useState<IState>({ processId, includeActions });

    const process = useSelectorProcesses().find((p) => p.processId === state.processId);

    const setProcess: SetProcess = async (processId, includeActions = true) => {
        if (processId) {
            await dispatch(processGet(processId, includeActions));
        }

        setState({ processId, includeActions });
    };

    return [process, setProcess];
}
