import {
    BomFollowUpAssertTraceabilityNotExpiredReq,
    BomFollowUpAssertTraceabilityNotExpiredRes,
    BomFollowUpEditItemReq,
    BomFollowUpEditItemRes,
    BomFollowUpEditSerializedItemReq,
    BomFollowUpEditSerializedItemRes,
    BomFollowUpInsertReq,
    BomFollowUpInsertRes,
    BomFollowUpInsertSerializedItemReq,
    BomFollowUpInsertSerializedItemRes,
    BomFollowUpMultipleTraceabilitiesItemReq,
    BomFollowUpMultipleTraceabilitiesItemRes,
    BomFollowUpOverconsumeItemReq,
    BomFollowUpOverconsumeItemRes,
    BomFollowUpOverconsumeNonTraceableItemReq,
    BomFollowUpOverconsumeNonTraceableItemRes,
    BomFollowUpRemoveItemReq,
    BomFollowUpRemoveItemRes,
    BomFollowUpRemoveSerializedItemReq,
    BomFollowUpRemoveSerializedItemRes,
    BomFollowUpReplaceItemReq,
    BomFollowUpReplaceItemRes,
    BomFollowUpReplaceSerializedItemReq,
    BomFollowUpReplaceSerializedItemRes,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import {
    bomItemEditedAction,
    bomItemInsertedAction,
    bomItemMultipleTraceabilitiesAction,
    bomItemOverconsumedAction,
    bomItemRemovedAction,
    bomItemReplacedAction,
    bomSerializedItemInsertedAction,
} from "../bom-manager/bom-actions";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

/**
 * Export ERP services from BOM
 */
export {
    bomClearBoms as processPlayerBomFollowUpClearBoms,
    bomGetByTrackingId as processPlayerBomFollowUpGetByTrackingId,
    bomGetWoBom as processPlayerBomFollowUpGetWoBom,
} from "../bom-manager/bom-thunks";

/**
 * Edit a BOM item
 */
export function processPlayerBomFollowUpEditItem(
    followUp: UnwrapAOSPayload<BomFollowUpEditItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpEditItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpEditItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpEditItem(followUp)()
            .then((res) => {
                dispatch(bomItemEditedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Edit a BOM item serialized
 */
export function processPlayerBomFollowUpEditSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpEditSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpEditSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpEditSerializedItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpEditSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomItemEditedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Create a BOM follow-up
 */
export function processPlayerBomFollowUpInsertFollowUp(
    followUp: UnwrapAOSPayload<BomFollowUpInsertReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpInsertRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpInsertRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpInsert(followUp)()
            .then((res) => {
                if (followUp?.bomFollowUpId) {
                    dispatch(bomItemEditedAction(res));
                } else {
                    dispatch(bomItemInsertedAction(res));
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Create a BOM follow-up serialized item
 */
export function processPlayerBomFollowUpInsertFollowUpSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpInsertSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpInsertSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpInsertSerializedItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpInsertSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomSerializedItemInsertedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Overconsume a BOM item
 */
export function processPlayerBomFollowUpOverconsumeItem(
    followUp: UnwrapAOSPayload<BomFollowUpOverconsumeItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpOverconsumeItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpOverconsumeItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpOverconsumeItem(followUp)()
            .then((res) => {
                dispatch(bomItemOverconsumedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Overconsume a BOM not traceable item
 */
export function processPlayerBomFollowUpOverconsumeNonTraceableItem(
    followUp: UnwrapAOSPayload<BomFollowUpOverconsumeNonTraceableItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpOverconsumeNonTraceableItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpOverconsumeNonTraceableItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpOverconsumeNonTraceableItem(followUp)()
            .then((res) => {
                dispatch(bomItemInsertedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Remove a BOM item
 */
export function processPlayerBomFollowUpRemoveItem(
    followUp: UnwrapAOSPayload<BomFollowUpRemoveItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpRemoveItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpRemoveItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpRemoveItem(followUp)()
            .then(([...res]) => {
                dispatch(bomItemRemovedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Remove a BOM serialized item
 */
export function processPlayerBomFollowUpRemoveSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpRemoveSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpRemoveSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpRemoveSerializedItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpRemoveSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomItemRemovedAction([res]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Replace a BOM item
 */
export function processPlayerBomFollowUpReplaceItem(
    followUp: UnwrapAOSPayload<BomFollowUpReplaceItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpReplaceItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpReplaceItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpReplaceItem(followUp)()
            .then(([...res]) => {
                dispatch(bomItemReplacedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Replace a BOM item serialized
 */
export function processPlayerBomFollowUpReplaceSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpReplaceSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpReplaceSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpReplaceSerializedItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpReplaceSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomItemReplacedAction([res]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Make a BOM item have multiple traceabilities
 */
export function processPlayerBomFollowUpMultipleTraceabilitiesItem(
    followUp: UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemRes> | undefined> {
        return api.services.processPlayer
            .bomFollowUpMultipleTraceabilitiesItem(followUp)()
            .then(([...res]) => {
                dispatch(bomItemMultipleTraceabilitiesAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * BOM item check traceability
 */
export function processPlayerBomFollowUpAssertTraceabilityNotExpired(
    followUp: UnwrapAOSPayload<BomFollowUpAssertTraceabilityNotExpiredReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpAssertTraceabilityNotExpiredRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpAssertTraceabilityNotExpiredRes> | undefined> {
        return api.services.bomFollowUp
            .bomFollowUpAssertTraceabilityItemNotExpired(followUp)()
            .then((res) => {
                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}
