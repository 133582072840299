import { theme } from "@aos/react-components";
import { getDateStr, IGetSerializedItemsByTypeAjustmentRes } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Table, TableBody, TablePagination, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";

import { useBomContext } from "../../../context";
import { DownloadCsvButton, IFile } from "../../downloadCsvButton";

import { Header } from "./header";
import { Row } from "./row";

const useStyles = makeStyles({
    root: {
        display: "grid",
        height: "calc(100vh - 244px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 49px (adjustment type tabs) + 16px (adjustment type tabs margin)
        overflowY: "auto",
    },
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableContainer: {
        height: "calc(100vh - 298px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 49px (adjustment type tabs) + 16px (adjustment type tabs margin) + 54px (table pagination)
        overflowY: "auto",
    },
    tableContainerCaptionEnabled: {
        height: "calc(100vh - 318px)", // tableContainer height - 20px (typography)
    },
    tableFooter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface IState {
    page: number;
    rowsPerPage: number;
}

interface IOwnProps {
    serialNumberHistories: IGetSerializedItemsByTypeAjustmentRes;
}

const AdjustmentTypeOperationTable: FC<IOwnProps> = (props) => {
    const { serialNumberHistories } = props;

    const { previousSearch } = useBomContext();
    const classes = useStyles();
    const translate = useTranslate();

    const [state, setState] = useState<IState>({
        page: 0,
        rowsPerPage: 15,
    });

    const emptySerialNumberFound = Boolean(serialNumberHistories.find((history) => !history.serialNumber));

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState) => ({ ...prevState, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        }));
    };

    const onDownload = (): IFile[] => [
        {
            data: serialNumberHistories.map((history) => ({
                // Please note that the order of the data below will be the same when exported to a CSV document.
                serialNumber: history.serialNumber || history.previousValues.serialNumber || translate("general.na"),
                quantity: history.bomFollowUpSerializedItem.bomFollowUp.quantity,
                traceability: history.bomFollowUpSerializedItem.bomFollowUp.traceability,
                partNumber: history.bomFollowUpSerializedItem.bomFollowUp.partNumber,
                trackingId: history.bomFollowUpSerializedItem.bomFollowUp.trackingId,
                jobRefId: history.bomFollowUpSerializedItem.bomFollowUp.jobRefId,
                createdOn: history.createdOn,
                symptom: history.symptom?.title ?? "",
                justification: history.justification,
            })),
            name: `${translate("bomPage.bomTable.bomFollowUp")} - ${translate(
                "bomPage.searchType.adjustmentTypeSerialNumber"
            )} - ${translate(`bomPage.adjustmentType.${previousSearch.filters.adjustmentTypeSerialNumber}`)} - ${translate(
                "bomPage.bomTable.operations"
            )}${previousSearch.filters.dateFrom ? ` - ${getDateStr(previousSearch.filters.dateFrom)}` : ""}${
                previousSearch.filters.dateTo ? ` - ${getDateStr(previousSearch.filters.dateTo)}` : ""
            }.csv`,
        },
    ];

    return (
        <div className={classes.root}>
            {emptySerialNumberFound && (
                // At least 1 history has no serial number
                <Typography color="error" variant="caption">
                    {`1: ${translate("bomPage.bomTable.serialNumberNoLongerExists")}`}
                </Typography>
            )}
            <div className={`${classes.tableContainer} ${emptySerialNumberFound ? classes.tableContainerCaptionEnabled : ""}`}>
                <Table stickyHeader={true}>
                    <Header />
                    <TableBody>
                        {serialNumberHistories
                            .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                            .map((serialNumberHistory, index) => (
                                <Row
                                    classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                                    serialNumberHistory={serialNumberHistory}
                                    key={`adjustmentTypeSerialNumberOperationTableRow${index}`}
                                />
                            ))}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.tableFooter}>
                <DownloadCsvButton onDownload={onDownload} />
                <TablePagination
                    component="div"
                    count={serialNumberHistories.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={state.page}
                    rowsPerPage={state.rowsPerPage}
                    rowsPerPageOptions={[15, 50, 100]}
                />
            </div>
        </div>
    );
};

export default AdjustmentTypeOperationTable;
