import { IBomFollowUp, isBomItemTraceable, ISerializedItem, IWoBomItem, OrUndefined } from "@kortex/aos-common";
import { makeStyles, PopoverPosition } from "@material-ui/core";
import React, { FC, useState } from "react";

import { MenuType, NonTraceableItemMenu, TraceabilityMenu } from "../../../bom";
import { usePlayerContext } from "../../context";

import { NonTraceableRow } from "./nonTraceableRow";
import { TraceabilityRow } from "./traceabilityRow";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    itemIndex: number;
    processActionLabel: string;
    processActionStepId: number;
    processActionStepLabel: string;
    validateBom: boolean;
}

const ProcessBomDialogRow: FC<IOwnProps> = (props) => {
    const { item, itemIndex, processActionLabel, processActionStepId, processActionStepLabel, validateBom } = props;

    const classes = useStyles(props);
    const { jobProcessInfo, playerState, woBom, process } = usePlayerContext();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>(undefined);
    const [menuType, setMenuType] = useState<MenuType>("traceability");
    const [followUpLineSelected, setFollowUpLineSelected] = useState<OrUndefined<IBomFollowUp>>(undefined);
    const [serializedItemSelected, setSerializedItemSelected] = useState<ISerializedItem | undefined>(undefined);

    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const isItemTraceable = isBomItemTraceable(item);
    const optionsButtonDisabled = isBomDisabled || !item.followUp[processActionStepId];

    const handleDialogClose = (): void => {
        setFollowUpLineSelected(undefined);
        setSerializedItemSelected(undefined);
    };

    const handleMenuClose = (): void => {
        setMenuPosition(undefined);
    };

    /**
     * Opens the options menu
     */
    const handleMoreClick =
        (type: MenuType, followUp?: IBomFollowUp, serializedItem?: ISerializedItem): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            if (type === "serial") setSerializedItemSelected(serializedItem);

            setMenuType(type);
            setFollowUpLineSelected(followUp);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    /**
     * Renders a table row for each traceability
     */
    const renderRows = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        let remainingQuantity = item.quantity;

        // Add initial row if item is non-traceable
        if (!isItemTraceable) {
            elements.push(
                <NonTraceableRow
                    classes={{ root: classes.root }}
                    item={item}
                    key={`nonTraceableRow-${elements.length}`}
                    onMoreClick={handleMoreClick}
                    processActionLabel={processActionLabel}
                    processActionStepLabel={processActionStepLabel}
                />
            );

            remainingQuantity = 0;
        }

        // Check if we need an additional row (for the remainder)
        for (const followUp of item.followUp[processActionStepId] ?? []) {
            if (!followUp.isOverconsumption) remainingQuantity -= followUp.quantity;
        }

        // How many rows do we need to render?
        const rowCount =
            item.quantity === 0 && isItemTraceable
                ? 1
                : (item.followUp[processActionStepId]?.length ?? 0) + (remainingQuantity > 0 ? 1 : 0);

        // Add traceability rows
        for (let i = 0; i < rowCount; i++) {
            elements.push(
                <TraceabilityRow
                    classes={{ root: classes.root }}
                    followUp={item.followUp[processActionStepId]?.[i]}
                    index={i}
                    item={item}
                    itemIndex={itemIndex}
                    key={`traceableRow-${i}`}
                    onMoreClick={handleMoreClick}
                    processActionLabel={processActionLabel}
                    processActionStepId={processActionStepId}
                    processActionStepLabel={processActionStepLabel}
                    quantity={item.followUp[processActionStepId]?.[i]?.quantity ?? remainingQuantity}
                    validateBom={validateBom}
                />
            );
        }

        return elements;
    };

    return (
        <>
            {/* ROWS */}
            {renderRows()}
            {/* OPTIONS MENU */}
            {!optionsButtonDisabled && followUpLineSelected ? (
                <TraceabilityMenu
                    followUp={followUpLineSelected}
                    menuPosition={menuPosition}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    serializedItem={serializedItemSelected}
                    type={menuType}
                    variant="player"
                    treeNodeId={process?.treeNodeId}
                    isQuantityDecimal={item.isQuantityDecimal}
                />
            ) : null}
            {!isItemTraceable && woBom && item.quantity > 0 ? (
                <NonTraceableItemMenu
                    item={item}
                    menuPosition={menuPosition}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    processActionStepId={processActionStepId}
                    woBom={woBom}
                    variant="player"
                />
            ) : null}
        </>
    );
};

export default ProcessBomDialogRow;
