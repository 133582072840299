import { isBomItemTraceable, IWoBomComplete, IWoBomItem, IWoBomTracked, WoBomItems } from "@kortex/aos-common";

import { sortWoBomTableItemFollowUpRows } from "../../items/item/utils";

import { IWoBomItemFormatted, IWoBomItemParams } from "./types";

export function formatWoBom(woBom: IWoBomComplete): IWoBomItemFormatted[] {
    const [traceableItems, nonTraceableItems] = getFilteredItems(woBom.woBom, "en");

    return Object.values(traceableItems)
        .map((item) => addItems(item, woBom.workInstructionsItems))
        .concat(Object.values(nonTraceableItems).map((item) => addItems(item, woBom.workInstructionsItems)))
        .flat();
}

/**
 * Returns a filtered list of the items based on whether or not they are traceable
 */
function getFilteredItems(woBom: IWoBomTracked, language: string): [traceableItems: WoBomItems, nonTraceableItems: WoBomItems] {
    const traceableItems: WoBomItems = {};
    const nonTraceableItems: WoBomItems = {};

    for (const partNumber of Object.keys(woBom.items).sort((a, b) => a.localeCompare(b, language, { sensitivity: "base" }))) {
        // Filter items based on whether or not they are traceable
        if (isBomItemTraceable(woBom.items[partNumber])) {
            traceableItems[partNumber] = woBom.items[partNumber];
        } else {
            nonTraceableItems[partNumber] = woBom.items[partNumber];
        }
    }

    return [traceableItems, nonTraceableItems];
}

function addItems(item: IWoBomItem, workInstructionsItems: IWoBomComplete["workInstructionsItems"] = {}): IWoBomItemFormatted[] {
    const isItemTraceable = isBomItemTraceable(item);
    const rows: IWoBomItemFormatted[] = [];
    const wiItems = workInstructionsItems[item.partNumber] ?? [];

    let quantityRemaining = item.quantity;

    //-------------------------------------------------------------------------------
    // Items assigned to a work instructions step
    //-------------------------------------------------------------------------------

    // Add row for each step in which the item was included
    for (const workInstructionsItem of wiItems) {
        let workInstructionsItemQuantityRemaining = workInstructionsItem.quantity;

        // If item is not traceable, add base row (no follow-up) before follow-up rows
        if (!isItemTraceable) {
            rows.push(
                formatItem({
                    index: rows.length,
                    item,
                    workInstructionsItem,
                })
            );

            quantityRemaining -= workInstructionsItemQuantityRemaining;
        }

        // Follow-up rows
        for (const followUp of sortWoBomTableItemFollowUpRows(
            item.followUp[workInstructionsItem.processActionStep.processActionStepId] ?? []
        )) {
            rows.push(
                formatItem({
                    followUp,
                    index: rows.length,
                    item,
                    workInstructionsItem,
                })
            );

            if (!followUp.isOverconsumption) {
                const diff =
                    followUp.quantity === 0
                        ? 1 // To take into account items with a quantity of 0
                        : followUp.quantity;

                workInstructionsItemQuantityRemaining -= diff;
                quantityRemaining -= diff;
            }
        }

        // Add rows with empty traceability if the item is traceable
        if (isItemTraceable && workInstructionsItemQuantityRemaining > 0) {
            rows.push(
                formatItem({
                    index: rows.length,
                    item,
                    quantity: workInstructionsItemQuantityRemaining,
                    workInstructionsItem,
                })
            );

            quantityRemaining -= workInstructionsItemQuantityRemaining;
        }
    }

    //-------------------------------------------------------------------------------
    // Items not assigned to a work instructions step
    //-------------------------------------------------------------------------------

    // Add non-traceable row as the first row
    if (!isItemTraceable && (quantityRemaining > 0 || item.quantity === 0)) {
        rows.push(
            formatItem({
                index: rows.length,
                item,
                quantity: quantityRemaining,
            })
        );
    }

    // Add rows for each follow-up manually added in the BOM page (index 0)
    for (const followUp of sortWoBomTableItemFollowUpRows(item.followUp[0] ?? [])) {
        rows.push(
            formatItem({
                followUp,
                index: rows.length,
                item,
            })
        );

        if (!followUp.isOverconsumption) {
            quantityRemaining -=
                followUp.quantity === 0
                    ? 1 // To take into account items with a quantity of 0
                    : followUp.quantity;
        }
    }

    // Add remaining rows with empty traceability
    if (isItemTraceable) {
        if (quantityRemaining > 0 || (item.quantity === 0 && quantityRemaining === 0)) {
            rows.push(
                formatItem({
                    index: rows.length,
                    item,
                    quantity: quantityRemaining,
                })
            );
        }
    }

    return rows;
}

function formatItem(row: IWoBomItemParams): IWoBomItemFormatted {
    // Please note that the order of the data below will be the same when exported to a CSV document.
    return {
        partNumber: row.item.partNumber,
        description: row.item.description,
        location: row.item.location,
        materialLocation: row.item.materialLocation,
        lotSerialType: row.item.lotSerialType,
        quantity: row.quantity ?? row.followUp?.quantity ?? row.item.quantity,
        isOverconsumption: Boolean(row.followUp?.isOverconsumption),
        traceability: row.followUp?.traceability ?? "",
        serialNumbers: row.followUp?.serializedItems.map((serializedItem) => serializedItem.serialNumber).join() ?? "",
    };
}
