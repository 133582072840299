import { KortexDialogConfirmation, theme } from "@aos/react-components";
import { getProcessWithLinkedActions, isStepTraceabilityComplete, IWoBomItem, WoBomItems } from "@kortex/aos-common";
import { AutoTabProvider } from "@kortex/aos-ui/utilitites/autoTab";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { usePlayerContext } from "../../context";
import { PlayerControlsBomReload } from "../../controls/content/bom/reload";

import ProcessBomDialogRow from "./ProcessBomDialogRow";

const useStyles = makeStyles({
    header: {
        backgroundColor: theme.palette.grey[300],
    },
    itemEven: {
        backgroundColor: theme.palette.grey[100],
    },
    itemOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
});

interface IOwnProps {
    open: boolean;
    onClose: () => void;
    onValidated: () => void;
    validateBom: boolean;
}

function ProcessBomDialog(props: IOwnProps): JSX.Element {
    const { open, onClose, onValidated, validateBom } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const { process, woBom } = usePlayerContext();

    const actions = process ? getProcessWithLinkedActions(process).actions : [];
    const confirmButtonDisabled = ((): boolean => {
        // Button is be disabled if BOM validation is not required

        if (!validateBom) return true;

        // Do not block user if WO BOM is not loaded
        if (!woBom) return false;

        // Button is disabled if at least 1 traceability is blank
        return actions.some((action) => {
            if (action.type !== "core-work-instructions") return false;

            return action.steps.some((step) => {
                const woBomItems: WoBomItems = {};

                for (const item of Object.values(step.config.bomItems)) {
                    // Validate if the item is in the BOM
                    if (woBom.items[item.partNumber]) {
                        woBomItems[item.partNumber] = {
                            ...woBom.items[item.partNumber],
                            quantity: item.quantity,
                            serialized: Boolean(item.serialized),
                        };
                    }
                }

                return !isStepTraceabilityComplete(step.processActionStepId, woBomItems);
            });
        });
    })();

    /**
     * handle the continue
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * handle the continue
     */
    const handleValidate = (): void => {
        onValidated();
    };

    /**
     * Render all line of ProcessBomDialogRow
     */
    const render = (): JSX.Element[] => {
        // Initialize an object to store BOM items for this step
        const rows: JSX.Element[] = [];

        if (!woBom || Object.keys(woBom.items).length === 0) return rows;

        // Get the BOM items that have been assigned to the process
        for (const action of actions) {
            if (action.type !== "core-work-instructions") continue;

            let actionItemCounter = 0; // Will be used to display the action name only for the first item of the action

            for (const step of action.steps) {
                for (const [itemIndex, item] of Object.values(step.config.bomItems).entries()) {
                    if (woBom.items[item.partNumber]) {
                        const bomItem: IWoBomItem = {
                            ...woBom.items[item.partNumber],
                            quantity: item.quantity,
                            serialized: Boolean(item.serialized),
                            singleSerialNumber: Boolean(item.singleSerialNumber),
                        };

                        rows.push(
                            <ProcessBomDialogRow
                                classes={{
                                    root: rows.length % 2 === 0 ? classes.itemEven : classes.itemOdd,
                                }}
                                item={bomItem}
                                itemIndex={rows.length}
                                key={`processBomDialogRowId-${step.processActionStepId}-${itemIndex}`}
                                processActionLabel={
                                    // Do not display the action name if it's the same as the previous
                                    actionItemCounter === 0 ? action.label : ""
                                }
                                processActionStepId={step.processActionStepId}
                                processActionStepLabel={
                                    // Do not display the step name if it's the same as the previous
                                    itemIndex === 0 ? step.label : ""
                                }
                                validateBom={validateBom}
                            />
                        );
                    }

                    actionItemCounter++;
                }
            }
        }

        // Return the array of rows
        return rows;
    };

    return (
        <KortexDialogConfirmation
            confirmDisabled={confirmButtonDisabled}
            dialogProps={{
                disableAutoFocus: true,
                id: "processBomDialogId",
                maxWidth: "xl",
            }}
            onCancel={handleClose}
            onConfirm={handleValidate}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.close"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("player.bomFollowUp"),
            }}
        >
            <Table>
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell width="10%">{translate("player.bom.actionName")}</TableCell>
                        <TableCell width="10%">{translate("player.bom.stepName")}</TableCell>
                        <TableCell width="10%">{translate("player.bom.partNumber")}</TableCell>
                        <TableCell width="35%">{translate("player.bom.description")}</TableCell>
                        <TableCell width="5%">{translate("player.bom.quantity")}</TableCell>
                        <TableCell width="15%">{translate("player.bom.traceability")}</TableCell>
                        <TableCell width="15%">{translate("player.bom.serialNumber")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{woBom ? <AutoTabProvider>{render()}</AutoTabProvider> : <PlayerControlsBomReload colSpan={7} />}</TableBody>
            </Table>
        </KortexDialogConfirmation>
    );
}

export default ProcessBomDialog;
