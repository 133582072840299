import { KortexDialogConfirmation } from "@aos/react-components";
import {
    ITaskInfoProcessApproval,
    IUserDbModel,
    IUserGroupDbModel,
    ProcessUiModel,
    TTask,
    TaskTypeEnum,
    getProcessWithLinkedActions,
} from "@kortex/aos-common";
import { CircularProgress, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { useSelectorTreeProcess } from "../../../redux/selectors";

import PrintProcessDocument from "./PrintProcessDocument";
import { printProcessDocumentPDF } from "./printProcessDocumentPDF";
import { IPrintProcessDocumentDynamicSections } from "./printUtils";

const useStyles = makeStyles({
    circularProgress: {
        height: "24px !important", // Overwrite the style (set by material-ui) that is applied on top of this class
        marginRight: "10px",
        width: "24px !important", // Overwrite the style (set by material-ui) that is applied on top of this class
    },
});
interface IPrintProcessDialogProps {
    onClose: () => void;
    open: boolean;
    process: ProcessUiModel;
    processVersions: ProcessUiModel[];
    tasks: TTask<TaskTypeEnum.PROCESS_APPROVAL, ITaskInfoProcessApproval>[];
    userGroups: IUserGroupDbModel[];
    userList: IUserDbModel[];
}

export default function PrintProcessDialog(props: IPrintProcessDialogProps): JSX.Element {
    const { onClose, open, process: completeProcess, processVersions, tasks, userGroups, userList } = props;
    const [dynamicSections, setDynamicSections] = useState<IPrintProcessDocumentDynamicSections | null>(null);

    const classes = useStyles();
    const translate = useTranslate();
    const treeNodes = useSelectorTreeProcess();

    const [loading, setLoading] = useState<boolean>(false);

    const process = getProcessWithLinkedActions(completeProcess); // Remove all actions that are not linked in the process
    const treeNode = treeNodes.find((treeNode) => treeNode.treeNodeId === process?.treeNodeId)!;

    /**
     * Called when canceling the PDF print
     */
    const handleCloseClick = (): void => {
        if (onClose) {
            onClose();
        }
    };

    /**
     * Called when confirming the PDF print
     */
    const handlePrintClick = (): void => {
        if (!dynamicSections) return void 0;

        setLoading(true);

        printProcessDocumentPDF({
            intl: translate.intl,
            process,
            processVersions,
            dynamicSections,
            treeNode,
            userList,
            userGroups,
            tasks,
        })
            .then(() => {
                props.onClose?.();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates dynamic sections (inner state) of the PDF such as revision history and table of content
     *
     * @param {IPrintProcessDocumentDynamicSections} sections - document sections which can change during the printing process
     */
    const handleDynamicSectionChange = (sections: IPrintProcessDocumentDynamicSections): void => {
        setDynamicSections(sections);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={loading}
            dialogProps={{
                fullWidth: true,
                maxWidth: "lg",
            }}
            onCancel={handleCloseClick}
            onConfirm={handlePrintClick}
            open={open}
            textLabels={{
                titleLabel: translate("process.printing.preview"),
                cancelButtonLabel: translate("process.printing.cancel"),
                proceedButtonLabel: translate("process.printing.export"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress className={classes.circularProgress} /> : undefined,
            }}
        >
            <PrintProcessDocument
                onDynamicSectionChange={handleDynamicSectionChange}
                process={process}
                processVersions={processVersions}
                tasks={tasks}
                treeNode={treeNode}
                userGroups={userGroups}
                userList={userList}
            />
        </KortexDialogConfirmation>
    );
}
