import { JOB_QTY_MAX } from "../../../../../Job/jobUtils";
export const SUGGESTION_EN = {
    "suggestionKeyError.actionRequiredVersionDraft": "The current action required a draft version of the process.",
    "suggestionKeyError.associateExecutionAtOneFailureTicket": "For this process to create a repair job and a new failure ticket, it must be scheduled in a failure ticket.",
    "suggestionKeyError.changeStatusOfTheJobToUpdateIt": "Change the job status to be able to update the job.",
    "suggestionKeyError.contactAdminUser": "Contact the admin user.",
    "suggestionKeyError.contactAosDevTeam": "Contact the AOS development team.",
    "suggestionKeyError.contactResponsibleProcessApprovedOrReleaseInRouting": "Contact those responsible for the approval and release of the processes.",
    "suggestionKeyError.completeTheChangelog": "The changelog must be completed in order to create a new version.",
    "suggestionKeyError.completeTheReferenceId": "The reference ID must be completed in order to create a new version.",
    "suggestionKeyError.completeTrainingCertificate": "Complete a training certificate.",
    "suggestionKeyError.emptySuggestion": " ",
    "suggestionKeyError.expectedPasswordFormat": "Password must be at least 8 characters long and contain at least 1 capital letter and 1 number.",
    "suggestionKeyError.findOrCreateJob": "Find the job already created or create another job.",
    "suggestionKeyError.findOrCreateTraining": "Find the training already created or select another version of the process to create the training.",
    "suggestionKeyError.jobQtyMaxReach": `Set a quantity equal to or less than ${JOB_QTY_MAX}.`,
    "suggestionKeyError.makeSureEnteredInfoCorrectly": "Make sure you have entered the information correctly or contact the administrator to activate the user.",
    "suggestionKeyError.makeSureTraceabilityEnteredIsDesired": "Make sure that the traceability entered is the one desired.",
    "suggestionKeyError.makeSureTraceabilityEnteredIsNotExpired": "Make sure that the entered traceability has not expired.",
    "suggestionKeyError.makeSureEachProcessInRoutingApproved": "Make sure that each process used in routing has an approved version.",
    "suggestionKeyError.makeSureUsernameAndPasswordEnteredCorrectlyThenTryAgain": "Make sure the username and password were entered correctly, then try again.",
    "suggestionKeyError.processVersionStatusRequiredForTrainingRequirementUpdate": 
    // eslint-disable-next-line
    'The status of the process version must "Awaiting".',
    "suggestionKeyError.releaseDateNotEqualOrLaterThanRetiredDate": "Make sure that the release date is before the retired date.",
    "suggestionKeyError.selectAllMandatoryApprovalGroups": "Select all approval groups required to create a new version.",
    "suggestionKeyError.selectVersion.OtherThanDraft": "Select a version other than the draft.",
    "suggestionKeyError.storeResultError": "The system will attempt to address the situation automatically. If this error occurs too frequently, contact the AOS development team.",
    "suggestionKeyError.unknownError": "Unknown error.",
    "suggestionKeyError.useAnotherIdentifier": "Use another identifier.",
    "suggestionKeyError.useAnotherPrefix": "Use another prefix than SYS_.",
    "suggestionKeyError.useAnotherUsername": "Use another username, that username already exists.",
    "suggestionKeyError.useAnotherUsercode": "Use another usercode, that usercode already exists.",
    "suggestionKeyError.validateBomIdAndRevision": "Verify that the BOM ID and revision are valid.",
    "suggestionKeyError.validateJobOrBom": "Verify that the job exists in the ERP system. Verify that the BOM ID and revision are valid.",
};
