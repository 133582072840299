import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    GeneralConnectionListChangedNotif,
    GeneralConnectionListReq,
    GeneralConnectionListRes,
    GeneralDesktopStartUpdateReq,
    GeneralDesktopStartUpdateRes,
    GeneralDesktopUpdateAvailableNotif,
    GeneralLogReq,
    GeneralLogRes,
    GeneralTranslateReq,
    GeneralTranslateRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { connectionList } from "./connectionList";
import { connectionListChanged } from "./connectionListChanged";
import { desktopStartUpdate } from "./desktopStartUpdate";
import { desktopUpdateAvailable } from "./desktopUpdateAvailable";
import { log } from "./log";
import { translate } from "./translate";

interface IAOSClientServiceWrappedGeneral {
    connectionList: AOSClientServiceRequestWrapped<GeneralConnectionListReq, GeneralConnectionListRes>;
    desktopStartUpdate: AOSClientServiceRequestWrapped<GeneralDesktopStartUpdateReq, GeneralDesktopStartUpdateRes>;
    onConnectionListChanged: AOSClientServiceNotificationWrapped<GeneralConnectionListChangedNotif>;
    onDesktopUpdateAvailable: AOSClientServiceNotificationWrapped<GeneralDesktopUpdateAvailableNotif>;
    log: AOSClientServiceRequestWrapped<GeneralLogReq, GeneralLogRes>;
    translate: AOSClientServiceRequestWrapped<GeneralTranslateReq, GeneralTranslateRes>;
}

type AOSClientServiceWrappedGeneral = IAOSClientServiceWrappedGeneral;

// ADD_API_CALL General
export function generateGeneralServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedGeneral {
    return {
        connectionList: AOSClientServiceWrapperUI.request(connectionList(router)),
        desktopStartUpdate: AOSClientServiceWrapperUI.request(desktopStartUpdate(router)),
        onConnectionListChanged: AOSClientServiceWrapperUI.notification(connectionListChanged(router, notifier)),
        onDesktopUpdateAvailable: AOSClientServiceWrapperUI.notification(desktopUpdateAvailable(router, notifier)),
        log: AOSClientServiceWrapperUI.request(log(router)),
        translate: AOSClientServiceWrapperUI.request(translate(router)),
    };
}

generateGeneralServiceClient.serviceId = 1;
