import { KortexDateTimePicker, KortexTextField } from "@aos/react-components";
import { IJobUiModel, ITokenDecoded, IUserDbModel, JOB_QTY_MAX, JobStatusEnum, getTimestampFromDateStr } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { jobJobProcessUpdate, jobUpdate, jobUpdateStatus } from "@kortex/aos-ui/redux/scheduler-manager/scheduler-thunks-job";
import { deepClone } from "@kortex/utilities";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "moment/locale/fr";
import * as React from "react";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import { useTranslate } from "../../../../hooks/useTranslate";
import { userCanInsert } from "../../../../utilitites/IUserRights";
import KortexPanelCard from "../../../core/KortexPanelCard";
import { computeJobProgressCompletionPercentage, getJobStatusColor, getJobStatusLabelKey } from "../SchedulerData";
import { useSchedulerContext } from "../schedulerContext";

const useStyles = makeStyles({
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    headerProgressBar: {
        marginTop: "0px",
        alignItems: "center",
        width: "60px",
        height: "100%",
        paddingRight: "15px",
    },
    headerContainerContent: {
        width: "100%",
    },
    headerContainerContentTypo: {
        margin: "8px 0px 8px 6px",
    },
    headerContainerContentText: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexFlow: "wrap",
        alignItems: "center",
        paddingBottom: "20px",
    },
    itemContainerContentText: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexFlow: "wrap",
        alignItems: "flex-end",
        paddingBottom: "20px",
    },
    textLarge: {
        margin: "5px",
        width: "170px",
    },
    datePicker: {
        margin: "5px",
        width: "170px",
    },
    datePickerLabel: {
        display: "inline-block",
        marginTop: "0px",
    },
    datePickerInput: {
        padding: "5px",
        fontSize: "1rem",
    },
});

interface IOwnProps {
    plannerList: IUserDbModel[];
    userInfo?: ITokenDecoded;
}

export default function SchedulerJobHeaderCard(props: IOwnProps): JSX.Element {
    const { plannerList, userInfo } = props;

    const dispatch = useThunkDispatch();
    const classes = useStyles();
    const translate = useTranslate();
    const { selectedJob: job } = useSchedulerContext();

    const [textFieldReadOnly, setTextFieldReadOnly] = useState<boolean>(false);
    const [jobQty, setJobQty] = useState<number>(0);

    /**
     * Update state of text field based on jobProcess status and user access
     */
    useEffect((): void => {
        if (job) {
            setTextFieldReadOnly(job.status !== JobStatusEnum.DRAFT || !userCanInsert(userInfo?.roleRights.scheduler));
            setJobQty(job.qty);
        }
    }, [job]);

    const handleJobStatusChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleJobChange("status")(event.target.value);
    };

    /**
     * Handle called when the card header item is updated
     */
    const handleJobChange =
        (key: keyof IJobUiModel): ((value: string) => void) =>
        (value: string | number | JobStatusEnum): void => {
            if (job) {
                const newJob = deepClone(job);
                // @ts-expect-error - should work fine
                newJob[key] = value;

                if (key === "status") {
                    dispatch(jobUpdateStatus({ jobId: newJob.jobId, status: value as JobStatusEnum }));
                } else if (key === "qty") {
                    dispatch(
                        jobJobProcessUpdate({
                            job: newJob,
                            jobProcessList: newJob.processList.map((jobProcess) => {
                                return { ...jobProcess, qty: value as number };
                            }),
                        })
                    );
                    dispatch(jobUpdate([newJob]));
                } else {
                    dispatch(jobUpdate([newJob]));
                }
            }
        };

    /**
     * Handle called when the card header item is updated
     *
     * @param {keyof IJobUiModel} key - key of that job that changed
     */
    const handleJobDateChange =
        (key: keyof IJobUiModel): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            if (job) {
                const newJob = { ...job };

                if (key === "plannedOn" || key === "requestedOn" || key === "scheduledOn") {
                    newJob[key] = date ? getTimestampFromDateStr(String(date)) : 0;

                    dispatch(jobUpdate([newJob]));
                }
            }
        };

    /**
     * Return status ui element (status of the job) with drop down based on the current status
     */
    const getStatusUiElement = (): JSX.Element => {
        if (!job) {
            return <React.Fragment />;
        }

        /////////////////////////////////////////////
        // State Machine for the Job Status

        // When DRAFT, can go to READY only if processList is not empty
        if (job.status === JobStatusEnum.DRAFT) {
            return (
                <TextField
                    label={translate("scheduler.status")}
                    value={job.status}
                    className={classes.textLarge}
                    inputProps={{
                        readOnly: job.processList.length === 0 || !userCanInsert(userInfo?.roleRights.scheduler),
                    }}
                    select
                    onChange={handleJobStatusChange}
                    variant="outlined"
                >
                    <MenuItem key={JobStatusEnum.DRAFT} value={JobStatusEnum.DRAFT} id="jobStatusSelectDraftId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.DRAFT))}
                    </MenuItem>
                    <MenuItem key={JobStatusEnum.READY} value={JobStatusEnum.READY} id="jobStatusSelectReadyId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.READY))}
                    </MenuItem>
                </TextField>
            );
        }

        if (job.status !== JobStatusEnum.ARCHIVED) {
            return (
                <TextField
                    label={translate("scheduler.status")}
                    value={job.status}
                    className={classes.textLarge}
                    select
                    onChange={handleJobStatusChange}
                    variant="outlined"
                    inputProps={{
                        readOnly: !userCanInsert(userInfo?.roleRights.scheduler),
                    }}
                >
                    <MenuItem key={JobStatusEnum.DRAFT} value={JobStatusEnum.DRAFT} id="jobStatusSelectDraftId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.DRAFT))}
                    </MenuItem>
                    <MenuItem key={JobStatusEnum.READY} value={JobStatusEnum.READY} id="jobStatusSelectReadyId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.READY))}
                    </MenuItem>
                    <MenuItem key={JobStatusEnum.IN_PROGRESS} value={JobStatusEnum.IN_PROGRESS} id="jobStatusSelectInProgressId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.IN_PROGRESS))}
                    </MenuItem>
                    <MenuItem key={JobStatusEnum.ON_HOLD} value={JobStatusEnum.ON_HOLD} id="jobStatusSelectOnHoldId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.ON_HOLD))}
                    </MenuItem>
                    <MenuItem key={JobStatusEnum.DONE} value={JobStatusEnum.DONE} id="jobStatusSelectDoneId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.DONE))}
                    </MenuItem>
                    {job.status === JobStatusEnum.DONE ? (
                        <MenuItem key={JobStatusEnum.ARCHIVED} value={JobStatusEnum.ARCHIVED} id="jobStatusSelecArchivedId">
                            {translate(getJobStatusLabelKey(JobStatusEnum.ARCHIVED))}
                        </MenuItem>
                    ) : (
                        <span />
                    )}
                    <MenuItem key={JobStatusEnum.CANCELLED} value={JobStatusEnum.CANCELLED} id="jobStatusSelectCancelId">
                        {translate(getJobStatusLabelKey(JobStatusEnum.CANCELLED))}
                    </MenuItem>
                </TextField>
            );
        } else {
            return (
                <TextField
                    label={translate("scheduler.status")}
                    value={translate(getJobStatusLabelKey(job.status))}
                    className={classes.textLarge}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="outlined"
                />
            );
        }
    };

    const progress = job ? computeJobProgressCompletionPercentage(job) : 0;

    return (
        <React.Fragment>
            {job && (
                <KortexPanelCard isSelected={false} statusColor={getJobStatusColor(job.status)}>
                    <div className={classes.headerContainer} id="jobHeaderCardId">
                        <div className={classes.headerProgressBar}>
                            <CircularProgressbar
                                value={progress}
                                text={`${progress}%`}
                                styles={{ path: { stroke: getJobStatusColor(job.status) } }}
                            />
                        </div>
                        <div className={classes.headerContainerContent}>
                            <div className={classes.headerContainerContentText}>
                                <Typography className={classes.headerContainerContentTypo} variant="h6">
                                    {job.reworkId ? translate("scheduler.reworkSettings") : translate("scheduler.jobSettings")}
                                </Typography>
                                {getStatusUiElement()}
                            </div>
                            <div className={classes.itemContainerContentText}>
                                <KortexTextField
                                    label={translate("scheduler.jobRefId")}
                                    value={job.jobRefId}
                                    className={classes.textLarge}
                                    variant="standard"
                                    onChanged={handleJobChange("jobRefId")}
                                    InputProps={{
                                        readOnly: textFieldReadOnly,
                                    }}
                                />
                                <KortexTextField
                                    label={translate("scheduler.referenceId")}
                                    value={job.referenceId}
                                    className={classes.textLarge}
                                    variant="standard"
                                    onChanged={handleJobChange("referenceId")}
                                    InputProps={{
                                        readOnly: textFieldReadOnly,
                                    }}
                                />
                                <KortexTextField
                                    label={translate("scheduler.partNumber")}
                                    value={job.partNumber}
                                    className={classes.textLarge}
                                    variant="standard"
                                    onChanged={handleJobChange("partNumber")}
                                    InputProps={{
                                        readOnly: textFieldReadOnly,
                                    }}
                                />
                                <KortexDateTimePicker
                                    value={job.requestedOn}
                                    onChange={handleJobDateChange("requestedOn")}
                                    label={translate("scheduler.requestedOn")}
                                    DateTimePickerProps={{
                                        className: classes.textLarge,
                                        readOnly: textFieldReadOnly,
                                        clearLabel: translate("general.clear"),
                                        cancelLabel: translate("general.cancel"),
                                        okLabel: translate("general.select"),
                                    }}
                                />
                                <KortexDateTimePicker
                                    value={job.plannedOn}
                                    onChange={handleJobDateChange("plannedOn")}
                                    label={translate("scheduler.plannedOn")}
                                    DateTimePickerProps={{
                                        className: classes.textLarge,
                                        readOnly: textFieldReadOnly,
                                        clearLabel: translate("general.clear"),
                                        cancelLabel: translate("general.cancel"),
                                        okLabel: translate("general.select"),
                                    }}
                                />
                                <KortexDateTimePicker
                                    value={job.scheduledOn}
                                    onChange={handleJobDateChange("scheduledOn")}
                                    label={translate("scheduler.scheduledOn")}
                                    DateTimePickerProps={{
                                        className: classes.textLarge,
                                        readOnly: textFieldReadOnly,
                                        clearLabel: translate("general.clear"),
                                        cancelLabel: translate("general.cancel"),
                                        okLabel: translate("general.select"),
                                    }}
                                />
                                <KortexTextField
                                    label={translate("scheduler.quantity")}
                                    value={jobQty}
                                    className={classes.textLarge}
                                    variant="standard"
                                    type="number"
                                    onChanged={handleJobChange("qty")}
                                    max={JOB_QTY_MAX}
                                    InputProps={{
                                        readOnly: textFieldReadOnly,
                                    }}
                                />
                                <KortexTextField
                                    label={translate("scheduler.customer")}
                                    value={job.customer}
                                    className={classes.textLarge}
                                    variant="standard"
                                    onChanged={handleJobChange("customer")}
                                    InputProps={{
                                        readOnly: textFieldReadOnly,
                                    }}
                                />
                                <KortexTextField
                                    label={translate("scheduler.planner")}
                                    value={job.plannerId ? job.plannerId : "0"}
                                    className={classes.textLarge}
                                    variant="standard"
                                    TextFieldProps={{
                                        select: true,
                                    }}
                                    onChanged={handleJobChange("plannerId")}
                                    changedDelayMS={0}
                                    InputProps={{
                                        readOnly: textFieldReadOnly,
                                    }}
                                >
                                    {[{ userId: 0, userName: translate("scheduler.NoPlanner") }, ...plannerList].map(
                                        (planner, plannerIndex): JSX.Element => (
                                            <MenuItem key={plannerIndex} value={planner.userId}>
                                                {planner.userName}
                                            </MenuItem>
                                        )
                                    )}
                                </KortexTextField>
                            </div>
                        </div>
                    </div>
                </KortexPanelCard>
            )}
        </React.Fragment>
    );
}
