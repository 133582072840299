import { primaryPalette, theme } from "@aos/react-components";
import { getDateTimeStr, ProcessUiModel } from "@kortex/aos-common";
import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import { IPage, PrintBasePage } from "./PrintBasePage";
import PrintDraftWatermark from "./PrintDraftWaterMark";

const useStyles = makeStyles({
    mainContainerStyle: {
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        height: "795px",
        justifyItems: "center",
        paddingTop: "300px",
        width: "1125px",
    },
    tytleStyle: {
        color: primaryPalette[500],
        display: "inline-block",
        fontSize: "2.5rem",
        maxWidth: "800px",
        textAlign: "center",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    versionStyle: {
        color: primaryPalette[500],
        display: "inline-block",
        fontSize: "1.25rem",
        maxWidth: "800px",
        textAlign: "center",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

interface IFrontPageProps extends IPage {
    process: ProcessUiModel;
    title: string;
}

export default function PrintFrontPage(props: IFrontPageProps): JSX.Element {
    const { process, title, ...pageInfo } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <PrintBasePage {...pageInfo}>
            <g>
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <div className={classes.mainContainerStyle}>
                            <span className={classes.tytleStyle}>{title}</span>
                            <div />
                            <Typography className={classes.versionStyle}>
                                {translate("process.printing.version")}
                                {": "}
                                {process.isDraft ? translate("process.printing.draft") : process.version}
                            </Typography>
                            <div />
                            <Typography className={classes.versionStyle}>
                                {translate("process.printing.printDate")}
                                {`: ${getDateTimeStr()}`}
                            </Typography>
                        </div>
                        <PrintDraftWatermark isDraft={process.isDraft} />
                    </div>
                </foreignObject>
            </g>
        </PrintBasePage>
    );
}
