import { IProcessDbModel } from "@kortex/aos-common";
import { Box, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import { IPage, PrintBasePage } from "./PrintBasePage";
import PrintDraftWatermark from "./PrintDraftWaterMark";

const useStyles = makeStyles({
    itemStyle: {
        color: "dimgray",
        fontSize: "1.125rem",
        marginLeft: "100px",
        padding: "8px",
    },
});

interface IPrintTableOfContentProps extends IPage {
    howMany: number;
    pagesTitle: string[];
    process: IProcessDbModel;
    startIndex: number;
}

export default function PrintTableOfContent(props: IPrintTableOfContentProps): JSX.Element {
    const { howMany, pagesTitle, process, startIndex, ...pageInfo } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <PrintBasePage {...pageInfo}>
            <g>
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <Box marginTop={2}>
                            <Typography variant="h1" color="primary" align="center" display="block">
                                {translate("process.printing.tableOfContent")}
                            </Typography>
                        </Box>
                        {pagesTitle
                            .filter((_, index): boolean => index >= startIndex && index < startIndex + howMany)
                            .map((pageItem, index): JSX.Element => {
                                return (
                                    <div key={index} className={classes.itemStyle}>
                                        <span>{(startIndex + index + 1).toString() + ".\t" + pageItem}</span>
                                    </div>
                                );
                            })}
                        <PrintDraftWatermark isDraft={process.isDraft} />
                    </div>
                </foreignObject>
            </g>
        </PrintBasePage>
    );
}
