import { isBomItemTraceable } from "./isBomItemTraceable";
/**
 * Returns false if at least 1 item has incomplete traceability/serial number for the specified process action step.
 * Otherwise returns true.
 */
export function isStepTraceabilityComplete(processActionStepId, woBomItems) {
    return !Object.values(woBomItems).some((item) => {
        const followUps = item.followUp[processActionStepId] ?? [];
        let total = item.quantity;
        // Skip if item is not traceable
        if (!isBomItemTraceable(item))
            return false;
        for (const followUp of followUps) {
            // Check if all serial numbers are entered
            if (item.serialized) {
                // Check if it requires 1 serial number
                if (item.singleSerialNumber) {
                    if (followUp.serializedItems.length !== 1)
                        return true;
                }
                // Instead check if it requires a quantity of serial number equal to the follow-up quantity
                else if (followUp.serializedItems.length !== followUp.quantity)
                    return true;
                // Check if serial numbers are blank
                if (followUp.serializedItems.some((serializedItem) => !serializedItem.serialNumber.trim()))
                    return true;
            }
            // Check if a follow-up has no traceability
            if (!followUp.traceability.trim())
                return true;
            if (!followUp.isOverconsumption)
                total -= followUp.quantity;
        }
        // Check if traceability is missing
        return total > 0;
    });
}
