export * from './colorManipulator';
export { default as createMuiTheme } from './createMuiTheme'; // eslint-disable-next-line camelcase

export { default as unstable_createMuiStrictModeTheme } from './createMuiStrictModeTheme';
export { default as createStyles } from './createStyles';
export { default as makeStyles } from './makeStyles';
export { default as responsiveFontSizes } from './responsiveFontSizes';
export { default as styled } from './styled';
export * from './transitions';
export { default as useTheme } from './useTheme';
export { default as withStyles } from './withStyles';
export { default as withTheme } from './withTheme';
export { createGenerateClassName, jssPreset, ServerStyleSheets, StylesProvider, ThemeProvider as MuiThemeProvider, ThemeProvider } from '@material-ui/styles';