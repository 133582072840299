import { theme } from "@aos/react-components";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React, { createContext, Dispatch, FC, PropsWithChildren, useContext, useState } from "react";

interface IAppLayoutContext {
    loading: boolean;
    setLoading: Dispatch<React.SetStateAction<boolean>>;
}

const AppLayoutContext = createContext<IAppLayoutContext>({
    loading: false,
    setLoading: () => void 0,
});

type AppLayoutProviderProps = PropsWithChildren<{}>;

const useStyles = makeStyles({
    container: {
        position: "relative",
        minheight: "100vh",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: 2000,
    },
});

export const AppLayoutProvider: FC<AppLayoutProviderProps> = (props) => {
    const classes = useStyles();
    const { children } = props;
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <AppLayoutContext.Provider
            value={{
                loading,
                setLoading,
            }}
        >
            <div className={classes.container}>
                {children}
                {loading && (
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress />
                    </Backdrop>
                )}
            </div>
        </AppLayoutContext.Provider>
    );
};

export const useAppLayoutContext = (): IAppLayoutContext => useContext<IAppLayoutContext>(AppLayoutContext);
