import EventEmitter from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    BomFollowUpAssertTraceabilityNotExpiredReq,
    BomFollowUpAssertTraceabilityNotExpiredRes,
    BomFollowUpEditItemReq,
    BomFollowUpEditItemRes,
    BomFollowUpEditSerializedItemReq,
    BomFollowUpEditSerializedItemRes,
    BomFollowUpInsertReq,
    BomFollowUpInsertRes,
    BomFollowUpInsertSerializedItemReq,
    BomFollowUpInsertSerializedItemRes,
    BomFollowUpInsertedNotif,
    BomFollowUpMultipleTraceabilitiesItemReq,
    BomFollowUpMultipleTraceabilitiesItemRes,
    BomFollowUpOverconsumeItemReq,
    BomFollowUpOverconsumeItemRes,
    BomFollowUpOverconsumeNonTraceableItemReq,
    BomFollowUpOverconsumeNonTraceableItemRes,
    BomFollowUpRemoveItemReq,
    BomFollowUpRemoveItemRes,
    BomFollowUpRemoveSerializedItemReq,
    BomFollowUpRemoveSerializedItemRes,
    BomFollowUpReplaceItemReq,
    BomFollowUpReplaceItemRes,
    BomFollowUpReplaceSerializedItemReq,
    BomFollowUpReplaceSerializedItemRes,
    BomFollowUpUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { bomFollowUpAssertTraceabilityItemNotExpired } from "./assertTraceabilityItemNotExpired";
import { bomFollowUpEditItem } from "./editItem";
import { bomFollowUpEditSerializedItem } from "./editSerializedItem";
import { bomFollowUpInsert } from "./insert";
import { bomFollowUpInserted } from "./inserted";
import { bomFollowUpInsertSerializedItem } from "./insertSerializedItem";
import { bomFollowUpMultipleTraceabilitiesItem } from "./multipleTraceabilitiesItem";
import { bomFollowUpOverconsumeItem } from "./overconsumeItem";
import { bomFollowUpOverconsumeNonTraceableItem } from "./overconsumeNonTraceableItem";
import { bomFollowUpRemoveItem } from "./removeItem";
import { bomFollowUpRemoveSerializedItem } from "./removeSerializedItem";
import { bomFollowUpReplaceItem } from "./replaceItem";
import { bomFollowUpReplaceSerializedItem } from "./replaceSerializedItem";
import { bomFollowUpUpdated } from "./updated";

interface IAOSClientServiceWrappedBomFollowUp {
    bomFollowUpAssertTraceabilityItemNotExpired: AOSClientServiceRequestWrapped<
        BomFollowUpAssertTraceabilityNotExpiredReq,
        BomFollowUpAssertTraceabilityNotExpiredRes
    >;
    editItem: AOSClientServiceRequestWrapped<BomFollowUpEditItemReq, BomFollowUpEditItemRes>;
    editSerializedItem: AOSClientServiceRequestWrapped<BomFollowUpEditSerializedItemReq, BomFollowUpEditSerializedItemRes>;
    insert: AOSClientServiceRequestWrapped<BomFollowUpInsertReq, BomFollowUpInsertRes>;
    insertSerializedItem: AOSClientServiceRequestWrapped<BomFollowUpInsertSerializedItemReq, BomFollowUpInsertSerializedItemRes>;
    multipleTraceabilitiesItem: AOSClientServiceRequestWrapped<
        BomFollowUpMultipleTraceabilitiesItemReq,
        BomFollowUpMultipleTraceabilitiesItemRes
    >;
    onInserted: AOSClientServiceNotificationWrapped<BomFollowUpInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<BomFollowUpUpdatedNotif>;
    overconsumeItem: AOSClientServiceRequestWrapped<BomFollowUpOverconsumeItemReq, BomFollowUpOverconsumeItemRes>;
    overconsumeNonTraceableItem: AOSClientServiceRequestWrapped<
        BomFollowUpOverconsumeNonTraceableItemReq,
        BomFollowUpOverconsumeNonTraceableItemRes
    >;
    removeItem: AOSClientServiceRequestWrapped<BomFollowUpRemoveItemReq, BomFollowUpRemoveItemRes>;
    replaceItem: AOSClientServiceRequestWrapped<BomFollowUpReplaceItemReq, BomFollowUpReplaceItemRes>;
    removeSerializedItem: AOSClientServiceRequestWrapped<BomFollowUpRemoveSerializedItemReq, BomFollowUpRemoveSerializedItemRes>;
    replaceSerializedItem: AOSClientServiceRequestWrapped<BomFollowUpReplaceSerializedItemReq, BomFollowUpReplaceSerializedItemRes>;
}

type AOSClientServiceWrappedBomFollowUp = IAOSClientServiceWrappedBomFollowUp;

// ADD_API_CALL BOM Follow-Up
export function generateBomFollowUpServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedBomFollowUp {
    return {
        bomFollowUpAssertTraceabilityItemNotExpired: AOSClientServiceWrapperUI.request(bomFollowUpAssertTraceabilityItemNotExpired(router)),
        editItem: AOSClientServiceWrapperUI.request(bomFollowUpEditItem(router)),
        editSerializedItem: AOSClientServiceWrapperUI.request(bomFollowUpEditSerializedItem(router)),
        insert: AOSClientServiceWrapperUI.request(bomFollowUpInsert(router)),
        insertSerializedItem: AOSClientServiceWrapperUI.request(bomFollowUpInsertSerializedItem(router)),
        multipleTraceabilitiesItem: AOSClientServiceWrapperUI.request(bomFollowUpMultipleTraceabilitiesItem(router)),
        onInserted: AOSClientServiceWrapperUI.notification(bomFollowUpInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(bomFollowUpUpdated(router, notifier)),
        overconsumeItem: AOSClientServiceWrapperUI.request(bomFollowUpOverconsumeItem(router)),
        overconsumeNonTraceableItem: AOSClientServiceWrapperUI.request(bomFollowUpOverconsumeNonTraceableItem(router)),
        replaceItem: AOSClientServiceWrapperUI.request(bomFollowUpReplaceItem(router)),
        removeItem: AOSClientServiceWrapperUI.request(bomFollowUpRemoveItem(router)),
        removeSerializedItem: AOSClientServiceWrapperUI.request(bomFollowUpRemoveSerializedItem(router)),
        replaceSerializedItem: AOSClientServiceWrapperUI.request(bomFollowUpReplaceSerializedItem(router)),
    };
}

generateBomFollowUpServiceClient.serviceId = 37;
