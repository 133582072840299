// aos common
import { KortexDialogConfirmation, theme, useKeyPressed } from "@aos/react-components";
import {
    AccessLevelEnum,
    ApprovalStatusEnum,
    IOrgSettingDbModel,
    IProcessActionDbModel,
    IProcessUiModel,
    IProcessVariable,
    OrUndefined,
    ProcessAction,
    ProcessActionId,
    ProcessActionRoutingProcess,
    ProcessActionStepRoutingProcess,
    ProcessActionType,
    ProcessApprovalRightsEnum,
    ProcessEditorRightsEnum,
    ProcessId,
    ProcessType,
    ProcessUiModel,
    ProcessValidation,
    getActionsBoundingBox,
    upsertObjectFromArray,
    validateProcess,
} from "@kortex/aos-common";
import { useSnackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useKeybindCopyPaste } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { deepClone } from "@kortex/utilities";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    Divider,
    Menu,
    MenuItem,
    Paper,
    Slide,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { SlideProps } from "@material-ui/core/Slide/Slide";
import Debug from "debug";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PlayerDialog from "../../../../components/core/ProcessPlayer/PlayerDialog";
import { EnumClipboardDataType } from "../../../../configs/EnumClipboardDataType";
import { useForeground } from "../../../../hooks/useForeground";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesSettingOrganizations, useEntitiesTreeProcess } from "../../../../redux/effects";
import { setEditedProcessIdAction } from "../../../../redux/process-manager/process-actions";
import {
    processActionDelete,
    processActionInsert,
    processActionInsertCopy,
    processActionUpdate,
    processGet,
    processSetEditedProcessId,
    processVersionGetDraft,
} from "../../../../redux/process-manager/process-thunks-process";
import {
    useSelectorEditedProcessId,
    useSelectorEditedTreeProcessNodeId,
    useSelectorProcesses,
    useSelectorUserSession,
} from "../../../../redux/selectors";
import { treeSetEditedTreeProcessNodeId } from "../../../../redux/tree-manager/tree-thunks-process";
import { normalizeSVGDimension } from "../../../../utilitites/normalizeSVGDimension";
import { isProcessValid } from "../../../../utilitites/process";
import { RepoBreadCrumbs } from "../RepositoryManager/RepoBreadCrumbs";

import ActionBlocksAndLinks from "./ActionBlocksAndLinks/ActionBlocksAndLinks";
import ActionEditor from "./ActionEditors/ActionEditor";
import ActionSelector from "./ActionSelector/ActionSelector";
import ProcessEditorNavMenu from "./ProcessEditorNavMenu";
import ProcessEditorSideMenu from "./ProcessEditorSideMenu";
import ProcessValidationDrawer from "./ProcessValidation/ProcessValidationDrawer";
import ProcessVariableCopyDialog from "./ProcessVariableCopyDialog";
import { useProcessEditorContext } from "./context";
import { ProcessTrainingCommuniqueDrawer } from "./processTrainingCommuniqueDrawer";

const debug = Debug("kortex:ui:process-editor");

const useStyles = makeStyles({
    root: {
        position: "relative",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
    },
    selectRunnerButton: {
        position: "absolute",
        bottom: "60px",
        left: "45%",
    },
    addButton: {
        position: "absolute",
        bottom: "0px",
        right: "20px",
    },
    playButton: {
        position: "absolute",
        bottom: "60px",
        left: "55%",
    },
    processSVG: {
        backgroundColor: theme.palette.common.white,
    },
    processSVGContainer: {
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
    },
    dialogContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: 1102,
    },
    selectButton: {
        minWidth: "200px",
        height: "60px",
        marginTop: "380px",
        justifySelf: "center",
    },
});

const Transition = React.forwardRef((props: SlideProps, ref): JSX.Element => <Slide {...props} direction="up" ref={ref} />);
Transition.displayName = "SlideUp";

function ProcessEditor(): JSX.Element {
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const urlParams = useParams();
    const snackbar = useSnackbar();
    const translate = useTranslate();

    const { bom, updateBom } = useProcessEditorContext();

    const userInfo = useSelectorUserSession();
    const svgContainer = useRef<HTMLDivElement>(null);
    const svgArea = useRef<SVGSVGElement>(null);

    const editedProcessId = useSelectorEditedProcessId();
    const editedTreeNodeId = useSelectorEditedTreeProcessNodeId();
    // FIXME: this is temporary; as we will have more than a setting
    //        and it will not be hardcoded.
    const orgInfo: IOrgSettingDbModel | undefined = useEntitiesSettingOrganizations()[0];
    const processes = useSelectorProcesses();
    const treeNodes = useEntitiesTreeProcess();

    const [editedProcessActionId, setEditedProcessActionId] = useState<IProcessActionDbModel["processActionId"]>(0);
    const [preselectedProcessActionStepIndex, setPreselectedProcessActionStepIndex] = useState<number>(0);
    const [selectedProcessActionIds, setSelectedProcessActionIds] = useState<IProcessActionDbModel["processActionId"][]>([]);

    const editedProcess = processes.find((process) => process.processId === editedProcessId);
    const editedProcessAction = editedProcess?.actions.find((action) => action.processActionId === editedProcessActionId);
    const editedTreeNode = treeNodes.find((node) => node.treeNodeId === editedTreeNodeId);

    const [showActionEditor, setShowActionEditor] = useState(false);
    const [viewBoxInfo, setViewBoxInfo] = useState("0 0 0 0");
    const [autoScrolled, setAutoScrolled] = useState(false);
    const [repoSelectorOpen, setRepoSelectorOpen] = useState<boolean>(false);

    const [actionSelectorOpen, setActionSelectorOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);

    const [svgRatio, setSvgRatio] = useState(1);
    const [isDragMode, setIsDragMode] = useState(false);
    const [canvasScale, setCanvasScale] = useState(1.0);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    const [variableDuplicatesWithDifferentProps, setVariableDuplicatesWithDifferentProps] = useState<IProcessVariable[]>([]);
    const [playerDialogOpened, setPlayerDialogOpened] = useState<boolean>(false);

    const [playerDryRunMode, setPlayerDryRunMode] = useState<boolean>(false);
    const [playerTestRunMode, setPlayerTestRunMode] = useState<boolean>(false);

    const [processValidationDrawerOpened, setProcessValidationDrawerOpened] = useState(false);
    const [trainingDrawerOpened, setTrainingDrawerOpened] = useState(false);

    const isForeground = useForeground();

    const [isDraftDifferentThanLatestReleasedVersion, setIsDraftDifferentThanLatestReleasedVersion] = useState<boolean>(false);
    const [isDraftDifferentThanLatestInApprovalVersion, setisDraftDifferentThanLatestInApprovalVersion] = useState<boolean>(false);
    const [loadingActionPaste, setLoadingActionPaste] = useState<boolean>(false);

    // deleteConfirmationDialog's foreground must be handled here since it's not in its own component
    useForeground(deleteConfirmationOpen);

    // Key events
    const ctrlPressed = useKeyPressed("Control", !isForeground);
    const shiftPressed = useKeyPressed("Shift", !isForeground);

    const [loadingProcessVersions, setLoadingProcessVersions] = useState(true);

    const circularProgressActive = Boolean((editedTreeNode && loadingProcessVersions) || loadingActionPaste);
    const editedActionAvail = Boolean(editedProcessAction?.processActionId);
    const isDeleteDisabled =
        editedActionAvail && (editedProcessAction?.type === "core-input" || editedProcessAction?.type === "core-output");
    const svgId =
        editedProcess?.type === ProcessType.PROCESS
            ? "processEditorId"
            : editedProcess?.type === ProcessType.ROUTING
            ? "routingEditorId"
            : "";

    const [processValidation, setProcessValidation] = useState<OrUndefined<Readonly<ProcessValidation>>>(undefined);

    const [inRouting, setInRouting] = useState<boolean>(false);
    const [routingNodeId, setRoutingNodeId] = useState<number | undefined>(0);
    const [routingProcessId, setRoutingProcessId] = useState<number | undefined>(0);

    /**
     * Check if process editor can only be read
     */
    const isReadOnly = (): boolean => {
        if (!editedProcess || !userInfo) {
            // Process and user info not available
            return true;
        }

        if (
            userInfo.roleRights.processEditor <= AccessLevelEnum.READ || // User does not have rights
            !editedProcess.isDraft || // Process is not draft
            editedProcess.archived // Process is archived
        ) {
            return true;
        }

        if (
            ((orgInfo && editedProcess.type === ProcessType.PROCESS && orgInfo.lockPendingProcess) || // Cannot edit a process in pending approval state
                (orgInfo && editedProcess.type === ProcessType.ROUTING && orgInfo.lockPendingRouting)) && // Cannot edit a routing in pending approval state
            processes.some(
                (process) =>
                    process.treeNodeId === editedProcess.treeNodeId &&
                    process.versionStatus === ApprovalStatusEnum.AWAITING &&
                    !process.archived
            )
        ) {
            // Process has at least one version waiting for approval
            return true;
        }

        return false;
    };

    const readOnly = isReadOnly();
    const processEditorUserAccessLevel =
        userInfo && userInfo.roleRights.processEditor > ProcessEditorRightsEnum.READ && readOnly
            ? ProcessEditorRightsEnum.READ
            : userInfo && userInfo.roleRights.processEditor;

    const processEditorSideMenuUserAccessLevel = userInfo ? userInfo.roleRights.processEditor : ProcessEditorRightsEnum.READ;
    const processApprovalSideMenuUserAccessLevel = userInfo ? userInfo.roleRights.processApproval : ProcessApprovalRightsEnum.AUTHENTICATED;

    /**
     * Saves edited process ID in store if a node is chosen but the edited process ID is not already set
     */
    useEffect((): void => {
        if (editedTreeNodeId && !editedProcessId) {
            dispatch(processSetEditedProcessId(processes.find((process) => process.treeNodeId === editedTreeNodeId)?.processId));
        }

        verifyIfDraftIsDifferentThanLatestReleasedVersion();
    }, [processes]);

    /**
     * Called when component is mounted
     */
    useEffect((): void => {
        updateViewPort(canvasScale, translateX, translateY);
        loadProcessIfNew();
    }, []);

    /**
     * Called when edited process change to center process into screen
     */
    useEffect((): void => {
        if (editedProcess && editedProcess.processId) {
            centerWorkspace();
        }
    }, [editedProcess && editedProcess.processId]);

    // When svgArea is found, calculate the pixel ratio
    useEffect((): void => {
        if (!svgArea.current || !svgArea.current.getScreenCTM) {
            return;
        }
        setSvgRatio((svgArea.current.getScreenCTM() as DOMMatrix).a);
    }, [svgArea.current, canvasScale]);

    useEffect((): void => {
        if (editedProcess) {
            dispatch(processGet(editedProcess.processId));
        }

        if (svgContainer && svgContainer.current && !autoScrolled) {
            svgContainer.current.scrollLeft = 600;
            svgContainer.current.scrollTop = 200;
            setAutoScrolled(true);
        }
    }, [editedProcess, svgContainer, autoScrolled]);

    /**
     * Update the BOM when the process is changed
     */
    useEffect((): void => {
        // Get Bom of the process
        if (editedProcess) {
            if (!bom) {
                if (editedProcess.bomId && editedProcess.bomRev) {
                    updateBom(editedProcess.bomId, editedProcess.bomRev, true);
                }
            } else if (!editedProcess.bomId || !editedProcess.bomRev) {
                // Process does not have a BOM
                updateBom();
            } else if (editedProcess.bomId !== bom?.bomId || editedProcess.bomRev !== bom?.revision) {
                updateBom(editedProcess.bomId, editedProcess.bomRev, true);
            }
        } else {
            updateBom();
        }
    }, [editedProcessId]);

    /**
     * Copy and paste action blocks
     */
    useKeybindCopyPaste(
        // Copy
        (event: ClipboardEvent): void => {
            const copiedActionsIds = selectedProcessActionIds.filter((id) => {
                const action = editedProcess?.actions.find((action) => action.processActionId === id);

                if (!action) {
                    return false;
                }

                if (action.type === "core-fail" || action.type === "core-input" || action.type === "core-output") {
                    snackbar.warning(`${translate("processEditor.nonCopyableActions")}`);

                    return false;
                }

                return true;
            });

            if (!copiedActionsIds.length) {
                event.clipboardData?.clearData(EnumClipboardDataType.PROCESS_FUNCTIONS);
                return void 0;
            }

            event.preventDefault();
            event.stopPropagation();

            if (event.clipboardData && copiedActionsIds.length) {
                event.clipboardData.clearData();
                event.clipboardData.setData(EnumClipboardDataType.PROCESS_FUNCTIONS, JSON.stringify(copiedActionsIds));
            }
        },
        // Paste
        (event: ClipboardEvent): void => {
            if (!event.clipboardData) {
                return;
            }

            if (!editedProcess || event.clipboardData.types[0] !== EnumClipboardDataType.PROCESS_FUNCTIONS || !editedProcess.processId) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            // draft only
            if (!editedProcess.isDraft) {
                return;
            }

            setLoadingActionPaste(true);

            const actionIdsToInsert: ProcessActionId[] =
                JSON.parse(event.clipboardData.getData(EnumClipboardDataType.PROCESS_FUNCTIONS)) ?? [];

            event.clipboardData.clearData(EnumClipboardDataType.PROCESS_FUNCTIONS);

            if (actionIdsToInsert.length) {
                dispatch(
                    processActionInsertCopy({
                        processId: editedProcess.processId,
                        processActionIds: actionIdsToInsert,
                    })
                )
                    .then((payload) => {
                        setSelectedProcessActionIds(payload.insertedActions.map((processAction) => processAction.processActionId) ?? []);
                        setVariableDuplicatesWithDifferentProps(payload.duplicatedVariablesWithDifferentProperties);
                    })
                    .finally(() => {
                        setLoadingActionPaste(false);
                    });
            } else {
                snackbar.warning(`${translate("processEditor.copyError")}`);

                setLoadingActionPaste(false);
            }
        },
        {
            disabled: !isForeground,
        }
    );

    const updateViewPort = (scale: number, transX: number, transY: number): void => {
        setViewBoxInfo(
            `${-transX} ${-transY} ${(svgContainer.current ? svgContainer.current.clientWidth : 0) * scale} ${
                (svgContainer.current ? svgContainer.current.clientHeight : 0) * scale
            }`
        );
    };

    const loadProcessIfNew = async (): Promise<void> => {
        if (!location) {
            return;
        }

        const newTreeNodeId = urlParams.treeNodeId ? parseInt(urlParams.treeNodeId ?? "") : undefined;
        const newProcessId = urlParams.processId ? parseInt(urlParams.processId) : undefined;
        const newActionId = urlParams.processActionId ? parseInt(urlParams.processActionId) : undefined;

        if (newTreeNodeId && newTreeNodeId !== editedTreeNodeId) {
            dispatch(treeSetEditedTreeProcessNodeId(newTreeNodeId)).then(() => {
                loadProcess(newTreeNodeId, newProcessId);
            });
        }

        if (newActionId) {
            setShowActionEditor(true);
            setEditedProcessActionId(newActionId);
        }
    };

    /**
     * Load a process with a tree node ID or a process version
     *
     * @param {number} treeNodeId - new tree node ID
     * @param {number} [processId] - process version ID
     */
    const loadProcess = async (treeNodeId?: ProcessUiModel["treeNodeId"], processId?: ProcessUiModel["processId"]): Promise<void> => {
        if (processId) {
            dispatch(processGet(processId)).then(() => {
                dispatch(processSetEditedProcessId(processId));
            });
        } else if (treeNodeId) {
            dispatch(processVersionGetDraft({ treeNodeId })).then((process) => {
                dispatch(processSetEditedProcessId(process?.processId));
            });
        }
    };

    const centerWorkspace = (): void => {
        if (!editedProcess || !svgContainer.current) {
            return;
        }

        const boundingBox = getActionsBoundingBox(editedProcess.actions);
        const pageRatio = svgContainer.current.clientWidth / svgContainer.current.clientHeight;
        let newScale = canvasScale;
        let newTransX = translateX;
        let newTransY = translateY;

        if (boundingBox.width / pageRatio > boundingBox.height) {
            newScale = (boundingBox.width / svgContainer.current.clientWidth) * 1.1;
        } else {
            newScale = (boundingBox.height / svgContainer.current.clientHeight) * 1.1;
        }

        newTransX = -boundingBox.x1 - boundingBox.width / 2 + (svgContainer.current.clientWidth * newScale) / 2;
        newTransY = -boundingBox.y1 - boundingBox.height / 2 + (svgContainer.current.clientHeight * newScale) / 2;

        setCanvasScale(newScale);
        setTranslateX(newTransX);
        setTranslateY(newTransY);
        updateViewPort(newScale, newTransX, newTransY);
    };

    const onActionEdit = (): void => {
        if (!editedProcessAction) {
            return;
        }

        navigate(editedProcessAction.processActionId.toString());
        setShowActionEditor(true);
    };

    const closeDialog = (): void => {
        navigate(`${getPageUrl(EnumPageTypes.PROCESS)}/${editedTreeNodeId}/${editedProcessId}`);
        setShowActionEditor(false);
        setEditedProcessActionId(-1);
        setEditedProcessIdAction(undefined);
        setPreselectedProcessActionStepIndex(0);
    };

    const handleInsertActionClick = (): void => {
        setActionSelectorOpen(true);
    };

    const handleOnInsertAction = (type: ProcessActionType): void => {
        if (!editedProcess) {
            return;
        }

        // If it's process, make sure to select the process first before inserting
        if (editedProcess.processId) {
            dispatch(processActionInsert({ processId: editedProcess.processId, type }));
        } else {
            debug("Can't insert action, processId is not defined");
        }
    };

    const handleClose = (): void => {
        setActionSelectorOpen(false);
        setActionMenuAnchorEl(undefined);
    };

    const handleOpenActionMenu = (index: ProcessUiModel["processId"], anchorEl: HTMLElement): void => {
        if (!editedProcess) {
            return;
        }

        setEditedProcessActionId(editedProcess.actions[index].processActionId ?? 0);
        setActionMenuAnchorEl(anchorEl);
    };

    const onDeleteAction = (): void => {
        if (!editedProcess || !editedProcessAction) {
            return;
        }
        dispatch(processActionDelete({ processActionId: editedProcessAction.processActionId, processId: editedProcessAction.processId }));
    };

    /**
     * handles open process from a routing process action
     */
    const handleOpenProcess = (): void => {
        if (!editedProcess || !editedProcessAction) {
            return;
        }
        if (editedProcess.type === ProcessType.ROUTING) {
            const actionIndex = editedProcess.actions
                .map((action): number | undefined => action.processActionId)
                .indexOf(editedProcessAction.processActionId);
            if (editedProcess.actions[actionIndex].type === "core-routing-process") {
                const routingProcessProps = editedProcess.actions[actionIndex].steps[0] as ProcessActionStepRoutingProcess;
                if (routingProcessProps.config.treeNodeId) {
                    setInRouting(true);
                    setRoutingNodeId(editedTreeNodeId);
                    setRoutingProcessId(editedProcessId);
                    handleSelectProcess(routingProcessProps.config.treeNodeId, routingProcessProps.config.processId ?? undefined);
                }
            }
        }
    };

    /**
     * handles back from routing, called from the breadcrumb
     */
    const handleBackFromRouting = (): void => {
        setInRouting(false);
        if (routingNodeId) {
            handleSelectProcess(routingNodeId, routingProcessId);
        }
    };

    const handleDisconnect = (): void => {
        if (!editedProcess || !editedProcessAction) {
            return;
        }

        const actionIndex = editedProcess.actions
            .map((action): number | undefined => action.processActionId)
            .indexOf(editedProcessAction.processActionId);

        if (actionIndex === -1) {
            debug("Can't find action index", editedProcessAction.processActionId);
            return;
        }

        // actions to update
        let groggyActions: ProcessAction[] = [];

        // Disconnect inputs
        for (const action of editedProcess.actions) {
            for (const output of action.outputs) {
                if (output.remoteIds.length > 0 && output.remoteIds[0].actionId === editedProcess.actions[actionIndex].processActionId) {
                    output.remoteIds = [];

                    // add action to update
                    groggyActions.push(action);
                }
            }
        }

        // Disconnect outputs
        for (const output of editedProcess.actions[actionIndex].outputs) {
            output.remoteIds = [];
        }

        {
            // add action to update
            groggyActions = upsertObjectFromArray(groggyActions, editedProcess.actions[actionIndex], function (action) {
                return action.processActionId === this.processActionId;
            });

            // push changes
            dispatch(processActionUpdate(groggyActions));
        }
    };

    const handleZoomIn = (): void => {
        const newScale = Math.max(canvasScale - 0.1, 0.5);
        setCanvasScale(newScale);
        updateViewPort(newScale, translateX, translateY);
    };

    const handleZoomOut = (): void => {
        const newScale = Math.min(canvasScale + 0.1, 3.0);
        setCanvasScale(newScale);
        updateViewPort(newScale, translateX, translateY);
    };

    const handleCenter = (): void => {
        centerWorkspace();
    };

    const handleActionDeleteConfirmationOpen = (): void => {
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmation =
        (willDelete: boolean): (() => void) =>
        (): void => {
            if (willDelete) {
                onDeleteAction();
            }
            setDeleteConfirmationOpen(false);
        };

    const handleWheelChange = (event: React.WheelEvent<SVGElement>): void => {
        if (isDragMode) {
            return;
        }

        event.stopPropagation();
        const newScale = Math.min(Math.max(canvasScale + event.deltaY / 300, 0.5), 5);

        const newCenterX = event.clientX * newScale;
        const oldCenterX = event.clientX * canvasScale;
        const newTransX = translateX - (oldCenterX - newCenterX);

        const newCenterY = (event.clientY - 64) * newScale;
        const oldCenterY = (event.clientY - 64) * canvasScale;
        const newTransY = translateY - (oldCenterY - newCenterY);

        setCanvasScale(newScale);
        setTranslateX(newTransX);
        setTranslateY(newTransY);

        updateViewPort(newScale, newTransX, newTransY);
    };

    const handleMouseMove = (event: React.MouseEvent<SVGElement>): void => {
        if (!isDragMode) {
            return;
        }

        setTranslateX((prevTranslateX) => {
            const updatedTranslateX = prevTranslateX + event.movementX * canvasScale;

            setTranslateY((prevTranslateY) => {
                const updatedTranslateY = prevTranslateY + event.movementY * canvasScale;

                updateViewPort(canvasScale, updatedTranslateX, updatedTranslateY);

                return updatedTranslateY;
            });

            return updatedTranslateX;
        });
    };

    const handleMouseDown = (): void => {
        setIsDragMode(true);
    };

    const handleMouseUp = (): void => {
        setIsDragMode(false);
    };

    /**
     * Checks whether a process is valid or not. If the process is not valid,
     * it sets the validation error and opens the validation drawer.
     *
     * @param {IProcessUiModel} editedProcess - the process to validate
     */
    const processIsValid = (editedProcess: IProcessUiModel): boolean => {
        return isProcessValid(editedProcess, (error) => {
            setProcessValidation(error);
            setProcessValidationDrawerOpened(true);
        });
    };

    /**
     * handle preview open
     */
    const handleDryRunClick = (): void => {
        if (!editedProcess) {
            return;
        }

        if (processIsValid(editedProcess)) {
            setPlayerDryRunMode(true);
            togglePlayerDialog(true)();
        }
    };

    /**
     * handle validate process
     */
    const handleValidateClick = (): void => {
        if (!editedProcess) {
            return;
        }

        setProcessValidation(validateProcess(editedProcess, bom?.items));
        setProcessValidationDrawerOpened(true);
    };

    /**
     * handle play open
     */
    const handlePlayOpen = (): void => {
        if (!editedProcess) {
            return;
        }

        if (processIsValid(editedProcess)) {
            setPlayerDryRunMode(false);
            setPlayerTestRunMode(false);
            togglePlayerDialog(true)();
        }
    };

    /**
     * handle test play open
     */
    const handleTestPlayOpen = (): void => {
        if (!editedProcess) {
            return;
        }

        if (processIsValid(editedProcess)) {
            setPlayerDryRunMode(false);
            setPlayerTestRunMode(true);
            togglePlayerDialog(true)();
        }
    };

    /**
     * close the runner dialog
     */
    const togglePlayerDialog =
        (opened: boolean): (() => void) =>
        (): void => {
            setPlayerDialogOpened(opened);
        };

    /**
     * handles the process selection
     */
    const handleSelectProcess = (selectedtreeNodeId: number, versionId?: number): void => {
        dispatch(treeSetEditedTreeProcessNodeId(selectedtreeNodeId)).then(() => {
            setRepoSelectorOpen(false);
            loadProcess(selectedtreeNodeId, versionId);
            setSelectedProcessActionIds([]);
            navigate(`${getPageUrl(EnumPageTypes.PROCESS)}/${selectedtreeNodeId}${versionId ? `/${versionId}` : ""}`);
        });
    };

    /**
     * handles the process cancel
     */
    const handleCancelSelectProcess = (): void => {
        setRepoSelectorOpen(false);
        setSelectedProcessActionIds([]);
    };

    /**
     * Builds the process title
     */
    const getProcessTitle = (dryRunMode: boolean): string => {
        if (!editedProcess) {
            return "";
        }

        let fullTitle = editedTreeNode?.label ?? "";

        if (dryRunMode) {
            fullTitle += ` (${translate("scheduler.dryRun")})`;
        }
        return fullTitle;
    };

    const handleOpenRepoSelector = (): void => {
        setRepoSelectorOpen(true);
    };

    /**
     * Changes edited process when selecting a new version
     *
     * @param {number} processId - process ID of selected version
     */
    const handleProcessVersionChange = (processId: ProcessId, _?: boolean, changedOnLoad?: boolean): void => {
        if (!changedOnLoad) {
            loadProcess(editedProcess?.treeNodeId, processId);
            navigate(`${getPageUrl(EnumPageTypes.PROCESS)}/${editedTreeNodeId}/${processId}`);
        }
    };

    /**
     * Update "Loading" inner state when process versions are loaded
     */
    const handleProcessVersionsLoaded = (): void => {
        verifyIfDraftIsDifferentThanLatestReleasedVersion();
        setLoadingProcessVersions(false);
    };

    /**
     * Edit action on double click
     *
     * @param {number} index - action index
     */
    const handleActionBlockDoubleClick = (index: number): void => {
        if (!editedProcess || !editedProcess.actions) {
            return;
        }

        navigate(editedProcess.actions[index].processActionId.toString());

        setShowActionEditor(true);
        setEditedProcessActionId(editedProcess.actions[index].processActionId);
        setSelectedProcessActionIds([]);
    };

    /**
     * Select the process actions
     *
     * @param {number} index - process action index
     */
    const handleActionBlockSelect = (index: number): void => {
        if (!editedProcess) {
            return;
        }

        let selectedProcessActionIdsCopy = [...selectedProcessActionIds];
        const selectedActionIndex = selectedProcessActionIdsCopy.findIndex((id) => id === editedProcess.actions[index].processActionId);

        if (ctrlPressed || shiftPressed) {
            if (selectedActionIndex !== -1) {
                // The action block is already selected
                selectedProcessActionIdsCopy.splice(selectedActionIndex, 1);
            } else {
                selectedProcessActionIdsCopy.push(editedProcess.actions[index].processActionId);
            }
        } else if (selectedProcessActionIdsCopy.every((id) => id !== editedProcess.actions[index].processActionId)) {
            // Single different action block selected
            selectedProcessActionIdsCopy = [editedProcess.actions[index].processActionId];
        }

        setSelectedProcessActionIds(selectedProcessActionIdsCopy);
    };

    /**
     * Deselect action block on click away
     */
    const handleActionBlockClickAway = (): void => {
        if (selectedProcessActionIds.length) {
            setSelectedProcessActionIds([]);
        }
    };

    /**
     * Clicking on an error will open the action editor to the corresponding action
     *
     * @param {ProcessActionId} [processActionId] - ID of the action that has an error
     */
    const handleProcessValidationErrorClick = (processActionId: ProcessActionId, stepIndex?: number): void => {
        if (stepIndex !== undefined) {
            // Open action editor and go to specified action step
            navigate(`${processActionId.toString()}${typeof stepIndex === "number" ? `/${stepIndex}` : ""}`);
            setShowActionEditor(true);
            setEditedProcessActionId(processActionId);
            setPreselectedProcessActionStepIndex(stepIndex);
            setSelectedProcessActionIds([]);
        } else {
            // Select specified action in the process editor
            setProcessValidationDrawerOpened(false);
            setSelectedProcessActionIds([processActionId]);
        }
    };

    const handleTrainingDrawerOpened =
        (open: boolean): (() => void) =>
        (): void => {
            if (!editedProcess) {
                return;
            }

            setTrainingDrawerOpened(open);
        };

    const handleProcessValidationDrawerOpened =
        (open: boolean): (() => void) =>
        (): void => {
            setProcessValidationDrawerOpened(open);
        };

    /**
     * Resets duplicate variable when closing the dialog
     */
    const handleCloseVariableCopyDialog = (): void => {
        setVariableDuplicatesWithDifferentProps([]);
    };

    /**
     * Verify if draft is more recent than either the latest release version or the latest "in release" version after the latest released version.
     */
    const verifyIfDraftIsDifferentThanLatestReleasedVersion = async (): Promise<void> => {
        // Verify if draft, otherwise return.
        if (!editedProcess?.isDraft) {
            setIsDraftDifferentThanLatestReleasedVersion(false);
            setisDraftDifferentThanLatestInApprovalVersion(false);
            return;
        }

        // Find all processes for the current TreeNodeId
        const allProcessWithCurrentTreeNodeId = processes.filter((process) => process.treeNodeId === editedTreeNodeId);

        // Look if there is more than one process version, otherwise return.
        if (allProcessWithCurrentTreeNodeId.length <= 1) {
            setIsDraftDifferentThanLatestReleasedVersion(false);
            setisDraftDifferentThanLatestInApprovalVersion(false);
            return;
        }

        // Find get the latest released (and not retired) version, if any.
        // Sort by the processId in descending order to get the most recent one.
        const latestReleasedProcess = allProcessWithCurrentTreeNodeId
            .sort(function (a, b) {
                return b.processId - a.processId;
            })
            .find((process) => process.releasedOn && !process.retiredOn);

        // Find get the latest in releasing process version, if any.
        // Sort by the processId in descending order to get the most recent one.
        // Filtering out in release processes that are older than the latest release found (if any) version also.
        const latestInReleaseProcess = allProcessWithCurrentTreeNodeId
            .sort(function (a, b) {
                return b.processId - a.processId;
            })
            .find(
                (process) =>
                    (!latestReleasedProcess ||
                        (process.updatedOn && latestReleasedProcess.updatedOn && process.updatedOn > latestReleasedProcess.updatedOn)) &&
                    !process.releasedOn &&
                    !process.retiredOn &&
                    (process.versionStatus === ApprovalStatusEnum.APPROVED || process.versionStatus === ApprovalStatusEnum.AWAITING)
            );

        // Verify if there is a latest release process. If so, make the comparison. If not, the draft version is by default more recent and should be flagged.
        if (latestReleasedProcess) {
            dispatch(processGet(latestReleasedProcess.processId)).then((process) => {
                if (process) setIsDraftDifferentThanLatestReleasedVersion(isDraftMoreRecentThanProcess(process));
            });
        } else {
            setIsDraftDifferentThanLatestReleasedVersion(false);
        }

        // Verify if there is an in-release process. If so, make the comparison.
        if (latestInReleaseProcess) {
            dispatch(processGet(latestInReleaseProcess.processId)).then((process) => {
                if (process) setisDraftDifferentThanLatestInApprovalVersion(isDraftMoreRecentThanProcess(process));
            });
        } else {
            setisDraftDifferentThanLatestInApprovalVersion(false);
        }

        // Verify the corner case where all process are retired. if so, compare with the latest retired version.
        if (!latestReleasedProcess && !latestInReleaseProcess) {
            // Find get the latest in retired process version, if any.
            // Sort by the processId in descending order to get the most recent one.
            const latestRetiredProcess = allProcessWithCurrentTreeNodeId
                .sort(function (a, b) {
                    return b.processId - a.processId;
                })
                .find((process) => process.releasedOn && process.retiredOn);

            if (latestRetiredProcess) {
                dispatch(processGet(latestRetiredProcess.processId)).then((process) => {
                    if (process) setIsDraftDifferentThanLatestReleasedVersion(isDraftMoreRecentThanProcess(process));
                });
            } else {
                setIsDraftDifferentThanLatestReleasedVersion(false);
            }
        }
    };

    /**
     * Verify if the draft is more recent then the process to compare.
     * Return True is draft is more recent. False if least recent or if not able to compare.
     */
    const isDraftMoreRecentThanProcess = (process: IProcessUiModel): boolean => {
        // Verify if the current editedProcess is the draft version, otherwise return.
        if (!editedProcess?.isDraft || process.isDraft) {
            return false;
        }

        // At this point, the editedProcess is the Draft.
        const draftProcess = editedProcess;

        // Verify if the draft variables is different than process variables.
        if (JSON.stringify(draftProcess.variables) !== JSON.stringify(process.variables)) {
            return true;
        }

        // Verify the actions Length. If not the same, return true
        if (draftProcess.actions.length !== process.actions.length) {
            return true;
        }

        // Copy the process array into a temporary array
        let processActions = deepClone(process.actions);

        // For each element... compare. If there is a difference, then return true.
        for (const draftAction of draftProcess.actions) {
            const processActionsLengthBeforeFiltering = processActions.length;
            processActions = processActions.filter((processAction) => {
                // Compare primitive first : type. For now, posX and posY are not considered as a fonctional change since it's only aesthethic.
                if (
                    draftAction.type !== processAction.type ||
                    draftAction.label !== processAction.label // ||
                    // draftAction.posX !== processAction.posX ||
                    // draftAction.posY !== processAction.posY
                ) {
                    return true;
                }
                // Compare Inputs
                if (JSON.stringify(draftAction.inputs) !== JSON.stringify(processAction.inputs)) {
                    return true;
                }
                // Compare Outputs
                if (
                    // Remove the "ActionId" links from the string
                    JSON.stringify(draftAction.outputs).replace(/"actionId":\d+,/gm, "") !==
                    JSON.stringify(processAction.outputs).replace(/"actionId":\d+,/gm, "")
                ) {
                    return true;
                }
                // Compare steps
                // Exclude the following properties because they are updated on version creation:
                // - processActionId
                // - processActionStepId
                // - previousStepId
                // - trainingCommunique
                // - trainingCommuniqueProcessVersion
                const processActionStepComparisionRegex =
                    /("processActionId":\d+,?)|("processActionStepId":\d+,?)|("previousStepId":\d+,?)|("trainingCommunique":".*?",?)|("trainingCommuniqueProcessVersion":("\d+\.\d+"|""),?)/gm;

                if (
                    JSON.stringify(draftAction.steps).replace(processActionStepComparisionRegex, "") !==
                    JSON.stringify(processAction.steps).replace(processActionStepComparisionRegex, "")
                ) {
                    return true;
                }

                // Return false to keep it in the array
                return false;
            });

            // Verify that the length has decrease by 1.
            if (processActionsLengthBeforeFiltering - 1 !== processActions.length) {
                return true;
            }
        }

        // No differences found yet, return false.
        return false;
    };

    return (
        <>
            {circularProgressActive && (
                <Backdrop className={classes.backdrop} open={circularProgressActive} id={"backdropId"}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Paper className={classes.root} elevation={4}>
                <div ref={svgContainer} className={classes.processSVGContainer}>
                    <svg
                        id={svgId}
                        ref={svgArea}
                        xmlns="http://www.w3.org/2000/svg"
                        width={normalizeSVGDimension(svgContainer.current ? svgContainer.current.clientWidth : 0)}
                        height={normalizeSVGDimension(svgContainer.current ? svgContainer.current.clientHeight : 0)}
                        viewBox={viewBoxInfo}
                        className={classes.processSVG}
                        onWheel={handleWheelChange}
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onTouchStart={handleMouseDown}
                        onTouchEnd={handleMouseUp}
                        style={{ cursor: "move" }}
                    >
                        <defs>
                            <filter
                                id="f1"
                                x="-300"
                                y="-100"
                                width="110%"
                                height="110%"
                                filterUnits="userSpaceOnUse"
                                primitiveUnits="userSpaceOnUse"
                            >
                                <feComposite in2="SourceAlpha" operator="in" />
                                <feGaussianBlur stdDeviation="6" />
                                <feOffset dx="1" dy="3" result="afterOffset" />
                                <feFlood floodColor="black" floodOpacity="0.3" />
                                <feComposite in2="afterOffset" operator="in" />
                                <feMorphology operator="dilate" radius="1" />
                                <feComposite in2="SourceAlpha" operator="out" />
                            </filter>
                            <g id="actionNode">
                                <circle cx="0" cy="0" r="7" stroke="white" strokeWidth="1" fill="currentColor" />
                            </g>
                        </defs>
                        {editedProcess && editedProcess.actions && (
                            <ActionBlocksAndLinks
                                actions={editedProcess.actions}
                                canvasScale={canvasScale}
                                onActionBlockDoubleClick={handleActionBlockDoubleClick}
                                onActionBlockSelect={handleActionBlockSelect}
                                onActionBlockClickAway={handleActionBlockClickAway}
                                onOpenActionMenu={handleOpenActionMenu}
                                selectedActionIds={selectedProcessActionIds}
                                svgRatio={svgRatio}
                                userAccessLevel={processEditorUserAccessLevel}
                            />
                        )}
                    </svg>
                </div>

                <RepoBreadCrumbs
                    onSelectProcess={handleSelectProcess}
                    onCancelSelectProcess={handleCancelSelectProcess}
                    onVersionChange={handleProcessVersionChange}
                    onVersionsLoaded={handleProcessVersionsLoaded}
                    opened={repoSelectorOpen}
                    processId={editedProcess && editedProcess.processId}
                    style={{ position: "absolute", left: "0px", top: "0px" }}
                    treeNodeId={editedProcess && editedProcess.treeNodeId}
                    userAccessLevel={userInfo && userInfo.roleRights.repositoryEditor}
                    inRouting={inRouting}
                    onBackFromRouting={handleBackFromRouting}
                    isDraftDifferentThanLatestReleasedVersion={isDraftDifferentThanLatestReleasedVersion}
                    isDraftDifferentThanLatestInApprovalVersion={isDraftDifferentThanLatestInApprovalVersion}
                />

                {/* SIDE MENU */}
                {editedTreeNode && (
                    <ProcessEditorSideMenu
                        readOnly={readOnly}
                        onInsertAction={handleInsertActionClick}
                        onDryRunClick={handleDryRunClick}
                        onTestPlayClick={handleTestPlayOpen}
                        onPlayClick={handlePlayOpen}
                        onTrainingClick={handleTrainingDrawerOpened(true)}
                        onValidateProcess={handleValidateClick}
                        processApprovalSideMenuUserAccessLevel={processApprovalSideMenuUserAccessLevel}
                        processEditorSideMenuUserAccessLevel={processEditorSideMenuUserAccessLevel}
                    />
                )}
                {!Boolean(editedProcess) && !circularProgressActive && (
                    <Button variant="contained" color="secondary" className={classes.selectButton} onClick={handleOpenRepoSelector}>
                        <Typography>{translate("repoBreadCrumbs.selectProcess")}</Typography>
                    </Button>
                )}

                {/* BOTTOM NAVIGATION MENU */}
                <ProcessEditorNavMenu onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onCenter={handleCenter} />

                {editedProcessAction && showActionEditor && (
                    <Dialog
                        fullScreen={true}
                        open={Boolean(showActionEditor && editedProcessAction)}
                        onClose={closeDialog}
                        TransitionComponent={Transition}
                    >
                        <ActionEditor
                            editedAction={editedProcessAction}
                            onCloseDialog={closeDialog}
                            preselectedStep={preselectedProcessActionStepIndex}
                            userAccessLevel={processEditorUserAccessLevel}
                        />
                    </Dialog>
                )}

                <ActionSelector
                    isRouting={editedProcess ? editedProcess.type === ProcessType.ROUTING : false}
                    addFail={
                        editedProcess?.actions.find(
                            (action) => action.type === (editedProcess.type === ProcessType.ROUTING ? "core-routing-fail" : "core-fail")
                        ) === undefined
                    }
                    open={actionSelectorOpen}
                    onInsertAction={handleOnInsertAction}
                    onClose={handleClose}
                />

                <Menu id="action-menu" anchorEl={actionMenuAnchorEl} open={Boolean(actionMenuAnchorEl)} onClose={handleClose}>
                    <MenuItem onClick={onActionEdit} onMouseDown={handleClose} id="actionMenuEditId">
                        {readOnly ? translate("processEditor.read") : translate("processEditor.edit")}
                    </MenuItem>
                    {editedProcess?.type === ProcessType.ROUTING &&
                        editedProcessAction?.type === "core-routing-process" &&
                        ((editedProcessAction as ProcessActionRoutingProcess)?.steps[0]).config.treeNodeId !== 0 && (
                            <MenuItem onClick={handleOpenProcess} onMouseDown={handleClose} id="actionMenuOpenProcessId">
                                {translate("processEditor.openProcess")}
                            </MenuItem>
                        )}
                    <MenuItem disabled={readOnly} onClick={handleDisconnect} onMouseDown={handleClose} id="actionMenuDisconnectId">
                        {translate("processEditor.disconnect")}
                    </MenuItem>
                    <MenuItem disabled={true} onMouseDown={handleClose} id="actionMenuStartId">
                        {translate("processEditor.start")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        disabled={isDeleteDisabled || readOnly}
                        onClick={handleActionDeleteConfirmationOpen}
                        onMouseDown={handleClose}
                        id="actionMenuDeleteId"
                    >
                        {translate("processEditor.delete")}
                    </MenuItem>
                </Menu>

                {playerDialogOpened ? (
                    <PlayerDialog
                        processId={editedProcess && editedProcess.processId ? editedProcess.processId : 0}
                        dryRunMode={playerDryRunMode}
                        testRunMode={playerTestRunMode}
                        runWithoutJobProcess={true}
                        onClose={togglePlayerDialog(false)}
                        reworkId={0}
                        open={playerDialogOpened}
                        title={getProcessTitle(playerDryRunMode)}
                    />
                ) : null}

                {deleteConfirmationOpen && (
                    <KortexDialogConfirmation
                        open={deleteConfirmationOpen}
                        textLabels={{
                            titleLabel: translate("processEditor.deleteConfirmationTitle"),
                            cancelButtonLabel: translate("processEditor.deleteConfirmationCancel"),
                            proceedButtonLabel: translate("processEditor.deleteConfirmationConfirm"),
                        }}
                        closeOnEscape={true}
                        onConfirm={handleDeleteConfirmation(true)}
                        onCancel={handleDeleteConfirmation(false)}
                    >
                        {translate("processEditor.deleteConfirmationLabel")}
                    </KortexDialogConfirmation>
                )}

                {editedProcess && trainingDrawerOpened ? (
                    <ProcessTrainingCommuniqueDrawer
                        open={trainingDrawerOpened}
                        onClose={handleTrainingDrawerOpened(false)}
                        onStepClick={handleProcessValidationErrorClick}
                        process={editedProcess}
                    />
                ) : null}

                <ProcessValidationDrawer
                    open={processValidationDrawerOpened}
                    onClose={handleProcessValidationDrawerOpened(false)}
                    onErrorClick={handleProcessValidationErrorClick}
                    process={editedProcess}
                    processValidation={processValidation}
                />

                {editedProcess && (
                    <ProcessVariableCopyDialog
                        onClose={handleCloseVariableCopyDialog}
                        variableDuplicatesWithDifferentProps={variableDuplicatesWithDifferentProps}
                        variables={editedProcess.variables}
                    />
                )}
            </Paper>
        </>
    );
}

export default ProcessEditor;
