export const TITLE_EN = {
    "titleKeyError.approvalProcessVersion": "Process version approval.",
    "titleKeyError.approvalRoutingVersion": "Routing version approval.",
    "titleKeyError.assertProcessVersion": "Confirmation of the process version.",
    "titleKeyError.cannotCopyEmptyPayload": "Empty payload.",
    "titleKeyError.configError": "Config error.",
    "titleKeyError.configurationOrganizationSettings": "Configuring organization settings.",
    "titleKeyError.databaseCommunicationError": "Database communication error.",
    "titleKeyError.erpError": "ERP error.",
    "titleKeyError.executionError": "Execution error.",
    "titleKeyError.holdProcessVersion": "Unable to hold process version.",
    "titleKeyError.invalidElectronicSignature": "Invalid electronic signature.",
    "titleKeyError.invalidTraceability": "Invalid traceability.",
    "titleKeyError.invalidUser": "Invalid user.",
    "titleKeyError.invalidVariableIdentifier": "Invalid variable identifier.",
    "titleKeyError.jobProcessQtyMaxReach": "The maximum quantity of items for the job process has been exceeded.",
    "titleKeyError.jobQtyMaxReach": "The maximum quantity of job items has been exceeded.",
    "titleKeyError.noProcessVersionAvailable": "No process version available.",
    "titleKeyError.noRunnerAvailable": "Process execution system not available.",
    "titleKeyError.processIsDraft": "Process version is a draft.",
    "titleKeyError.requestFailedRunner": "Communication error with process execution system.",
    "titleKeyError.unableToPerformYourAction": "Unable to perform your action.",
    "titleKeyError.unableCancelProcessVersion": "Unable to cancel process version.",
    "titleKeyError.unableCopyProcessActions": "Unable to copy process actions.",
    "titleKeyError.unableCreateNewJob": "Unable to create new job.",
    "titleKeyError.unableCreateNewColumn": "Unable to create new column.",
    "titleKeyError.unableCreateNewTraining": "Unable to create new training.",
    "titleKeyError.unableCreateProcessVersion": "Could not create a version.",
    "titleKeyError.unableCreateReworkJobAndNewFailureTicket": "Executing the process failed to create a rework job and a new failure ticket.",
    "titleKeyError.unableCreateTrainingCertificate": "Unable to create the training certificate.",
    "titleKeyError.unableCreateTrainingReadingCertificate": "Unable to create the reading certificate.",
    "titleKeyError.unableGetErpSettings": "Can't get ERP settings",
    "titleKeyError.unableCreateTrainingCertification": "Unable to create training certification.",
    "titleKeyError.unableSignTrainingCertificate": "Unable to sign training certificate.",
    "titleKeyError.unableSignTrainingCertification": "Unable to sign training certification.",
    "titleKeyError.unableHoldProcessVersion": "Unable to hold process version.",
    "titleKeyError.unableInsertRouting": "Unable to insert routing.",
    "titleKeyError.unableInsertUser": "Unable to insert user.",
    "titleKeyError.unableInsertUserRole": "Unable to insert role.",
    "titleKeyError.unableInsertProcessFile": "Unable to insert process.",
    "titleKeyError.unableUpdateJob": "Unable to update job.",
    "titleKeyError.unableUpdateProcess": "Unable to update process.",
    "titleKeyError.unableUpdateRouting": "Unable to update routing.",
    "titleKeyError.unableUpdatePassword": "Unable to update password.",
    "titleKeyError.unableInsertTreeFile": "Unable to insert tree file.",
    "titleKeyError.unableUpdateTreeFile": "Unable to update tree file.",
    "titleKeyError.unableInsertLog": "Unable to insert log.",
    "titleKeyError.unableUpdateUserRole": "Unable to update role.",
    "titleKeyError.unableDeleteUserRole": "Unable to delete role.",
    "titleKeyError.unableUserConnection": "User login not possible.",
    "titleKeyError.unhandledError": "Unhandled error.",
    "titleKeyError.unknownError": "Unknown error.",
    "titleKeyError.accessUnauthorized": "Access Unauthorized.",
};
