import { theme } from "@aos/react-components";
import {
    BomFollowUpHistoryTraceabilityType,
    BomFollowUpSerializedItemHistoryTraceabilityType,
    getDateTimeStr,
    IBomFollowUp,
} from "@kortex/aos-common";
import { MenuType } from "@kortex/aos-ui/components/core/bom";
import { useBomContext } from "@kortex/aos-ui/components/pages/bom/context";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Box, Collapse, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
    header: {
        backgroundColor: theme.palette.grey[300],
    },
    tableCell: {
        padding: "4px",
    },
    rowHistory: {
        borderBottom: "none",
    },
    collapse: {
        padding: "0px",
    },
});

interface IItemRowProps {
    bomFollowUp: IBomFollowUp;
    itemId: number | string;
    open: boolean;
}

const woBomTableItemHistoryRow: FC<IItemRowProps> = (props) => {
    const { bomFollowUp, itemId, open } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const { bomFollowUpSymptoms } = useBomContext();

    const convertHistoryType = (
        historyType: BomFollowUpHistoryTraceabilityType | BomFollowUpSerializedItemHistoryTraceabilityType,
        traceabilityType: MenuType
    ): string => {
        switch (historyType) {
            case "edit":
                if (traceabilityType === "serial") {
                    return translate("bomPage.bomTable.history.editedSerialNumber");
                } else {
                    return translate("bomPage.bomTable.history.editedTraceability");
                }
            case "multiTraceability":
                return translate("bomPage.bomTable.history.multiTraceability");
            case "overconsumption":
                return translate("bomPage.bomTable.history.overconsumedTraceability");
            case "remove":
                if (traceabilityType === "serial") {
                    return translate("bomPage.bomTable.history.removedSerialNumber");
                } else {
                    return translate("bomPage.bomTable.history.removedTraceability");
                }
            case "replacement":
                if (traceabilityType === "serial") {
                    return translate("bomPage.bomTable.history.replacedSerialNumber");
                } else {
                    return translate("bomPage.bomTable.history.replacedTraceability");
                }
            case "reassignment":
                if (traceabilityType === "serial") {
                    return translate("bomPage.bomTable.history.reassignedSerialNumber");
                } else {
                    return translate("bomPage.bomTable.history.reassignedTraceability");
                }
            case "standard":
                if (traceabilityType === "serial") {
                    return translate("bomPage.bomTable.history.createdSerialNumber");
                } else {
                    return translate("bomPage.bomTable.history.createdTraceability");
                }
            case "moveIn":
                return translate("bomPage.bomTable.history.serialNumberReassociated");
            case "moveOut":
                return translate("bomPage.bomTable.history.serialNumberDissociated");
            default:
                if (traceabilityType === "serial") {
                    return translate("bomPage.bomTable.history.createdSerialNumber");
                } else {
                    return translate("bomPage.bomTable.history.createdTraceability");
                }
        }
    };

    const renderHistoryRow = (): JSX.Element[] => {
        const sortableElements: { key: number; element: JSX.Element }[] = [];

        for (const [historyIndex, history] of bomFollowUp.history.entries()) {
            const historySymptom = bomFollowUpSymptoms.find((symptom) => symptom.bomFollowUpSymptomId === history.bomFollowUpSymptomId);
            sortableElements.push({
                element: (
                    <TableRow className={classes.rowHistory} key={history.bomFollowUpHistoryId}>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowType${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {convertHistoryType(history.type, "traceability")}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowCreatedOn${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {getDateTimeStr(history.createdOn)}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowFullName${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {history.user.fullName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowTraceability${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {history.traceability.length === 0 ? translate("general.na") : history.traceability}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowQuantity${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {history.quantity}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowPreviousTraceability${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {history.previousValues?.traceability && history.previousValues?.traceability.length > 0
                                    ? history.previousValues?.traceability
                                    : translate("general.na")}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowPreviousQuantity${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {history?.previousValues?.quantity ?? translate("general.na")}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowJustification${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {history.justification || translate("general.na")}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                            <Typography
                                id={`woBomTableItemHistoryRowSymptom${bomFollowUp.partNumber}-${itemId}-${historyIndex}Id`}
                                variant="body2"
                            >
                                {historySymptom?.title || translate("general.na")}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ),
                key: history.createdOn ?? 0,
            });
        }

        for (const [serialNumberIndex, serializedItem] of bomFollowUp.serializedItems.entries()) {
            for (const [historyIndex, serialNumber] of serializedItem.history.entries()) {
                const serialHistorySymptom = bomFollowUpSymptoms.find(
                    (symptom) => symptom.bomFollowUpSymptomId === serialNumber.bomFollowUpSymptomId
                );
                sortableElements.push({
                    element: (
                        <TableRow className={classes.rowHistory} key={serialNumber.bomFollowUpSerializedItemHistoryId}>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemType${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {convertHistoryType(serialNumber.type, "serial")}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemCreatedOn${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {getDateTimeStr(serialNumber.createdOn)}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemFullName${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {serialNumber.user.fullName}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemSerialNumber${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {serialNumber.serialNumber && serialNumber.serialNumber.length > 0
                                        ? serialNumber.serialNumber
                                        : translate("general.na")}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemQuantity${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {translate("general.na")}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemPreviousSerialNumber${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {serialNumber.previousValues?.serialNumber && serialNumber.previousValues.serialNumber.length > 0
                                        ? serialNumber.previousValues.serialNumber
                                        : translate("general.na")}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemPreviousQuantity${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {translate("general.na")}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemJustification${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {serialNumber.justification || translate("general.na")}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                <Typography
                                    id={`woBomTableItemHistoryRowSerializedItemSymptom${bomFollowUp.partNumber}-${itemId}-${serialNumberIndex}-${historyIndex}Id`}
                                    variant="body2"
                                >
                                    {serialHistorySymptom?.title || translate("general.na")}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ),
                    key: serialNumber.createdOn ?? 0,
                });
            }
        }

        return sortableElements.sort((elem1, elem2) => elem2.key - elem1.key).map((element) => element.element);
    };

    return (
        <TableRow className={classes.root}>
            <TableCell colSpan={9} className={classes.collapse}>
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                    <Box margin={1}>
                        <Typography variant="h4" gutterBottom={true} component="div">
                            {translate("bomPage.bomTable.history")}
                        </Typography>
                        <Table align="right" size="small" aria-label="purchases">
                            <TableHead className={classes.header}>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.type")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("general.date")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("general.userName")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.traceability")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.quantity")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.previousTraceability")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.previousQuantity")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.justification")}</Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="h6">{translate("bomPage.bomTable.symptom")}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderHistoryRow()}</TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default woBomTableItemHistoryRow;
