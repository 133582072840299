import { JOB_QTY_MAX } from "../../../../../Job/jobUtils";
export const SUGGESTION_FR = {
    "suggestionKeyError.actionRequiredVersionDraft": "L'action en cours requiert une version brouillon du processus.",
    "suggestionKeyError.associateExecutionAtOneFailureTicket": "Pour que ce processus puisse créer un job de réparation et un nouveau billet d'échec, il faut que celui-ci soit planifié dans un billet d'échec.",
    "suggestionKeyError.changeStatusOfTheJobToUpdateIt": "Modifier le statut du travail pour pouvoir mettre à jour le job.",
    "suggestionKeyError.contactAdminUser": "Contacter l'utilisateur administrateur.",
    "suggestionKeyError.contactAosDevTeam": "Contacter l'équipe de développement AOS.",
    "suggestionKeyError.contactResponsibleProcessApprovedOrReleaseInRouting": "Contacter les responsables de l'approbation et de la publication des processus.",
    "suggestionKeyError.completeTheChangelog": "Le changelog doit être complété afin de créer une nouvelle version.",
    "suggestionKeyError.completeTheReferenceId": "L'ID de référence doit être complété afin de créer une nouvelle version.",
    "suggestionKeyError.completeTrainingCertificate": "Compléter une attestation de formation.",
    "suggestionKeyError.emptySuggestion": " ",
    "suggestionKeyError.expectedPasswordFormat": "Le mot de passe doit comporter au moins 8 caractères et contenir au moins 1 lettre majuscule et 1 chiffre.",
    "suggestionKeyError.findOrCreateJob": "Retrouvez le bon de commande déjà créé ou créez un autre travail.",
    "suggestionKeyError.findOrCreateTraining": "Recherchez la formation déjà créée ou sélectionnez une autre version du processus pour créer la formation.",
    "suggestionKeyError.jobQtyMaxReach": `Mettre une quantité égale ou inférieure à ${JOB_QTY_MAX}.`,
    "suggestionKeyError.makeSureEnteredInfoCorrectly": "Assurez-vous d'avoir correctement saisi les informations ou contacter l'administrateur pour activer l'utilisateur.",
    "suggestionKeyError.makeSureTraceabilityEnteredIsDesired": "Assurez-vous que la traçabilité saisie soit bien celle souhaitée.",
    "suggestionKeyError.makeSureTraceabilityEnteredIsNotExpired": "Assurez-vous que la traçabilité saisie ne soit pas périmée.",
    "suggestionKeyError.makeSureEachProcessInRoutingApproved": "Assurez-vous que chaque processus utilisé dans le routage possède une version approuvée.",
    "suggestionKeyError.makeSureUsernameAndPasswordEnteredCorrectlyThenTryAgain": "Assurez-vous que le nom d'utilisateur et le mot de passe ont été saisis correctement, puis réessayez.",
    "suggestionKeyError.processVersionStatusRequiredForTrainingRequirementUpdate": "La version du processus doit avoir le statut « En attente ».",
    "suggestionKeyError.releaseDateNotEqualOrLaterThanRetiredDate": "Assurez-vous que la date de publication est avant la date de retrait.",
    "suggestionKeyError.selectAllMandatoryApprovalGroups": "Sélectionner tous les groupes d'approbation requis pour créer une nouvelle version.",
    "suggestionKeyError.selectVersion.OtherThanDraft": "Sélectionner une version autre que le brouillon.",
    "suggestionKeyError.storeResultError": "Le système tentera de résoudre la situation automatiquement. Si cette erreur se produit trop fréquemment, contactez l'équipe de développement d'AOS.",
    "suggestionKeyError.unknownError": "Erreur inconnue.",
    "suggestionKeyError.useAnotherIdentifier": "Utiliser un autre identifiant.",
    "suggestionKeyError.useAnotherPrefix": "Utiliser un autre préfixe que SYS_.",
    "suggestionKeyError.useAnotherUsername": "Utiliser un autre nom d'utilisateur, ce nom d'utilisateur existe déjà.",
    "suggestionKeyError.useAnotherUsercode": "Utiliser un autre code d'utilisateur, ce code d'utilisateur existe déjà.",
    "suggestionKeyError.validateBomIdAndRevision": "Vérifier que l'ID et la révision du BOM sont valides.",
    "suggestionKeyError.validateJobOrBom": "Vérifier que le job existe dans le système ERP. Vérifier que l'ID et la révision du BOM sont valides.",
};
