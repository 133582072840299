import { KortexDialogConfirmation } from "@aos/react-components";
import { IBomFollowUp } from "@kortex/aos-common";
import { useAppLayoutContext } from "@kortex/aos-ui/components/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorBoms } from "@kortex/aos-ui/redux/selectors";
import { Button, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC, useEffect, useState } from "react";

import { serviceMap, ServiceVariant } from "../../utils";

import Row, { FollowUpRow } from "./row";

const useStyles = makeStyles({
    buttonIcon: {
        paddingRight: "10px",
    },
    dialogButtons: {
        margin: "10px 5px 5px 5px",
        padding: "0px 20px",
    },
});

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
    isQuantityDecimal: boolean;
}

const MultipleTraceabilitiesDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant, isQuantityDecimal } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const bom = useSelectorBoms();
    const { loading, setLoading } = useAppLayoutContext();

    const initialQuantity = followUp.quantity;
    const isFollowUpQuantityZero = initialQuantity === 0;

    const createDefaultRows = (): [firstRow: FollowUpRow, defaultRow: FollowUpRow] => {
        const firstLineQuantity = isFollowUpQuantityZero
            ? 0
            : isQuantityDecimal
            ? initialQuantity
            : initialQuantity === 1
            ? 1
            : initialQuantity - 1;

        return [
            { ...followUp, quantity: firstLineQuantity, valid: true },
            {
                quantity: isQuantityDecimal ? 1e-6 : isFollowUpQuantityZero ? 0 : 1,
                traceability: "",
                serializedItems: [],
                valid: true,
            },
        ];
    };

    const [followUpData, setFollowUpData] = useState<FollowUpRow[]>(createDefaultRows());

    /**
     * Reset the rows when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setFollowUpData(createDefaultRows());
        }
    }, [open]);

    // Calculate the sum of quantities in the followUpData
    const sumOfQuantities = followUpData.reduce((acc, row) => acc + row.quantity, 0);

    // Check if the number of serial numbers assigned to a row is greater than the row's quantity
    const checkSerialNumberCountPerRow = (): boolean => {
        return followUpData.some((row) => row.quantity < row.serializedItems.length);
    };

    // Disabled the CONFIRM button if any of the following conditions is met:
    const confirmButtonDisabled =
        //followUpData is empty
        followUpData.length === 0 ||
        // the quantity in the followUp object is not equal to the sum of quantities in the followUpData array
        (!isFollowUpQuantityZero && initialQuantity !== sumOfQuantities) ||
        // followUpData is invalid
        followUpData.some((row) => !row.traceability?.trim() || (!isFollowUpQuantityZero && row.quantity <= 0) || !row.valid) ||
        // the quantity of serial number is invalid (if same as follow-up quantity)
        (followUp.serializedItems.length > 0 &&
            followUp.serializedItems.length === initialQuantity &&
            !followUpData.every((row) => row.quantity === row.serializedItems.length)) ||
        // the quantity of serial number is invalid (if less than follow-up quantity)
        (followUp.serializedItems.length > 0 && followUp.serializedItems.length < initialQuantity && checkSerialNumberCountPerRow()) ||
        // there are deplicate traceabilities in followUpData and/or invalid traceabilities in the ERP (LOT SERIAL DATE)
        followUpData.some(
            (item1, index1, self) =>
                item1.traceability && // Traceability is not blank
                self.some(
                    (item2, index2) =>
                        item1.traceability === item2.traceability && // Traceability is the same as another row
                        index1 !== index2 // Prevent comparison with self
                )
        );

    /**
     * Creates a new row in the dialog to add a traceability
     */
    const handleAddTraceability = (): void => {
        setFollowUpData((prevState) => [...prevState, createDefaultRows()[1]]);
    };

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Assign a traceability to an item
     */
    const handleConfirm = (): void => {
        setLoading(true);

        dispatch(serviceMap[variant].multipleTraceabilities(followUpData))
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates the follow-up from state
     */
    const handleFollowUpChange = (updatedFollowUp: FollowUpRow[]): void => {
        setFollowUpData(updatedFollowUp);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.multipleTraceabilities"),
            }}
        >
            <>
                <div key="multipleTraceabilitiesDialogOriginalTraceability">
                    <Typography
                        color={initialQuantity === sumOfQuantities || isFollowUpQuantityZero ? "textPrimary" : "error"}
                        variant="body2"
                    >{`${translate("bomPage.bomTable.quantitySum")} ${initialQuantity}`}</Typography>
                    {/* ROWS */}
                    {followUpData.map((row, index): JSX.Element => {
                        const isDuplicate = followUpData.some(
                            (item, indexItem) =>
                                item.traceability && // Traceability is not blank
                                item.traceability === row.traceability && // Traceability is the same as another row
                                indexItem !== index // Prevent comparison with self
                        );

                        return (
                            <Row
                                followUp={followUpData}
                                index={index}
                                initialQuantity={initialQuantity}
                                isDuplicate={isDuplicate}
                                isFollowUpQuantityZero={isFollowUpQuantityZero}
                                isQuantityDecimal={isQuantityDecimal}
                                key={index}
                                lotSerialType={bom[0]?.woBom.items[followUp.partNumber].lotSerialType}
                                onFollowUpChange={handleFollowUpChange}
                                partNumber={followUp.partNumber}
                                variant={variant}
                            />
                        );
                    })}
                </div>
                {/* "ADD ROW" BUTTON  */}
                <Button
                    className={classes.dialogButtons}
                    color="secondary"
                    disabled={loading}
                    id="multipleTraceabilitiesDialogAddTraceabilityButtonId"
                    onClick={handleAddTraceability}
                    variant="contained"
                >
                    <AddIcon className={classes.buttonIcon} />
                    {translate("bomPage.bomTable.addTraceability")}
                </Button>
            </>
        </KortexDialogConfirmation>
    );
};

export default MultipleTraceabilitiesDialog;
