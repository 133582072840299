import { BomItems, OrUndefined, ProcessActionStepWorkInstructions, WorkInstructionStepBomInvalidItems } from "@kortex/aos-common";
import { TableBody } from "@material-ui/core";
import React, { useContext, useMemo } from "react";

import { ActionEditorContext } from "../../../../../context";
import { useWorkInstructionBomContext } from "../../../context";

import WorkInstructionsBomItemsRow from "./Row/WorkInstructionsBomItemsTableRow";
import WorkInstructionsBomStepInvalidItemsRow from "./Row/WorkInstructionsBomStepInvalidItemsTableRow";
import WorkInstructionsBomStepItemsRow from "./Row/WorkInstructionsBomStepItemsTableRow";

interface IOwnProps {
    displayStepItems: boolean;
}

interface IAssignItems {
    items: BomItems;
    invalidItems: WorkInstructionStepBomInvalidItems;
}

function WorkInstructionsBomItemsTableBody(props: IOwnProps): JSX.Element {
    const { displayStepItems } = props;

    const { bom } = useWorkInstructionBomContext();
    const { editedActionStep } = useContext(ActionEditorContext);
    const { itemFilterCb } = useWorkInstructionBomContext();

    const assignItems = (): IAssignItems => {
        let workInstructionStepBomItems: BomItems = {};
        const workInstructionStepBomInvalidItems: WorkInstructionStepBomInvalidItems = {};
        const workInstructionsStep = editedActionStep as OrUndefined<ProcessActionStepWorkInstructions>; // To help with typing

        if (bom?.items) {
            if (displayStepItems && workInstructionsStep) {
                for (const partNumber of Object.keys(workInstructionsStep.config.bomItems ?? {})) {
                    if (bom.items[partNumber]) {
                        workInstructionStepBomItems[partNumber] = {
                            ...bom.items[partNumber],
                            ...workInstructionsStep.config.bomItems[partNumber],
                        };
                    } else {
                        workInstructionStepBomInvalidItems[partNumber] = { partNumber };
                    }
                }
            } else {
                workInstructionStepBomItems = bom.items;
            }
        }

        return {
            items: workInstructionStepBomItems,
            invalidItems: workInstructionStepBomInvalidItems,
        };
    };

    const { invalidItems, items } = useMemo(() => assignItems(), [bom, editedActionStep]);

    return (
        <TableBody>
            {Object.values(items).map((item, index) =>
                itemFilterCb(item) ? (
                    displayStepItems ? (
                        <WorkInstructionsBomStepItemsRow key={index} item={item} />
                    ) : (
                        <WorkInstructionsBomItemsRow key={index} item={item} />
                    )
                ) : null
            )}
            {displayStepItems
                ? Object.values(invalidItems).map((invalidItem, index) => (
                      <WorkInstructionsBomStepInvalidItemsRow key={index} item={invalidItem} />
                  ))
                : null}
        </TableBody>
    );
}

export default WorkInstructionsBomItemsTableBody;
