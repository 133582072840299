import { KortexTextField, theme } from "@aos/react-components";
import {
    BomFollowUpId,
    BomFollowUpSerializedItemId,
    IBomFollowUp,
    isBomItemTraceable,
    ISerializedItem,
    IWoBomItem,
    IWoBomWorkInstructionsItem,
    OrUndefined,
} from "@kortex/aos-common";
import { useAppLayoutContext } from "@kortex/aos-ui/components/context";
import { MenuType, NonTraceableItemMenu, TraceabilityMenu } from "@kortex/aos-ui/components/core/bom";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { bomInsertFollowUp, bomInsertFollowUpSerializedItem } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { userCanInsert } from "@kortex/aos-ui/utilitites/IUserRights";
import { IconButton, InputAdornment, makeStyles, PopoverPosition, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import WarningIcon from "@material-ui/icons/Warning";
import React, { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useWoBomTableContext } from "../../../context";

import { WoBomTableItemHistoryRow } from "./history";
import { ProcessInfoIcon } from "./processInfoIcon";

const useStyles = makeStyles({
    linkDisabled: {
        color: theme.palette.common.black,
        pointerEvents: "none",
        textDecoration: "none",
    },
    nonTraceableItemIconsContainer: {
        alignItems: "center",
        display: "flex",
        gap: "12px",
    },
    partNumberContainer: {
        alignItems: "center",
        display: "flex",
    },
    processInfoIconContainer: {
        padding: "12px",
    },
    root: {}, // To overwrite with props
    serialNumberCell: {
        borderBottom: "none",
    },
    tableCell: {
        height: "48px",
        padding: "8px 4px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
    warningIcon: {
        color: theme.palette.warning.main,
        height: "24px",
        marginRight: "10px",
        width: "24px",
    },
});

interface IItemRowProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUp?: IBomFollowUp;
    id: number | string;
    index: number;
    item: IWoBomItem;
    /**
     * This prop should only be used if the item is traceable and has no follow-up.
     * If not set, it will be set to one of the following value:
     * * Follow-up quantity (when the item is traceable and has a follow-up)
     * * Item quantity (when the item is not traceable)
     */
    quantity?: number;
    warning?: string;
    workInstructionsItem?: IWoBomWorkInstructionsItem;
}

const WoBomTableItemRow: FC<IItemRowProps> = (props) => {
    const { id, index, item, followUp, quantity = followUp?.quantity ?? item.quantity, warning, workInstructionsItem } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const session = useSelectorUserSession();
    const translate = useTranslate();
    const { woBom } = useWoBomTableContext();
    const { setLoading } = useAppLayoutContext();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>(undefined);
    const [menuType, setMenuType] = useState<MenuType>("traceability");
    const [serializedItemSelected, setSerializedItemSelected] = useState<ISerializedItem | undefined>(undefined);
    const [openHistory, setOpenHistory] = useState<boolean>(false);
    const [traceabilityError, setTraceabilityError] = useState<string>("");

    const isItemSerialized = Boolean(workInstructionsItem?.serialized);
    const isItemTraceable = isBomItemTraceable(item);
    const userCanInsertTraceability = userCanInsert(session?.roleRights.bom);
    const isTraceabilityFieldDisabled = !userCanInsertTraceability || !isItemTraceable || Boolean(followUp?.traceability);

    /**
     * Closes the menu attached to the 'more options' button
     */
    const handleMenuClose = (): void => {
        setMenuPosition(undefined);
    };

    const handleDialogClose = (): void => {
        setSerializedItemSelected(undefined);
    };

    /**
     * Sets the position of the menu when the 'more options' button is clicked
     */
    const handleMoreClick =
        (type: MenuType, serializedItem?: ISerializedItem): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            if (type === "serial") setSerializedItemSelected(serializedItem);

            setMenuType(type);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    /**
     * Updates the traceability of the item
     */
    const handleTraceabilityChange =
        (bomFollowUpId?: BomFollowUpId): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            setTraceabilityError("");
            if (isTraceabilityFieldDisabled) return void 0;

            const newValue = event.target.value.trim();

            if (!newValue.length) return void 0;

            setLoading(true);
            dispatch(
                bomInsertFollowUp({
                    jobRefId: woBom.jobRefId,
                    partNumber: item.partNumber,
                    processActionStepId: workInstructionsItem?.processActionStep.processActionStepId,
                    quantity,
                    traceability: newValue,
                    trackingId: woBom.trackingId,
                    trackingInstances: followUp?.trackingInstances,
                    bomFollowUpId,
                    lotSerialType: item.lotSerialType,
                })
            )
                .then((res) => {
                    if (!res) {
                        setTraceabilityError(translate("bomPage.bomTable.invalidTraceability"));
                        if (inputRef.current) {
                            inputRef.current.select();
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        };

    /**
     * Handles serial number change
     **/
    const handleSerialNumberChange =
        (
            followUpId: OrUndefined<IBomFollowUp["bomFollowUpId"]>,
            bomFollowUpSerializedItemId?: BomFollowUpSerializedItemId
        ): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const serialNumber = event.target.value.trim();

            if (!serialNumber.length) return void 0;

            if (followUpId && Boolean(serialNumber)) {
                dispatch(
                    bomInsertFollowUpSerializedItem({
                        bomFollowUpId: followUpId,
                        serialNumber,
                        type: bomFollowUpSerializedItemId ? "reassignment" : "standard",
                        bomFollowUpSerializedItemId,
                    })
                );
            }
        };

    const handleOpenHistory = (): void => {
        setOpenHistory((prevState) => !prevState);
    };

    const renderRowsSerialNumber = (qty: number, bomFollowUpLine?: IBomFollowUp): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < (isItemSerialized ? (workInstructionsItem?.singleSerialNumber ? 1 : qty) : 1); i++) {
            const textFieldDisabled =
                !Boolean(bomFollowUpLine?.traceability) || // Item does not have a traceability
                Boolean(bomFollowUpLine?.serializedItems[i]?.serialNumber) || // There is already a serial number at current index
                (i !== 0 && !bomFollowUpLine?.serializedItems[i - 1]?.serialNumber); // Previous index does not have a serial number
            const editIconDisabled =
                !userCanInsertTraceability ||
                !Boolean(bomFollowUpLine?.traceability) ||
                !Boolean(bomFollowUpLine?.serializedItems[i]?.serialNumber);

            elements.push(
                <div className={classes.serialNumberCell} key={i}>
                    <KortexTextField
                        InputProps={{
                            endAdornment: userCanInsertTraceability ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={editIconDisabled}
                                        id={`playerControlsBomItemSerialMore-${
                                            workInstructionsItem?.processActionStep.processActionStepId ?? 0
                                        }-${id}-${i}Id`}
                                        onClick={handleMoreClick("serial", bomFollowUpLine?.serializedItems[i])}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ) : null,
                            readOnly: textFieldDisabled,
                        }}
                        onBlur={handleSerialNumberChange(
                            bomFollowUpLine?.bomFollowUpId,
                            bomFollowUpLine?.serializedItems[i]?.bomFollowUpSerializedItemId
                        )}
                        TextFieldProps={{
                            id: `serialNumberInput${item.partNumber}-${
                                workInstructionsItem?.processActionStep.processActionStepId ?? 0
                            }-${id}-${i}Id`,
                            placeholder: textFieldDisabled ? "" : translate("player.bom.serialNumber"),
                        }}
                        value={bomFollowUpLine?.serializedItems[i]?.serialNumber ?? ""}
                        variant="standard"
                    />
                </div>
            );
        }

        return elements;
    };

    return (
        <>
            <TableRow className={classes.root}>
                {index === 0 ? (
                    <>
                        {/* PART NUMBER */}
                        <TableCell
                            align="left"
                            className={classes.tableCell}
                            id={`woBomTableItemRowPartNumber${item.partNumber}-${
                                workInstructionsItem?.processActionStep.processActionStepId ?? 0
                            }-${id}Id`}
                        >
                            <div className={classes.partNumberContainer}>
                                {warning ? (
                                    <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={warning}>
                                        <WarningIcon className={classes.warningIcon} />
                                    </Tooltip>
                                ) : null}
                                <Link
                                    className={isItemTraceable ? "" : classes.linkDisabled}
                                    to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${item.partNumber}`}
                                >
                                    <Typography variant="body2">{item.partNumber}</Typography>
                                </Link>
                            </div>
                        </TableCell>
                        {/* DESCRIPTION */}
                        <TableCell
                            align="left"
                            className={classes.tableCell}
                            id={`woBomTableItemRowDescription${item.partNumber}-${
                                workInstructionsItem?.processActionStep.processActionStepId ?? 0
                            }-${id}Id`}
                        >
                            <Typography variant="body2">{item.description}</Typography>
                        </TableCell>
                        {/* LOCATION */}
                        <TableCell
                            align="left"
                            className={classes.tableCell}
                            id={`woBomTableItemRowLocation${item.partNumber}-${
                                workInstructionsItem?.processActionStep.processActionStepId ?? 0
                            }-${id}Id`}
                        >
                            <Typography variant="body2">{item.location}</Typography>
                        </TableCell>
                        {/* MATERIAL LOCATION */}
                        <TableCell align="left" className={classes.tableCell} id={`woBomTableItemRowMaterialLocation${id}Id`}>
                            <Typography variant="body2">{item.materialLocation}</Typography>
                        </TableCell>
                        {/* LOT / SERIAL TYPE */}
                        <TableCell
                            align="left"
                            className={classes.tableCell}
                            id={`woBomTableItemRowLotSerialType${item.partNumber}-${
                                workInstructionsItem?.processActionStep.processActionStepId ?? 0
                            }-${id}Id`}
                        >
                            <Typography variant="body2">{item.lotSerialType}</Typography>
                        </TableCell>
                    </>
                ) : (
                    <TableCell colSpan={5} />
                )}
                {/* QUANTITY */}
                <TableCell
                    align="left"
                    className={classes.tableCell}
                    id={`woBomTableItemRowQuantity${item.partNumber}-${
                        workInstructionsItem?.processActionStep.processActionStepId ?? 0
                    }-${index}-${id}Id`}
                >
                    <Typography variant="body2">{`${followUp?.isOverconsumption ? "+ " : ""}${quantity}`}</Typography>
                </TableCell>
                {isItemTraceable ? (
                    <>
                        {/* TRACEABILITY */}
                        <TableCell align="left" className={classes.tableCell}>
                            <KortexTextField
                                InputProps={{
                                    inputRef: inputRef,
                                    startAdornment: workInstructionsItem ? (
                                        <ProcessInfoIcon workInstructionsItem={workInstructionsItem} />
                                    ) : null,
                                    endAdornment:
                                        userCanInsertTraceability && Boolean(followUp?.traceability) ? (
                                            <InputAdornment position="end">
                                                {/* OPTIONS BUTTON */}
                                                <IconButton
                                                    id={`woBomTableItemRowTraceabilityMore${item.partNumber}-${
                                                        workInstructionsItem?.processActionStep.processActionStepId ?? 0
                                                    }-${id}Id`}
                                                    onClick={handleMoreClick("traceability")}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null,
                                    readOnly: isTraceabilityFieldDisabled,
                                }}
                                error={traceabilityError !== "" ? traceabilityError : undefined}
                                onBlur={handleTraceabilityChange(followUp?.bomFollowUpId)}
                                TextFieldProps={{
                                    id: `woBomTableItemRowTraceability${item.partNumber}-${
                                        workInstructionsItem?.processActionStep.processActionStepId ?? 0
                                    }-${id}Id`,
                                    placeholder: isTraceabilityFieldDisabled ? "" : item.lotSerialType,
                                }}
                                value={followUp?.traceability ?? ""}
                                variant="standard"
                            />
                        </TableCell>
                        {/* SERIAL NUMBER  */}
                        <TableCell className={classes.tableCell}>
                            {isItemSerialized ? renderRowsSerialNumber(quantity, followUp) : null}
                        </TableCell>
                    </>
                ) : (
                    <TableCell className={classes.tableCell}>
                        <div className={classes.nonTraceableItemIconsContainer}>
                            {/* OPTIONS BUTTON (NON-TRACEABLE ITEM ONLY) */}
                            {workInstructionsItem ? (
                                <ProcessInfoIcon
                                    classes={{ root: classes.processInfoIconContainer }}
                                    workInstructionsItem={workInstructionsItem}
                                />
                            ) : null}
                            {!followUp && item.quantity > 0 && userCanInsertTraceability && (
                                <IconButton
                                    id={`woBomTableItemRowTraceabilityMore${item.partNumber}Id`}
                                    onClick={handleMoreClick("non-traceable")}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </div>
                    </TableCell>
                )}
                <TableCell className={classes.tableCell}>
                    {/* HISTORY BUTTON */}
                    {followUp ? (
                        <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("bomPage.bomTable.history")}>
                            <IconButton
                                aria-label="expand row"
                                onClick={handleOpenHistory}
                                id={`woBomTableItemRowExpendHistory${item.partNumber}${id}Id`}
                            >
                                {openHistory ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </TableCell>
                {/* OPTION MENU */}
                {isItemTraceable && followUp && userCanInsertTraceability ? (
                    <TraceabilityMenu
                        followUp={followUp}
                        menuPosition={menuPosition}
                        onDialogClose={handleDialogClose}
                        onMenuClose={handleMenuClose}
                        serializedItem={serializedItemSelected}
                        type={menuType}
                        variant="bom"
                        treeNodeId={workInstructionsItem?.treeNode.treeNodeId}
                        jobRefId={woBom.jobRefId}
                        isQuantityDecimal={item.isQuantityDecimal}
                    />
                ) : null}
                {!isItemTraceable && item.quantity > 0 ? (
                    <NonTraceableItemMenu
                        item={item}
                        menuPosition={menuPosition}
                        onDialogClose={handleDialogClose}
                        onMenuClose={handleMenuClose}
                        variant="bom"
                        woBom={woBom}
                    />
                ) : null}
            </TableRow>
            {followUp ? <WoBomTableItemHistoryRow bomFollowUp={followUp} itemId={id} open={openHistory} /> : null}
        </>
    );
};

export default WoBomTableItemRow;
