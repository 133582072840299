import {
    EnumProcessStatus,
    IOperatorJobProcessInfo,
    IProcessUiModel,
    IWoBomTracked,
    OrUndefined,
    ProcessActionStepWorkInstructions,
    ProcessId,
    WoBomItems,
} from "@kortex/aos-common";
import { useSnackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { initialState } from "@kortex/aos-ui/redux/player-manager/player-reducer";
import {
    processPlayerBomFollowUpClearBoms,
    processPlayerBomFollowUpGetWoBom,
} from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import { IPlayerState } from "@kortex/aos-ui/redux/player-manager/player-types";
import { useSelectorBoms, useSelectorPlayer, useSelectorProcesses } from "@kortex/aos-ui/redux/selectors";
import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

import { IPlayerContextMock } from "./playerContextMock";

interface IPlayerContext {
    bomStepItems: WoBomItems;
    jobProcessInfo: OrUndefined<IOperatorJobProcessInfo>;
    loadingBomFollowUp: boolean;
    loadWoBom: () => Promise<void>;
    mock: IPlayerContextMock;
    playerState: IPlayerState;
    process: IProcessUiModel | null;
    woBom: IWoBomTracked | undefined;
}

export const PlayerContext = createContext<IPlayerContext>({
    bomStepItems: {},
    jobProcessInfo: undefined,
    playerState: initialState,
    process: null,
    loadingBomFollowUp: false,
    loadWoBom: async () => void 0,
    mock: {
        set: () => void 0,
    },
    woBom: {
        items: {},
        jobRefId: "",
        trackingId: "",
    },
});

type PlayerProviderProps = PropsWithChildren<{
    processId: ProcessId;
    jobProcessInfo?: IOperatorJobProcessInfo;
}>;

export const PlayerProvider: FC<PlayerProviderProps> = (props) => {
    const { children, processId, jobProcessInfo } = props;

    const dispatch = useThunkDispatch();

    const playerState = useSelectorPlayer();
    const process: IProcessUiModel | undefined = useSelectorProcesses((p) => p.processId === processId)[0];
    const snackbar = useSnackbar();
    const translate = useTranslate();
    const woBom: IWoBomTracked | undefined = useSelectorBoms()[0]?.woBom;

    const [loadingBomFollowUp, setLoadingBomFollowUp] = useState<boolean>(false);
    const [mock, setMock] = useState<Omit<IPlayerContextMock, "set">>({});

    // Reset BOM when process is completed
    useEffect(() => {
        if (playerState.processState.status === EnumProcessStatus.COMPLETED || playerState.processState.isCompleted) {
            dispatch(processPlayerBomFollowUpClearBoms());
        }
    }, [playerState.processState.status, playerState.processState.isCompleted]);

    /**
     * Get the items' follow-up when a serial number is received
     */
    useEffect(() => {
        loadWoBom();
    }, [playerState.processState.serialNumber]);

    /**
     * Get the BOM items for current step
     */
    const getStepBomItems = (): WoBomItems => {
        // Initialize an object to store BOM items for this step
        const items: WoBomItems = {};

        // Check if the BOM is valid and if the current action is a work instruction
        if (!woBom || playerState.uiActionProps?.baseProps.type !== "core-work-instructions") return items;

        // Iterate through the BOM items for this step
        const step = playerState.uiActionProps.stepProps as ProcessActionStepWorkInstructions;

        if (woBom.items && Object.keys(woBom.items).length > 0) {
            for (const item of Object.values(step.config.bomItems)) {
                // Validate if the item is in the BOM
                if (woBom.items[item.partNumber]) {
                    // Create an entry in bomByStep with updated follow-up information
                    items[item.partNumber] = {
                        ...woBom.items[item.partNumber],
                        quantity: item.quantity,
                        serialized: Boolean(item.serialized),
                        singleSerialNumber: Boolean(item.singleSerialNumber),
                    };
                }
            }
        }

        return items;
    };

    const loadWoBom = async (): Promise<void> => {
        if (
            playerState.processState.serialNumber.length > 0 &&
            playerState.processState.bom.enabled &&
            (jobProcessInfo?.job.jobRefId || mock.jobRefId)
        ) {
            setLoadingBomFollowUp(true);

            await dispatch(
                processPlayerBomFollowUpGetWoBom(
                    (jobProcessInfo?.job.jobRefId ?? mock.jobRefId) as string,
                    playerState.processState.serialNumber
                )
            )
                .catch(() => {
                    snackbar.error(translate("player.bom.errorLoadingBomFollowUp"));
                })
                .finally(() => setLoadingBomFollowUp(false));
        }
    };

    const setMockProp: IPlayerContextMock["set"] = (key, value) => {
        setMock((prevState) => ((prevState[key] = value), prevState));
    };

    return (
        <PlayerContext.Provider
            value={{
                bomStepItems: getStepBomItems(),
                jobProcessInfo,
                loadingBomFollowUp,
                loadWoBom,
                mock: { ...mock, set: setMockProp },
                playerState,
                process,
                woBom,
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
};

export const usePlayerContext = (): IPlayerContext => useContext<IPlayerContext>(PlayerContext);
