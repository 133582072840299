import { IJobUiModel, JobRefId } from "@kortex/aos-common";
import { useLocationQuery } from "@kortex/aos-ui/utilitites/useLocationQuery";
import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";

interface IFilters {
    jobRefId?: JobRefId;
}

// Context for selected job
interface ISchedulerContext {
    queryParams: IFilters;
    selectedJob: IJobUiModel | undefined;
    setSelectedJob: React.Dispatch<React.SetStateAction<IJobUiModel | undefined>>;
}

const SchedulerContext = createContext<ISchedulerContext>({
    queryParams: {},
    selectedJob: undefined,
    setSelectedJob: () => void 0,
});

type SchedulerProviderProps = PropsWithChildren<{}>;

export const SchedulerProvider: FC<SchedulerProviderProps> = (props) => {
    const { children } = props;

    const query = useLocationQuery();

    const [selectedJob, setSelectedJob] = useState<IJobUiModel | undefined>(undefined);

    // FIXME: We could move all filters from SchedulerSearchBar to here when we refactor the Scheduler page.
    //        If so, we might need to change the useRef to useState.
    const queryParams = useRef<IFilters>({});

    /**
     * Wait until the page URL query is changed. When it does, update inner state.
     */
    useEffect(() => {
        const jobRefId: JobRefId | null = query.get("jobRefId");
        queryParams.current = jobRefId ? { jobRefId } : {};
    }, [query]);

    return (
        <SchedulerContext.Provider
            value={{
                queryParams: queryParams.current,
                selectedJob,
                setSelectedJob,
            }}
        >
            {children}
        </SchedulerContext.Provider>
    );
};

export const useSchedulerContext = (): ISchedulerContext => useContext<ISchedulerContext>(SchedulerContext);
