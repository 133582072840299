import { KortexTextField, primaryPalette, secondaryPalette } from "@aos/react-components";
import { IUserDbModel, IUserGroupDbModel, JobStatusEnum } from "@kortex/aos-common";
import { Checkbox, FormControl, Input, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesUsersGroups } from "../../../../redux/effects";
import KortexSearchField from "../../../core/KortexSearchField";
import { getJobStatusLabelKey } from "../SchedulerData";
import { useSchedulerContext } from "../schedulerContext";

import DateTimeFromToFilters from "./DateTimeFromToFilters";

export interface IFilters {
    plainText: string;
    jobRefId: string;
    status: string[];
    planner: string[];
    group: string[];
    partNumber: string;
    trackingId: string;
    reference: string;
    requestedDateFrom: string;
    requestedDateTo: string;
    plannedDateFrom: string;
    plannedDateTo: string;
    scheduledDateFrom: string;
    scheduledDateTo: string;
    jobProcessScheduledDateFrom: string;
    jobProcessScheduledDateTo: string;
    activeJobProcessOnly: boolean;
}

// Used to indicate which filters to display
export interface IEnabledFilters {
    plainText?: boolean;
    jobRefId?: boolean;
    status?: JobStatusEnum[] | boolean;
    planner?: boolean;
    group?: boolean;
    partNumber?: boolean;
    trackingId: boolean;
    reference?: boolean;
    requestedOn?: boolean;
    plannedOn?: boolean;
    scheduledOn?: boolean;
    activeJobProcessOnly?: boolean;
}

export const defaultFilters: IFilters = {
    plainText: "",
    jobRefId: "",
    status: [],
    planner: [],
    group: [],
    partNumber: "",
    trackingId: "",
    reference: "",
    requestedDateFrom: "",
    requestedDateTo: "",
    plannedDateFrom: "",
    plannedDateTo: "",
    scheduledDateFrom: "",
    scheduledDateTo: "",
    jobProcessScheduledDateFrom: "",
    jobProcessScheduledDateTo: "",
    activeJobProcessOnly: false,
};

const defaultEnabledFilters: IEnabledFilters = {
    plainText: true,
    jobRefId: true,
    status: true,
    planner: true,
    group: true,
    partNumber: true,
    trackingId: false,
    reference: true,
    requestedOn: true,
    plannedOn: true,
    scheduledOn: true,
    activeJobProcessOnly: false,
};

const useStyles = makeStyles({
    searchBox: {
        width: "100%",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
            cursor: "pointer",
        },
    },
});

interface IOwnProps {
    plannersList?: IUserDbModel[];
    enabledFilters?: IEnabledFilters;
    onFiltersChange: (filters: IFilters) => void;
    initialFilters?: Partial<IFilters>;
}

export default function SchedulerSearchBar(props: IOwnProps): JSX.Element {
    const { onFiltersChange } = props;
    const enabledFilters = Object.assign({}, defaultEnabledFilters, props.enabledFilters);
    const initialFilters = Object.assign({}, defaultFilters, props.initialFilters);

    const classes = useStyles();
    const groups = useEntitiesUsersGroups();
    const translate = useTranslate();
    const { queryParams } = useSchedulerContext();

    const onFiltersChangeDelayMS = 750;

    const [filters, setFilters] = useState<IFilters>(initialFilters);

    const statusFilters = enabledFilters.status && enabledFilters.status === true ? Object.values(JobStatusEnum) : enabledFilters.status;

    useEffect(() => {
        const updatedFilters: IFilters = {
            ...filters,
            jobRefId: queryParams.jobRefId ?? "",
        };

        setFilters(updatedFilters);
        onFiltersChange(updatedFilters);
    }, [queryParams]);

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof IFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            setFilters({ ...filters, [key]: e.target.value });
        };

    /**
     * Handle called when the card header item is updated
     *
     * @param {keyof IFilters} key - key of that job that changed
     */
    const handleDateFilterChange =
        (key: keyof IFilters): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            setFilters({ ...filters, [key]: date ? String(date) : "" });
        };

    /**
     * Called when the Show Archived button is toggled
     */
    const handleActiveJobProcessOnly = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, activeJobProcessOnly: e.target.checked });
    };

    /**
     * Called when filters change. Checks if filters equal their initial value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key as keyof IFilters] !== initialFilters[key as keyof IFilters]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(initialFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        const updatedFilters = { ...filters, plainText: val };
        setFilters(updatedFilters);
        onFiltersChange(updatedFilters);
    };

    /**
     * Called when the text in the searchbar closes
     */
    const handleSearchTextClose = (): void => {
        onFiltersChange(filters);
    };

    return (
        <KortexSearchField
            className={classes.searchBox}
            onChange={handleSearchTextChange}
            onClose={handleSearchTextClose}
            isFilterActive={isFilterActive()}
            buttonText={translate("player.apply")}
            changedDelayMS={onFiltersChangeDelayMS}
        >
            <React.Fragment>
                {enabledFilters.jobRefId && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.jobRefId")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("jobRefId")}
                            value={filters.jobRefId}
                            variant={"standard"}
                        />
                    </div>
                )}
                {enabledFilters.status && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.status")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("status")}
                                value={filters.status}
                            >
                                {statusFilters &&
                                    statusFilters
                                        // filter out Archived elements since they are handled differently
                                        .filter((status): boolean => status !== JobStatusEnum.NONE && status !== JobStatusEnum.ERROR)
                                        .map((key: JobStatusEnum): JSX.Element => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {translate(getJobStatusLabelKey(key))}
                                                </MenuItem>
                                            );
                                        })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.group && groups.length > 0 && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.group")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("group")}
                                value={filters.group}
                            >
                                {groups.map((group: IUserGroupDbModel, index: number): JSX.Element => {
                                    return (
                                        <MenuItem key={index} value={group.name}>
                                            {group.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.partNumber && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.partNumber")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("partNumber")}
                            value={filters.partNumber}
                            variant={"standard"}
                        />
                    </div>
                )}
                {enabledFilters.trackingId && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.tracking")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("trackingId")}
                            value={filters.trackingId}
                            variant={"standard"}
                        />
                    </div>
                )}
                {enabledFilters.reference && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.referenceId")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("reference")}
                            value={filters.reference}
                            variant={"standard"}
                        />
                    </div>
                )}
                {enabledFilters.planner && props.plannersList && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.planner")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("planner")}
                                value={filters.planner}
                            >
                                {props.plannersList.map((planner: IUserDbModel, index: number): JSX.Element => {
                                    return (
                                        <MenuItem key={index} value={planner.userName}>
                                            {planner.userName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.requestedOn && (
                    <DateTimeFromToFilters
                        filterLabel={"scheduler.requestedOn"}
                        ValueFrom={filters.requestedDateFrom}
                        ValueTo={filters.requestedDateTo}
                        onChangeFrom={handleDateFilterChange("requestedDateFrom")}
                        onChangeTo={handleDateFilterChange("requestedDateTo")}
                    />
                )}
                {enabledFilters.plannedOn && (
                    <DateTimeFromToFilters
                        filterLabel={"scheduler.plannedOn"}
                        ValueFrom={filters.plannedDateFrom}
                        ValueTo={filters.plannedDateTo}
                        onChangeFrom={handleDateFilterChange("plannedDateFrom")}
                        onChangeTo={handleDateFilterChange("plannedDateTo")}
                    />
                )}
                {enabledFilters.scheduledOn && (
                    <DateTimeFromToFilters
                        filterLabel={"scheduler.jobProcessScheduledOn"}
                        ValueFrom={filters.jobProcessScheduledDateFrom}
                        ValueTo={filters.jobProcessScheduledDateTo}
                        onChangeFrom={handleDateFilterChange("jobProcessScheduledDateFrom")}
                        onChangeTo={handleDateFilterChange("jobProcessScheduledDateTo")}
                    />
                )}
                {enabledFilters.activeJobProcessOnly && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.optionActiveJobProcessOnly")}</Typography>
                        <Checkbox checked={filters.activeJobProcessOnly} onChange={handleActiveJobProcessOnly} />
                    </div>
                )}
                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("scheduler.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}
