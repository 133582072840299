import { getActionsBoundingBox, IProcessActionDbModel, ProcessUiModel } from "@kortex/aos-common";
import { Box, Typography } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { normalizeSVGDimension } from "../../../utilitites/normalizeSVGDimension";
import ActionBlock from "../ProcessEditor/ProcessEditor/ActionBlocksAndLinks/ActionBlock/ActionBlock";
import ActionLink from "../ProcessEditor/ProcessEditor/ActionBlocksAndLinks/ActionLink/ActionLink";

import { IPage, PrintBasePage } from "./PrintBasePage";
import PrintDraftWatermark from "./PrintDraftWaterMark";
import { pageHeight, pageRatio, pageWidth } from "./printUtils";

export interface IPrintProcessFlowProps extends IPage {
    process: ProcessUiModel;
}

export default function PrintProcessFlow(props: IPrintProcessFlowProps): JSX.Element {
    const { process, ...pageInfo } = props;

    const translate = useTranslate();

    return (
        <PrintBasePage {...pageInfo}>
            <g>
                <TestProcess process={process} />
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <Box marginTop={2}>
                            <Typography variant="h1" color="primary" align="center" display="block">
                                {translate("process.printing.processFlow")}
                            </Typography>
                        </Box>
                        <PrintDraftWatermark isDraft={process.isDraft} />
                    </div>
                </foreignObject>
            </g>
        </PrintBasePage>
    );
}

// ****TEST****
function TestProcess({ process }: { process: ProcessUiModel }): JSX.Element {
    const boundingBox = getActionsBoundingBox(process.actions as IProcessActionDbModel[]);
    let viewBox = "0 0 0 0";

    if (boundingBox.width / pageRatio > boundingBox.height) {
        const vertCenter = boundingBox.y1 - boundingBox.width / pageRatio / 2 + boundingBox.height / 2;
        viewBox = `${boundingBox.x1} ${vertCenter} ${boundingBox.width} ${boundingBox.width / pageRatio}`;
    } else {
        const horizCenter = boundingBox.x1 - (boundingBox.height * pageRatio) / 2 + boundingBox.width / 2;
        viewBox = `${horizCenter} ${boundingBox.y1} ${boundingBox.height * pageRatio} ${boundingBox.height}`;
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            y="40"
            x="50"
            width={normalizeSVGDimension(pageWidth / 1.1)}
            height={normalizeSVGDimension(pageHeight / 1.1)}
            viewBox={viewBox}
        >
            <defs>
                <filter id="f1" x="-300" y="-100" width="600" height="300" filterUnits="userSpaceOnUse" primitiveUnits="userSpaceOnUse">
                    <feComposite in2="SourceAlpha" operator="in" />
                    <feGaussianBlur stdDeviation="6" />
                    <feOffset dx="1" dy="3" result="afterOffset" />
                    <feFlood floodColor="black" floodOpacity="0.3" />
                    <feComposite in2="afterOffset" operator="in" />
                    <feMorphology operator="dilate" radius="1" />
                    <feComposite in2="SourceAlpha" operator="out" />
                </filter>
                <g id="actionNode">
                    <circle cx="0" cy="0" r="6.5" stroke="white" strokeWidth="1" fill="currentColor" />
                </g>
            </defs>
            {process.actions.map(
                (action: IProcessActionDbModel, index: number): JSX.Element => (
                    <ActionBlock actionProps={action} hideEditButton={true} key={index} />
                )
            )}
            <ActionLink actionsProps={process.actions.map((actionItem): IProcessActionDbModel => actionItem)} />
        </svg>
    );
}
