import { theme } from "@aos/react-components";
import { IconButton, TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";

import { useWorkInstructionBomContext } from "../../context";

const useStyles = makeStyles({
    content: {
        alignItems: "center",
        display: "flex",
    },
    sectionRight: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    tableRow: {
        backgroundColor: theme.palette.grey[200],
    },
    typographyTitle: {
        flex: 1,
        paddingLeft: "4px",
    },
});

interface IOwnProps {
    displayStepItems: boolean;
    expanded: boolean;
    onToggleExpand: () => void;
    title: string;
}

function WorkInstructionsBomItemsHeader(props: IOwnProps): JSX.Element {
    const { displayStepItems, expanded, onToggleExpand, title } = props;

    const classes = useStyles();
    const { bom } = useWorkInstructionBomContext();

    return (
        <TableRow className={classes.tableRow}>
            <TableCell colSpan={2}>
                <div className={classes.content}>
                    <IconButton aria-label="expand row" size="small" onClick={onToggleExpand} id="expandIconId">
                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography className={classes.typographyTitle} variant="h6">
                        {title}
                    </Typography>
                    {!displayStepItems && (
                        <div className={classes.sectionRight}>
                            <Typography variant="body2" id="bomIdAndRevision">
                                {`${bom?.bomId} (${bom?.revision})`}
                            </Typography>
                        </div>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomItemsHeader;
