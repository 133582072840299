import { secondaryPalette, theme } from "@aos/react-components";
import {
    areSimilar,
    IGetAllJobsRes,
    IJobsFiltersOptions,
    IJobUiModel,
    ITokenDecoded,
    IUserDbModel,
    JobStatusEnum,
} from "@kortex/aos-common";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorErpSettings } from "@kortex/aos-ui/redux/selectors";
import { deepClone } from "@kortex/utilities";
import { Backdrop, CircularProgress, IconButton, makeStyles, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer"; // TODO: Replace InView by useInView

import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesJobs, useEntitiesUsersGroups } from "../../../../redux/effects";
import {
    getFromErpAndCreateJobs,
    jobArchiveAllCompleted,
    jobGetAllJob,
    jobUpdateStatus,
} from "../../../../redux/scheduler-manager/scheduler-thunks-job";
import { userCanArchive } from "../../../../utilitites/IUserRights";
import { useSchedulerContext } from "../schedulerContext";
import { applyFilters, convertUiFiltersToBackendFilters, verifyIfFiltersAreEmpty } from "../utilities";

import AddJobProcessRouting from "./AddJobProcessRouting";
import JobCardContainer from "./JobCardContainer";
import SchedulerSearchBar, { defaultFilters, IFilters } from "./SchedulerSearchBar";

const useStyles = makeStyles({
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
    },
    moreIcon: {
        height: "48px",
        margin: "5px 0 5px",
    },
    container: {
        width: "100%",
        position: "relative",
    },
    searchContainer: {
        justifyItems: "center",
        marginBottom: "10px",
        padding: "10px",
        display: "flex",
    },
    jobListContainer: {
        height: "calc(100vh - 184px)", // header (64px), page paddings (32px), search bar height (58px) and bottom margin (10px) and padding (20px)
        overflowY: "auto",
    },
    containerListFeedback: {
        width: "100%",
        height: "150px",
        marginTop: "10px",
    },
    loadingUserFeedback: {
        color: secondaryPalette[500],
        textAlign: "center",
    },
    intersectionObserver: {
        width: "100%",
    },
});

interface IOwnProps {
    onJobsLoaded?: () => void;
    onJobsLoading?: () => void;
    plannersList: IUserDbModel[];
    userInfo?: ITokenDecoded;
}

const JOB_REQUEST_LIMIT = 20;
const NUMBER_OF_ITEM_BEFORE_NEXT_FETCH = JOB_REQUEST_LIMIT / 4;

export default function SchedulerJobListAndSearch(props: IOwnProps): JSX.Element {
    const { plannersList, onJobsLoaded, userInfo } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const groupList = useEntitiesUsersGroups();
    const jobListFromStore = useEntitiesJobs((value) => downloadedEndCallback && downloadedEndCallback(value), JOB_REQUEST_LIMIT, 0, {});
    const translate = useTranslate();
    const erpEnabled = useSelectorErpSettings()?.erpEnabled;
    const { selectedJob, setSelectedJob } = useSchedulerContext();

    const [jobList, setJobList] = useState<IJobUiModel[]>(jobListFromStore);
    const [filters, setFilters] = useState<IFilters>(defaultFilters);
    const [newJobAdded, setNewJobAdded] = useState<boolean>(false);
    const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
    const [hasNext, setHasNext] = useState<boolean>(true);
    const [fetchOffset, setFetchOffset] = useState<number>(0);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    /**
     * Key down event - Arrow down
     * Select the next job
     */
    useKeybind(
        "ArrowDown",
        () => {
            if (selectedJob) {
                const index = jobList.findIndex((job) => job.jobId === selectedJob.jobId);

                if (index >= 0 && index < jobList.length) {
                    setSelectedJob(jobList[index + 1]);
                }
            } else {
                setSelectedJob(jobList[0]);
            }
        },
        {
            preventDefault: true,
            stopPropagation: true,
        }
    );

    /**
     * Key down event - Arrow up
     * Select the previous job
     */
    useKeybind(
        "ArrowUp",
        () => {
            if (selectedJob) {
                const index = jobList.findIndex((job) => job.jobId === selectedJob.jobId);

                if (index > 0) {
                    setSelectedJob(jobList[index - 1]);
                }
            }
        },
        {
            preventDefault: true,
            stopPropagation: true,
        }
    );

    /*
     * When jobListFromStore props changed from the external
     */
    useEffect((): void => {
        const orderedAndFilteredJobList = getJobsSortedByOrderIdAndFiltered(jobListFromStore);
        setJobList(orderedAndFilteredJobList);

        if (newJobAdded) {
            setSelectedJob(orderedAndFilteredJobList[0]);
            setNewJobAdded(false);
            setFetchOffset((prevState) => prevState + 1);
        } else if (selectedJob) {
            setSelectedJob(jobListFromStore.find((job): boolean => job.jobId === selectedJob.jobId));
        }
    }, [jobListFromStore]);

    /**
     * Get job list sorted by order ID
     */
    const getJobsSortedByOrderId = (jobList: IJobUiModel[]): IJobUiModel[] => {
        return jobList.sort((first, second) => second.orderId - first.orderId);
    };

    const getJobsSortedByOrderIdAndFiltered = (jobList: IJobUiModel[]): IJobUiModel[] => {
        return getJobsSortedByOrderId(jobList.filter((job) => applyFilters(job, filters, plannersList, groupList)));
    };

    const getFilteredJobsFromBackEnd = (newFilters: IFilters): void => {
        // Restart at 0 Offset the query
        setHasNext(true);
        getJobsListPagination(JOB_REQUEST_LIMIT, 0, convertUiFiltersToBackendFilters(newFilters, plannersList, groupList));
    };

    const fetchNextJobsList = (): void => {
        setDataIsLoading(true);
        getJobsListPagination(JOB_REQUEST_LIMIT, fetchOffset, convertUiFiltersToBackendFilters(filters, plannersList, groupList));
    };

    const getJobsListPagination = (JOB_REQUEST_LIMIT: number, jobRequestOffset: number, filterOptions: IJobsFiltersOptions): void => {
        dispatch(
            jobGetAllJob({ redux: { skipLookup: true, skipDispatch: false } }, JOB_REQUEST_LIMIT, jobRequestOffset, filterOptions)
        ).then((value) => downloadedEndCallback && downloadedEndCallback(value));
    };

    const downloadedEndCallback = (value: Readonly<IGetAllJobsRes>): void => {
        // Compute has next?
        setHasNext((value.chunkSize as number) >= JOB_REQUEST_LIMIT);
        setFetchOffset(value.nextCursor as number);

        // Verify is there is no value returned
        if (value.chunkSize === 0 && value.prevCursor === 0) {
            setJobList([]);
        }

        // Verify is there is only one value returned. If so auto-select it.
        if (value.chunkSize === 1 && value.prevCursor === 0) {
            setSelectedJob(value.data[0]);
        }

        onJobsLoaded && onJobsLoaded();
        setDataIsLoading(false);
    };

    /**
     * Handle archive/unarchive of the job
     *
     * @param {IJobUiModel} job - job to be archive
     */
    const handleArchiveCard = (job: IJobUiModel): void => {
        const archiveSelectedJob = deepClone(job);

        if (archiveSelectedJob.status === JobStatusEnum.DONE) {
            dispatch(jobUpdateStatus({ jobId: archiveSelectedJob.jobId, status: JobStatusEnum.ARCHIVED }));
        } else if (archiveSelectedJob.status === JobStatusEnum.ARCHIVED) {
            dispatch(jobUpdateStatus({ jobId: archiveSelectedJob.jobId, status: JobStatusEnum.DONE }));
        }

        setSelectedJob(undefined);
        setFetchOffset((prevState) => prevState - 1);
    };

    const handleOnNewJobAdded = (): void => {
        setNewJobAdded(true);
    };

    const handleMenuItemClick =
        (cb: () => void): (() => void) =>
        (): void => {
            setMenuAnchorEl(null);
            cb();
        };

    const handleArchiveAllCompletedJobs = (): void => {
        setLoading(true);
        dispatch(jobArchiveAllCompleted()).finally(() => {
            setLoading(false);
        });
    };

    const handleGetJobsFromErp = (): void => {
        setLoading(true);
        dispatch(getFromErpAndCreateJobs()).finally(() => {
            setLoading(false);
        });
    };

    const handleMoreButtonOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMoreButtonClose = (): void => {
        setMenuAnchorEl(null);
    };

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IFilters} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IFilters): void => {
        // verify if filters has changed
        if (areSimilar(filters, newFilters)) return;

        setDataIsLoading(true);
        setJobList([]);
        getFilteredJobsFromBackEnd(newFilters);
        setFilters(newFilters);
    };

    /**
     * Display "No job found if filters are used. Otherwise, return "Add new Job."
     *
     * @returns {JSX.Element} - formatted message to display
     */
    const displayListFeedback = (): JSX.Element => {
        return (
            <div className={classes.containerListFeedback}>
                <Typography className={classes.loadingUserFeedback} variant="body1">
                    {dataIsLoading && (
                        <>
                            {translate("scheduler.LoadingJobs")}
                            <br></br>
                            <CircularProgress color="secondary" />
                        </>
                    )}
                    {!dataIsLoading &&
                        jobList.length == 0 &&
                        translate(verifyIfFiltersAreEmpty(filters) ? "scheduler.AddNewJobSuggestion" : "scheduler.NoJobFound")}
                    {!dataIsLoading &&
                        jobList.length > JOB_REQUEST_LIMIT &&
                        translate(hasNext ? "scheduler.ScrollToLoadMoreItems" : "scheduler.NoMoreJobs")}
                </Typography>
            </div>
        );
    };

    const handleIntersectionObserverOnChange = (inView: boolean): void => {
        if (!inView) {
            return;
        }
        if (jobList.length > 0) {
            fetchNextJobsList();
        }
    };

    const observerJobId =
        jobList && jobList.length > NUMBER_OF_ITEM_BEFORE_NEXT_FETCH
            ? jobList[jobList.length - NUMBER_OF_ITEM_BEFORE_NEXT_FETCH - 1].jobId
            : undefined;

    return (
        <div className={classes.container}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* SEARCH */}
            <Paper className={classes.searchContainer}>
                <SchedulerSearchBar onFiltersChange={handleFiltersChange} plannersList={plannersList} />
                <IconButton className={classes.moreIcon} id="schedulerJobListAndSearchMoreIcon" onClick={handleMoreButtonOpen}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={menuAnchorEl}
                    id="schedulerJobListAndSearchMenu"
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMoreButtonClose}
                >
                    <MenuItem
                        id="schedulerJobListAndSearchMenuArchiveAllCompletedJobId"
                        onClick={handleMenuItemClick(handleArchiveAllCompletedJobs)}
                    >
                        {translate("scheduler.archiveAllCompletedJobs")}
                    </MenuItem>
                    {erpEnabled && (
                        <MenuItem id="schedulerJobListAndSearchMenuGetJobsFromErpId" onClick={handleMenuItemClick(handleGetJobsFromErp)}>
                            {translate("scheduler.getJobsFromErp")}
                        </MenuItem>
                    )}
                </Menu>
            </Paper>
            <Paper className={classes.jobListContainer}>
                {/* JOB LIST */}
                {jobList.length > 0 &&
                    jobList.map(
                        (job, index): JSX.Element => (
                            <div key={job.jobId}>
                                <JobCardContainer
                                    drapAndDropIndex={index}
                                    job={job}
                                    isSelected={selectedJob?.jobId === job.jobId}
                                    canUserArchive={userCanArchive(userInfo?.roleRights.scheduler)}
                                    onArchiveCard={handleArchiveCard}
                                />
                                {job.jobId === observerJobId && !dataIsLoading && hasNext && (
                                    <InView
                                        as="div"
                                        onChange={handleIntersectionObserverOnChange}
                                        className={classes.intersectionObserver}
                                    />
                                )}
                            </div>
                        )
                    )}
                {displayListFeedback()}
            </Paper>
            {/* Add Job, Process and Routing feature */}
            <AddJobProcessRouting userInfo={userInfo} onNewJobAdded={handleOnNewJobAdded} />
        </div>
    );
}
