export const TITLE_FR = {
    "titleKeyError.approvalProcessVersion": "Approbation de version de processus.",
    "titleKeyError.approvalRoutingVersion": "Approbation de version de routage.",
    "titleKeyError.assertProcessVersion": "Confirmation de la version du processus.",
    "titleKeyError.cannotCopyEmptyPayload": "La charge utile est vide.",
    "titleKeyError.configError": "Erreur de configuration.",
    "titleKeyError.configurationOrganizationSettings": "Configuration des paramètres d'organisation.",
    "titleKeyError.databaseCommunicationError": "Erreur de communication de base de données.",
    "titleKeyError.erpError": "Erreur ERP.",
    "titleKeyError.executionError": "Erreur d'exécution.",
    "titleKeyError.holdProcessVersion": "Impossible de suspendre la version du processus.",
    "titleKeyError.invalidElectronicSignature": "Signature électronique invalide.",
    "titleKeyError.invalidTraceability": "Traçabilité invalide.",
    "titleKeyError.invalidUser": "Utilisateur invalide.",
    "titleKeyError.invalidVariableIdentifier": "Identifiant de variable invalide.",
    "titleKeyError.jobProcessQtyMaxReach": "La quantité maximale d'items pour le processus du job est dépassée.",
    "titleKeyError.jobQtyMaxReach": "La quantité maximale d'items du job est dépassée.",
    "titleKeyError.noProcessVersionAvailable": "Aucune version de processus disponible.",
    "titleKeyError.noRunnerAvailable": "Système d'exécution de processus non disponible.",
    "titleKeyError.processIsDraft": "La version de processus est un brouillon.",
    "titleKeyError.requestFailedRunner": "Erreur de communication avec le système d'exécution de processus.",
    "titleKeyError.unableToPerformYourAction": "Action impossible.",
    "titleKeyError.unableCancelProcessVersion": "Impossible d'annuler la version du processus.",
    "titleKeyError.unableCopyProcessActions": "Impossible de copier les actions du processus.",
    "titleKeyError.unableCreateNewJob": "Impossible de créer une nouveau bon de commande.",
    "titleKeyError.unableCreateNewColumn": "Impossible de créer une nouvelle colonne.",
    "titleKeyError.unableCreateNewTraining": "Impossible de créer une nouvelle formation.",
    "titleKeyError.unableCreateProcessVersion": "Impossible de créer une version.",
    "titleKeyError.unableCreateReworkJobAndNewFailureTicket": "L'exécution du processus n'a pu créer un job de réparation et un nouveau billet d'échec.",
    "titleKeyError.unableCreateTrainingCertificate": "Impossible de créer l'attestation de formation.",
    "titleKeyError.unableSignTrainingCertificate": "Impossible de signer l'attestation de formation.",
    "titleKeyError.unableCreateTrainingReadingCertificate": "Impossible de créer l'attestation de lecture.",
    "titleKeyError.unableGetErpSettings": "Impossible d'obtenir la configuration ERP.",
    "titleKeyError.unableCreateTrainingCertification": "Impossible de créer la certification de formation.",
    "titleKeyError.unableSignTrainingCertification": "Impossible de signer la certification de formation.",
    "titleKeyError.unableHoldProcessVersion": "Impossible de suspendre la version du processus.",
    "titleKeyError.unableInsertRouting": "Impossible d'insérer le routage.",
    "titleKeyError.unableInsertUser": "Impossible d'insérer l'utilisateur.",
    "titleKeyError.unableInsertUserRole": "Impossible d'insérer le rôle.",
    "titleKeyError.unableInsertProcessFile": "Impossible d'insérer le processus.",
    "titleKeyError.unableUpdateJob": "Impossible de mettre à jour le job.",
    "titleKeyError.unableUpdateProcess": "Impossible de mettre à jour le processus.",
    "titleKeyError.unableUpdateRouting": "Impossible de mettre à jour le routage.",
    "titleKeyError.unableUpdatePassword": "Impossible de mettre à jour le mot de passe.",
    "titleKeyError.unableInsertTreeFile": "Impossible d'insérer le fichier.",
    "titleKeyError.unableUpdateTreeFile": "Impossible de mettre à jour le fichier.",
    "titleKeyError.unableInsertLog": "Impossible d'insérer le journal de travail.",
    "titleKeyError.unableUpdateUserRole": "Impossible de mettre à jour le rôle.",
    "titleKeyError.unableDeleteUserRole": "Impossible de supprimer le rôle.",
    "titleKeyError.unableUserConnection": "Connexion utilisateur impossible.",
    "titleKeyError.unhandledError": "Erreur non gérée.",
    "titleKeyError.unknownError": "Erreur inconnue.",
    "titleKeyError.accessUnauthorized": "Accès non autorisé.",
};
