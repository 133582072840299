import { theme } from "@aos/react-components";
import { AccessLevelEnum, IUserDbModel, IUserRoleDbModel } from "@kortex/aos-common";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { useEntitiesUsers, useEntitiesUsersRoles } from "../../../redux/effects";
import { useSelectorUserSession } from "../../../redux/selectors";

import SchedulerJobEditor from "./SchedulerJobEditor/SchedulerJobEditor";
import SchedulerJobListAndSearch from "./SchedulerJobListAndSearch/SchedulerJobListAndSearch";
import { SchedulerProvider } from "./schedulerContext";

const useStyles = makeStyles({
    root: {
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "33% 1fr",
        columnGap: "12px",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 100,
    },
});

export default function SchedulerPage(): JSX.Element {
    const classes = useStyles();

    const roles = useEntitiesUsersRoles();
    const users = useEntitiesUsers();
    const userInfo = useSelectorUserSession();

    const [plannersList, setPlannersList] = useState<IUserDbModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect((): void => {
        computePlannerList();
    }, [roles, users]);

    /**
     * Handle that jobs have been loaded, used to stop the spinner.
     */
    const handleJobsLoaded = (): void => {
        setIsLoading(false);
    };

    /**
     * Handle that jobs is now loading
     */
    const handleJobsLoading = (): void => {
        setIsLoading(true);
    };

    /**
     * Build up planner list based on users list and users role list
     */
    const computePlannerList = async (): Promise<void> => {
        // Find which roles have scheduler WRITE +
        const rolesWithSchedulerWrite = roles.reduce((accumulator: IUserRoleDbModel[], role: IUserRoleDbModel): IUserRoleDbModel[] => {
            if (role.scheduler >= AccessLevelEnum.WRITE) {
                accumulator.push(role);
            }
            return accumulator;
        }, []);

        setPlannersList(
            users
                // Find which users have the selected roles
                .reduce((accumulator: IUserDbModel[], user: IUserDbModel): IUserDbModel[] => {
                    for (const role of rolesWithSchedulerWrite) {
                        for (const userRole of user.roles) {
                            if (userRole === role.userRoleId) {
                                accumulator.push(user);
                            }
                        }
                    }
                    return accumulator;
                }, [])
                // Filter unique
                .filter((value, index, self) => self.indexOf(value) === index)
                // Sort alphabetically by userName
                .sort((a, b) => (a.userName < b.userName ? -1 : 1))
        );
    };

    return (
        <>
            {isLoading && (
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <div className={classes.root} id="SchedulerPageId">
                <SchedulerProvider>
                    {/* LEFT PANEL JOB LIST */}
                    <SchedulerJobListAndSearch
                        onJobsLoaded={handleJobsLoaded}
                        onJobsLoading={handleJobsLoading}
                        plannersList={plannersList}
                        userInfo={userInfo}
                    />

                    {/* RIGHT PANEL JOB DETAILS & PROCESSES */}
                    <SchedulerJobEditor plannersList={plannersList} userInfo={userInfo} />
                </SchedulerProvider>
            </div>
        </>
    );
}
