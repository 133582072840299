// common
import { IJobProcessUiModel, IJobUiModel, IProcessUiModel, IReworkUiModel, ITokenDecoded, JobStatusEnum } from "@kortex/aos-common";
// react
import * as React from "react";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "react-sortable-hoc";
// redux
import { secondaryPalette } from "@aos/react-components";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Typography } from "@material-ui/core";

import { useEntitiesUsersGroups } from "../../../../redux/effects";
import { jobGetAllProcess, jobJobProcessUpdate } from "../../../../redux/scheduler-manager/scheduler-thunks-job";
import { userCanInsert } from "../../../../utilitites/IUserRights";
import { useSchedulerContext } from "../schedulerContext";

import SchedulerJobProcessCard from "./SchedulerJobProcessCard";
import SchedulerJobRoutingCard from "./SchedulerJobRoutingCard";

const useStyles = makeStyles({
    noProcessOrRoutingAvailable: {
        color: secondaryPalette[500],
        textAlign: "center",
    },
});

interface IOwnProps {
    job?: IJobUiModel;
    userInfo?: ITokenDecoded;
    failureTicket?: boolean;
    rework?: IReworkUiModel;
    selectedJobRoutingIndex?: number;
    onProcessSelected?: (process: IJobProcessUiModel) => void;
    onOrderIdSelected?: (orderId: number) => void;
    onRoutingSelected?: (routing: number) => void;
    onJobsLoaded?: () => void;
    setSelectedJobRoutingIndex?: (index: number | undefined) => void;
}

export default function JobProcessCardContainer(props: IOwnProps): JSX.Element {
    const {
        userInfo,
        failureTicket = false,
        selectedJobRoutingIndex,
        setSelectedJobRoutingIndex,
        rework,
        onProcessSelected,
        onOrderIdSelected,
        onRoutingSelected,
        onJobsLoaded,
    } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const groupsList = useEntitiesUsersGroups();
    const { selectedJob } = useSchedulerContext();

    const [jobId, setJobId] = useState<number | undefined>(undefined);
    const [jobProcessList, setJobProcessList] = useState<IJobProcessUiModel[] | undefined>(undefined);
    const [jobProcessListNotRework, setJobProcessListNotRework] = useState<IJobProcessUiModel[] | undefined>(undefined);
    const [selectedJobRoutingId, setSelectedJobRoutingId] = useState<number | undefined>(undefined);
    const [selectedJobProcess, setSelectedJobProcess] = useState<IJobProcessUiModel | undefined>(undefined);
    const [isDragDisabled, setIsDragDisabled] = useState<boolean>(true);
    const [processes, setProcesses] = useState<IProcessUiModel[]>([]);

    const job = props.job ?? selectedJob;

    // Render nothing if no job is selected
    if (!job) {
        return <></>;
    }

    /**
     * Effect triggered when job is updated.
     */
    useEffect((): void => {
        // set job process list.
        if (!job) {
            setJobId(undefined);
            setSelectedJobProcess(undefined);
            setJobProcessList(undefined);
        } else {
            if (job.jobId !== jobId) {
                setJobId(job.jobId);
                setJobProcessListNotRework(job.processList.filter((processNotRework) => processNotRework.reworkId === 0));
                setSelectedJobProcess(undefined);
            }
            if (failureTicket) {
                setJobProcessList(job.processList.filter((processRework) => processRework.reworkId === rework?.reworkId));
            } else {
                setJobProcessList(job.processList);
            }
        }
        // job process is dragDisabled

        if (!failureTicket) {
            setIsDragDisabled(!(userCanInsert(userInfo?.roleRights.scheduler) && job.status === JobStatusEnum.DRAFT));
        } else {
            setIsDragDisabled(
                !(userCanInsert(userInfo?.roleRights.rework) && job.status !== JobStatusEnum.DONE && job.status !== JobStatusEnum.ARCHIVED)
            );
        }
    }, [job]);

    /**
     * Effect triggered to update the job process rework list
     */
    useEffect((): void => {
        if (rework) {
            setJobProcessList(job.processList.filter((processRework) => processRework.reworkId === rework.reworkId));
        }
    }, [rework]);

    /**
     * Effect triggered to get all the versions except the drafts of the processes used in a job
     */
    useEffect((): void => {
        if (jobProcessList) {
            // Get all the processId and treeNodeId used in the job.
            const processIdAndRoutingIdList: number[] = [];
            const processAndRoutingTreeNodeIdList: number[] = [];
            jobProcessList.map((jobProcess) => {
                if (!processIdAndRoutingIdList.includes(jobProcess.processId)) {
                    processIdAndRoutingIdList.push(jobProcess.processId);
                }
                if (!processAndRoutingTreeNodeIdList.includes(jobProcess.treeNodeIdProcess)) {
                    processAndRoutingTreeNodeIdList.push(jobProcess.treeNodeIdProcess);
                }

                if (jobProcess.routingId > 0) {
                    if (!processIdAndRoutingIdList.includes(jobProcess.routingId)) {
                        processIdAndRoutingIdList.push(jobProcess.routingId);
                    }
                    if (!processAndRoutingTreeNodeIdList.includes(jobProcess.treeNodeIdRouting)) {
                        processAndRoutingTreeNodeIdList.push(jobProcess.treeNodeIdRouting);
                    }
                }
            });

            // Get all the versions except the drafts of the processes used in a job.
            if (processIdAndRoutingIdList) {
                dispatch(jobGetAllProcess(processIdAndRoutingIdList, processAndRoutingTreeNodeIdList)).then((fetchedProcesses) => {
                    if (fetchedProcesses) {
                        setProcesses(fetchedProcesses);
                    }
                    if (onJobsLoaded) {
                        onJobsLoaded();
                    }
                });
            }
        }
    }, [jobProcessList]);

    /**
     * Handle called when the user select process within a job
     *
     * @param {IJobProcessUiModel} process - selected process
     */
    const handleSelectProcess = (process: IJobProcessUiModel): void => {
        setSelectedJobRoutingId(undefined);
        setSelectedJobProcess(process);
        if (onProcessSelected) {
            onProcessSelected(process);
        }
        if (onOrderIdSelected) {
            onOrderIdSelected(process.orderId);
        }
    };

    /**
     * Handle called when the user select process within a job
     *
     * @param {number} routingId - Selected routing id
     * @param {number} orderId - Order id of the selected routing id
     */
    const handleSelectRouting = (routingId: number, orderId: number, uniqueKey?: number): void => {
        setSelectedJobProcess(undefined);
        setSelectedJobRoutingId(routingId);
        if (setSelectedJobRoutingIndex) {
            setSelectedJobRoutingIndex(uniqueKey);
        }
        if (onRoutingSelected) {
            onRoutingSelected(routingId);
        }
        if (onOrderIdSelected) {
            onOrderIdSelected(orderId);
        }
    };

    /**
     * Handle end of dragging, reorder item
     *
     * @param {DropResult} result - Source and Destination to swap
     */
    const handleDragEnd = (result: DropResult): void => {
        if (job && jobProcessList && result.destination && result.source.index !== result.destination.index) {
            // Reorder the process list
            const newJobProcessList = arrayMove(jobProcessList, result.source.index, result.destination.index).map((process, index) => {
                // Update order ID of moved processes
                process.orderId = failureTicket && jobProcessListNotRework ? index + 1 + jobProcessListNotRework.length : index;
                return process;
            });

            // Update state and database
            setJobProcessList(newJobProcessList);

            dispatch(jobJobProcessUpdate({ job, jobProcessList: newJobProcessList }));
        }
    };

    /**
     * Handle called when the card header item is updated
     *
     * @param {IJobUiModel} job - Key in IJobUiModel changed
     * @param {IJobProcessUiModel} updateJobProcessInJob - jobProcessInJob
     */
    const handleJobProcessChange = (job: IJobUiModel, updateJobProcessInJob: IJobProcessUiModel): void => {
        dispatch(jobJobProcessUpdate({ job, jobProcessList: [updateJobProcessInJob] }));
    };

    /**
     * Return style associated to an item for the DnD of the process
     *
     * @param {boolean} isDragging - true during the dragging process
     * @param {React.CSSProperties | undefined} draggableStyle - current dragging stule
     *
     * @returns {React.CSSProperties} - CSS to be applied
     */
    const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const isJobProcessListUndefinedOrEmpty = !jobProcessList || jobProcessList.length == 0;

    return (
        <React.Fragment>
            {isJobProcessListUndefinedOrEmpty && (
                <KortexPanelCard isSelected={false}>
                    <Typography className={classes.noProcessOrRoutingAvailable} variant="body1">
                        {translate("scheduler.AddNewProcessOrRouting")}
                    </Typography>
                </KortexPanelCard>
            )}
            {!isJobProcessListUndefinedOrEmpty && (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided): JSX.Element => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {jobProcessList &&
                                    jobProcessList
                                        .sort((process1, process2) => process1.orderId - process2.orderId)
                                        .reduce((accumulator: JSX.Element[], jobProcess, index) => {
                                            const isRouting = jobProcess.routingId !== 0;
                                            if (isRouting) {
                                                const prevIsRouting =
                                                    index === 0
                                                        ? false
                                                        : jobProcessList[index - 1].routingId === jobProcessList[index].routingId;
                                                const prevIsSameRouting =
                                                    index === 0
                                                        ? false
                                                        : jobProcessList[index - 1].orderId === jobProcessList[index].orderId;
                                                if (!prevIsRouting || !prevIsSameRouting) {
                                                    // Create a list of process inside of routing
                                                    const jobProcessListInRouting: IJobProcessUiModel[] = [];

                                                    for (let counter = index; counter < jobProcessList.length; counter++) {
                                                        if (jobProcessList[counter].orderId === jobProcess.orderId) {
                                                            jobProcessListInRouting.push(jobProcessList[counter]);
                                                        }
                                                    }

                                                    accumulator.push(
                                                        <Draggable
                                                            key={`${index}`}
                                                            draggableId={`${index}`}
                                                            index={index}
                                                            isDragDisabled={isDragDisabled}
                                                        >
                                                            {(provided, snapshot): JSX.Element => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <SchedulerJobRoutingCard
                                                                        job={job}
                                                                        jobProcessList={jobProcessListInRouting}
                                                                        routingId={jobProcess.routingId}
                                                                        routingName={jobProcess.routingName}
                                                                        routingVersion={jobProcess.version}
                                                                        orderId={jobProcess.orderId}
                                                                        isSelected={
                                                                            selectedJobRoutingIndex === index &&
                                                                            selectedJobRoutingId === jobProcess.routingId
                                                                        }
                                                                        onSelection={handleSelectRouting}
                                                                        groupsList={groupsList}
                                                                        onUpdateProcess={handleJobProcessChange}
                                                                        isDragDisabled={isDragDisabled}
                                                                        userInfo={userInfo}
                                                                        processes={processes}
                                                                        uniqueKey={index}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            } else {
                                                const isJobProcessCardSelected = Boolean(
                                                    selectedJobProcess && jobProcess.jobProcessId === selectedJobProcess.jobProcessId
                                                );
                                                accumulator.push(
                                                    <Draggable
                                                        key={`${index}`}
                                                        draggableId={`${index}`}
                                                        index={index}
                                                        isDragDisabled={isDragDisabled}
                                                    >
                                                        {(provided, snapshot): JSX.Element => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                            >
                                                                <SchedulerJobProcessCard
                                                                    isSelected={isJobProcessCardSelected}
                                                                    job={job}
                                                                    jobProcess={jobProcess}
                                                                    groupsList={groupsList}
                                                                    onUpdateProcess={handleJobProcessChange}
                                                                    onSelection={handleSelectProcess}
                                                                    failureTicket={failureTicket}
                                                                    isDragDisabled={isDragDisabled}
                                                                    userInfo={userInfo}
                                                                    process={processes.find(
                                                                        (process) => process.processId === jobProcess.processId
                                                                    )}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            return accumulator;
                                        }, [])}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </React.Fragment>
    );
}
