import { IKortexTextFieldProps, KortexTextField, theme } from "@aos/react-components";
import { IBomFollowUpSymptomDbModel } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesBomFollowUpSymptom } from "@kortex/aos-ui/redux/effects";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { MenuItem, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

const useStyles = makeStyles({
    container: {
        // To be overwritten
    },
    item: {
        // To be overwritten
    },
    itemLabel: {
        overflow: "hidden",
        // To be overwritten
    },
    selector: {
        width: "320px",
        fontSize: "1rem",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
});

interface IBomFollowUpSymptomSelectorProps {
    classes: Partial<ReturnType<typeof useStyles>>;
    onChange: (value: number) => void;
    value: number;
    treeNodeId?: number;
    jobRefId?: string;
    KortexTextFieldProps?: IKortexTextFieldProps["TextFieldProps"];
}

const BomFollowUpSymptomSelector: FC<IBomFollowUpSymptomSelectorProps> = (props) => {
    const { onChange, value, treeNodeId, KortexTextFieldProps, jobRefId } = props;

    const classes = useStyles(props);
    const translate = useTranslate();
    const language = useSelectorLanguage();

    // TODO: AOS-2570 if bomFollowUpSymptomsType is an array empty add selector process to set treeNodeId
    const bomFollowUpSymptomsType = useEntitiesBomFollowUpSymptom(treeNodeId, jobRefId);
    const [selectedBomFollowUpSymptom, setSelectedBomFollowUpSymptom] = useState<IBomFollowUpSymptomDbModel | undefined>(undefined);

    useEffect((): void => {
        if (value && bomFollowUpSymptomsType) {
            setSelectedBomFollowUpSymptom(
                bomFollowUpSymptomsType.find((bomFollowUpSymptom) => bomFollowUpSymptom.bomFollowUpSymptomId === value)
            );
        }
    }, [bomFollowUpSymptomsType, value]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange(parseInt(event.target.value, 10));
    };

    return (
        <div className={classes.container}>
            <KortexTextField
                className={classes.selector}
                label={translate("player.bom.bomFollowUpSymptom")}
                onChange={handleChange}
                TextFieldProps={{
                    id: "bomFollowUpSymptomSelectorId",
                    select: true,
                    ...KortexTextFieldProps,
                }}
                value={selectedBomFollowUpSymptom?.bomFollowUpSymptomId ?? ""}
                variant="outlined"
            >
                {bomFollowUpSymptomsType &&
                    bomFollowUpSymptomsType
                        .sort((a, b) =>
                            a.title.localeCompare(b.title, language, {
                                sensitivity: "base",
                            })
                        )
                        .map((bomFollowUpSymptom, index): JSX.Element => {
                            return (
                                <MenuItem
                                    className={classes.item}
                                    key={index}
                                    value={bomFollowUpSymptom.bomFollowUpSymptomId}
                                    id={`${bomFollowUpSymptom.title}Id`}
                                >
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        placement="right"
                                        title={`${
                                            bomFollowUpSymptom.description.length > 0
                                                ? bomFollowUpSymptom.description
                                                : translate("general.na")
                                        }`}
                                    >
                                        <Typography className={classes.itemLabel}>{bomFollowUpSymptom.title}</Typography>
                                    </Tooltip>
                                </MenuItem>
                            );
                        })}
            </KortexTextField>
        </div>
    );
};

export default BomFollowUpSymptomSelector;
