import { theme } from "@aos/react-components";
import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import SerializedItemIcon from "@kortex/aos-ui/components/core/Icons/serializedItem/serializedItem";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import {
    Checkbox,
    IconButton,
    InputBase,
    makeStyles,
    Menu,
    MenuItem,
    PopoverPosition,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useContext, useState } from "react";

import { ActionEditorContext } from "../../../../../../context";
import { useWorkInstructionBomContext } from "../../../../context";
import { BomItemInfoTooltip } from "../../../utilities";

const useStyles = makeStyles({
    checkbox: {
        padding: 0,
        marginLeft: "4px",
    },
    iconButton: {
        padding: 0,
    },
    inputTextDecimal: {
        fontSize: "1rem",
        padding: "0px",
        textAlign: "center",
    },
    menuItem: {
        alignItems: "center",
    },
    partNumberContainer: {
        alignItems: "center",
        display: "flex",
    },
    partNumberIcon: {
        marginLeft: "10px",
    },
    tableCellQuantity: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        gap: "4px",
        width: "100%",
    },
    tableRow: {
        height: "40px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    typographyQuantity: {
        width: "40px",
    },
    typographySingleSerialNumber: {
        marginLeft: "4px",
    },
    text: {
        margin: "2px",
    },
});

interface IOwnProps {
    item: IBomItem;
}

function WorkInstructionsBomStepItemsRow(props: IOwnProps): JSX.Element {
    const { item } = props;

    const classes = useStyles();
    const actionEditorContext = useContext(ActionEditorContext);
    const { bom, readOnly } = useWorkInstructionBomContext();
    const translate = useTranslate();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);

    const addDisabled = readOnly || !bom?.items?.[item.partNumber] || item.quantity >= bom.items[item.partNumber].quantity;
    const editedActionStep = actionEditorContext.editedActionStep as ProcessActionStepWorkInstructions;
    const removeDisabled = readOnly || item.quantity - 1 <= 0;
    const serializable = item.quantity > 0 && item.lotSerialType !== "NONE" && !item.phantomItem;
    const serialized = Boolean(editedActionStep?.config.bomItems[item.partNumber]?.serialized);
    const singleSerialNumber = Boolean(editedActionStep?.config.bomItems[item.partNumber]?.singleSerialNumber);

    const [quantity, setQuantity] = useState<number>(editedActionStep.config.bomItems[item.partNumber].quantity);

    const handleDeleteItem = (): void => {
        const step = deepClone(editedActionStep);
        delete step.config.bomItems[item.partNumber];

        handleMenuClose();
        actionEditorContext.onChangedStep(step);
    };

    const handleMenuClose = (): void => {
        setMenuPosition(null);
    };

    const handleMoreClick = (event: React.MouseEvent<HTMLElement>): void => {
        setMenuPosition({
            left: event.clientX,
            top: event.clientY,
        });
    };

    const handleQuantityIncrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const bomItem = bom?.items?.[item.partNumber];
            const updatedQuantity = item.quantity + 1;

            if (bomItem && updatedQuantity <= bomItem.quantity) {
                const step = deepClone(editedActionStep);
                step.config.bomItems[item.partNumber].quantity = updatedQuantity;

                actionEditorContext.onChangedStep(step);
            }
        };

    const handleQuantityDecrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const updatedQuantity = item.quantity - 1;

            const step = deepClone(editedActionStep);
            step.config.bomItems[item.partNumber].quantity = updatedQuantity < 0 ? 0 : updatedQuantity; // Make sure quantity is not below 0

            actionEditorContext.onChangedStep(step);
        };

    const handleQuantityChange = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (readOnly) return void 0;

        const newValue = parseFloat(event.target.value);

        if (newValue === editedActionStep.config.bomItems[item.partNumber].quantity) return void 0;

        const step = deepClone(editedActionStep);

        if (bom?.items?.[item.partNumber].quantity && (Number.isNaN(newValue) || newValue > bom?.items?.[item.partNumber].quantity)) {
            step.config.bomItems[item.partNumber].quantity = bom?.items?.[item.partNumber].quantity;
        } else if (newValue < 1e-6) {
            step.config.bomItems[item.partNumber].quantity = 1e-6;
        } else {
            step.config.bomItems[item.partNumber].quantity = newValue;
        }

        setQuantity(step.config.bomItems[item.partNumber].quantity);
        actionEditorContext.onChangedStep(step);
    };

    const quantityOnChange = (event: React.FocusEvent<HTMLInputElement>): void => {
        setQuantity(parseFloat(event.target.value));
    };

    const handleClickSerialNumberCheckbox =
        (key: "serialized" | "singleSerialNumber"): (() => void) =>
        (): void => {
            const step = deepClone(editedActionStep);
            step.config.bomItems[item.partNumber][key] = !Boolean(step.config.bomItems[item.partNumber][key]);

            if (key === "serialized" && !step.config.bomItems[item.partNumber].serialized) {
                step.config.bomItems[item.partNumber]["singleSerialNumber"] = false;
            }

            actionEditorContext.onChangedStep(step);
        };

    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell>
                    <div className={classes.partNumberContainer}>
                        <BomItemInfoTooltip item={item}>
                            <Typography id={`${item.partNumber}StepItemPartNumberId`} variant="body2">
                                {item.partNumber}
                            </Typography>
                        </BomItemInfoTooltip>
                        {serialized && (
                            <>
                                <Tooltip
                                    classes={{ tooltip: classes.tooltip }}
                                    placement="right"
                                    title={translate("player.bom.itemSerialized")}
                                >
                                    <div className={classes.partNumberIcon}>
                                        <SerializedItemIcon height="24px" width="24px" id={`${item.partNumber}ItemSerializedId`} />
                                    </div>
                                </Tooltip>
                                {singleSerialNumber && (
                                    <Typography className={classes.typographySingleSerialNumber} variant="body2">
                                        x1
                                    </Typography>
                                )}
                            </>
                        )}
                    </div>
                </TableCell>
                <TableCell align="center">
                    {bom?.items?.[item.partNumber].quantity.toString().includes(".") ? (
                        <div className={classes.tableCellQuantity}>
                            <InputBase
                                className={classes.text}
                                id={`${item.partNumber}StepItemQuantityId`}
                                inputProps={{
                                    className: classes.inputTextDecimal,
                                    type: "number",
                                }}
                                onBlur={handleQuantityChange}
                                onChange={quantityOnChange}
                                readOnly={readOnly}
                                value={quantity}
                            />
                        </div>
                    ) : (
                        <div className={classes.tableCellQuantity}>
                            <IconButton
                                className={classes.iconButton}
                                disabled={addDisabled}
                                id={`${item.partNumber}AddQtyButtonId`}
                                onClick={handleQuantityIncrease(item)}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                            <Typography className={classes.typographyQuantity} id={`${item.partNumber}StepItemQuantityId`} variant="body2">
                                {item.quantity}
                            </Typography>
                            <IconButton
                                className={classes.iconButton}
                                disabled={removeDisabled}
                                id={`${item.partNumber}RemoveQtyButtonId`}
                                onClick={handleQuantityDecrease(item)}
                            >
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        className={classes.iconButton}
                        disabled={readOnly}
                        id={`${item.partNumber}MenuButtonId`}
                        onClick={handleMoreClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <Menu
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                open={menuPosition !== null}
                onClose={handleMenuClose}
                id="itemStepMenuId"
            >
                {serializable && [
                    <MenuItem
                        className={classes.menuItem}
                        id={`${item.partNumber}SerializeItemId`}
                        key="serialize"
                        onClick={handleClickSerialNumberCheckbox("serialized")}
                    >
                        {translate("action.workInstructions.bom.serialized")}
                        <Checkbox checked={serialized} className={classes.checkbox} />
                    </MenuItem>,
                    <MenuItem
                        className={classes.menuItem}
                        id={`${item.partNumber}SerializeItemId`}
                        disabled={!serialized}
                        key="singleSerialNumber"
                        onClick={handleClickSerialNumberCheckbox("singleSerialNumber")}
                    >
                        {translate("action.workInstructions.bom.singleSerialNumber")}
                        <Checkbox checked={singleSerialNumber} className={classes.checkbox} disabled={!serialized} />
                    </MenuItem>,
                ]}
                <MenuItem className={classes.menuItem} onClick={handleDeleteItem} id={`${item.partNumber}DeleteItemId`}>
                    {translate("general.delete")}
                </MenuItem>
            </Menu>
        </>
    );
}

export default WorkInstructionsBomStepItemsRow;
