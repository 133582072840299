import { greyPalette } from "@aos/react-components";
import { getWorkInstructionsStepCount, isWorkInstructions } from "@kortex/aos-common";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import PrintApproval from "./PrintApproval";
import PrintFrontPage from "./PrintFrontPage";
import PrintInstruction from "./PrintInstruction";
import PrintProcessFlow from "./PrintProcessFlow";
import PrintRevisionHistory from "./PrintRevisionHistory";
import PrintTableOfContent from "./PrintTableOfContent";
import {
    extractStepNameList,
    IPrintProcessDocumentDynamicSections,
    IPrintProcessDocumentProps,
    IRevisionHistoryTableProps,
    pageHeight,
    pageMargin,
    pageWidth,
} from "./printUtils";

const DEFAULT_PAGE_COUNT = 4;
const REVISION_HISTORY_MAX_ROW = 13;
const TABLE_OF_CONTENT_MAX_ROW = 18;

interface IOwnProps {
    onDynamicSectionChange: (dynamicSection: IPrintProcessDocumentDynamicSections) => void;
}

type IAllProps = IOwnProps & IPrintProcessDocumentProps;

export default function PrintProcessDocument(props: IAllProps): JSX.Element {
    const { process, processVersions, onDynamicSectionChange, treeNode, userList, userGroups, tasks } = props;

    if (!process) {
        return <div />;
    }

    const translate = useTranslate();

    const [revisionHistoryPages, setRevisionHistoryPages] = useState<IRevisionHistoryTableProps[]>([]);
    const [pageCount, setPageCount] = useState<number>(DEFAULT_PAGE_COUNT + getWorkInstructionsStepCount(process.actions));
    const [tableOfContent, setTableOfContent] = useState<string[]>([]);
    let pageIndex = 0;

    useEffect((): void => {
        const processActionStepNames = extractStepNameList(process.actions);
        const revisionHistoryPageCount = generateRevisionHistory();
        const newTableOfContent = [];

        // Add lines to table of contents
        newTableOfContent.push(translate("process.printing.processFlow"));
        processActionStepNames.forEach((name) => newTableOfContent.push(name));
        newTableOfContent.push(translate("process.printing.revisionHistory"));
        newTableOfContent.push(translate("process.printing.approvals"));

        setPageCount(
            3 + // Front page + process flow + approval page
                Math.ceil(newTableOfContent.length / TABLE_OF_CONTENT_MAX_ROW) + // Table of content
                processActionStepNames.length + // Work instructions steps
                revisionHistoryPageCount // Revision page(s)
        );
        setTableOfContent(newTableOfContent);
    }, [processVersions, process.processId]);

    useEffect((): void => {
        onDynamicSectionChange({ tableOfContent, revisionHistoryPages });
    }, [revisionHistoryPages, tableOfContent]);

    const generateTableOfContent = (): JSX.Element[] => {
        const tableOfContentPageCount = Math.ceil(tableOfContent.length / TABLE_OF_CONTENT_MAX_ROW);
        const pages: JSX.Element[] = [];
        let startIndex = 0;

        for (let i = 0; i < tableOfContentPageCount; i++) {
            pages.push(
                <PrintTableOfContent
                    howMany={TABLE_OF_CONTENT_MAX_ROW}
                    key={i}
                    pageIndex={pageIndex++}
                    pagesTitle={tableOfContent}
                    process={process}
                    startIndex={startIndex}
                    totalPage={pageCount}
                />
            );
            startIndex = startIndex + TABLE_OF_CONTENT_MAX_ROW;
        }

        return pages;
    };

    const generateRevisionHistory = (): number => {
        const newRevisionHistory: IRevisionHistoryTableProps[] = [];
        let pages = 0;

        if (processVersions) {
            for (let i = 0; i < processVersions.length; i += REVISION_HISTORY_MAX_ROW, pages++) {
                newRevisionHistory.push({
                    startIndex: i,
                    howMany: REVISION_HISTORY_MAX_ROW,
                });
            }
        }

        setRevisionHistoryPages(newRevisionHistory);
        return pages;
    };

    const handleRevisionPagesOverflow = (index: number): void => {
        revisionHistoryPages[index].howMany -= 1;

        if (revisionHistoryPages[index + 1]) {
            revisionHistoryPages[index + 1].startIndex -= 1;
        }
        setRevisionHistoryPages(revisionHistoryPages);
    };

    /**
     * Find all Work Instruction actions from process and return pages for each step
     */
    const renderWorkInstructionPages = (): JSX.Element[] => {
        const workInstructionPages: JSX.Element[] = [];

        if (!process) {
            return workInstructionPages;
        }

        for (const [actionIndex, action] of process.actions.entries()) {
            if (isWorkInstructions(action)) {
                workInstructionPages.push(
                    ...action.steps.map((step, stepIndex) => (
                        <PrintInstruction
                            formStepProps={step}
                            isDraft={process.isDraft}
                            key={`printPdfWorkInstructionPage-${actionIndex}-${stepIndex}`}
                            pageIndex={pageIndex++}
                            totalPage={pageCount}
                        />
                    ))
                );
            }
        }

        return workInstructionPages;
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={greyPalette[500]}
            width="100%"
            height="100%"
            viewBox={`0 0 ${pageWidth + pageMargin * 2} ${(pageHeight + pageMargin) * pageCount + pageMargin}`}
        >
            <rect x="0" y="0" width="100%" height="100%" fill={greyPalette[500]} />

            {/* FRONT PAGE */}
            <PrintFrontPage pageIndex={pageIndex++} process={process} title={(treeNode && treeNode.label) || ""} totalPage={pageCount} />

            {/* TABLE OF CONTENT */}
            {generateTableOfContent()}

            {/* PROCESS FLOW */}
            <PrintProcessFlow pageIndex={pageIndex++} process={process} totalPage={pageCount} />

            {/* PROCESS ACTIONS */}
            {renderWorkInstructionPages()}

            {/* CHANGE LOG */}
            {revisionHistoryPages.map((revisionHistory, index): JSX.Element => {
                return (
                    <PrintRevisionHistory
                        howMany={revisionHistory.howMany}
                        index={index}
                        key={index}
                        onOverflow={handleRevisionPagesOverflow}
                        pageIndex={pageIndex++}
                        process={process}
                        processVersions={processVersions}
                        startIndex={revisionHistory.startIndex}
                        totalPage={pageCount}
                        userList={userList}
                    />
                );
            })}

            {/* APPROVAL */}
            <PrintApproval
                pageIndex={pageIndex++}
                process={process}
                tasks={tasks}
                totalPage={pageCount}
                userGroups={userGroups}
                userList={userList}
            />
        </svg>
    );
}
